import { ALL_STUDENTS_PENDING, ALL_STUDENTS_SUCCESS, ALL_STUDENTS_FAIL } from "../Actions/Types";

const initialState = {
    getAllStudents: [],
};
const all_student_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ALL_STUDENTS_SUCCESS:
            return {
                ...state,
                getAllStudents: payload.getAllStudents,
            };
        case ALL_STUDENTS_PENDING:
            return {
                ...state,
                getAllStudents: payload.getAllStudents,
            };
        case ALL_STUDENTS_FAIL:
            return {
                ...state,
                getAllStudents: initialState.getAllStudents,
            };

        default:
            return state;
    }
};
export default all_student_reducer;