import React from "react";


const ResumeLessonModal = ({ title, subTitle, buttonAction }) => {
    
    const ACTION_CONTINUE_LESSON = "continue_lesson";
    const ACTION_LESSON_RECAP = "lesson_recap";
    const ACTION_SESSION_RECAP = "session_recap";
    
    return (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-40 rounded-3xl">
            <div className="text-center bg-white rounded-3xl px-4 py-8 w-[80%] sm:w-[55%] flex flex-col justify-center items-center">
                <p className="text-[#333] text-2xl mb-4 font-bold">
                    {title}
                </p>
                <div className="w-[40%] h-[3px] bg-[#34D795] self-center"></div>
                <br />
                <p className="text-[#727674] text-sm mb-4 font-bold">
                    {subTitle}
                </p>
                <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4">
                    <button
                        className="bg-white min-w-[140px] h-[60px] rounded-[6px] text-[#34D795] text-sm px-4 border-[2px] font-semibold border-[#34D795]"
                        onClick={() => buttonAction(ACTION_CONTINUE_LESSON)}
                    >
                        Continue
                    </button>
                    <button
                        className="bg-[#34D795] min-w-[140px] h-[60px] rounded-[6px] text-white text-sm px-4 font-semi-bold"
                        onClick={() => buttonAction(ACTION_LESSON_RECAP)}
                    >
                        Watch Lesson Recap
                    </button>
                    <button
                        className="bg-[#34D795] min-w-[140px] h-[60px] rounded-[6px]  text-white text-sm px-4 font-semi-bold"
                        onClick={() => buttonAction(ACTION_SESSION_RECAP)}
                    >
                        Watch Session Recap
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ResumeLessonModal;