import { COURSES_PENDING, COURSES_SUCCESS, COURSES_FAIL } from "../Actions/Types";

const initialState = {
    getCourses: [],
};
const courses_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case COURSES_SUCCESS:
            return {
                ...state,
                getCourses: payload.getCourses,
            };
        case COURSES_PENDING:
            return {
                ...state,
                getCourses: payload.getCourses,
            };
        case COURSES_FAIL:
            return {
                ...state,
                getCourses: [],
            };

        default:
            return state;
    }
};
export default courses_reducer;