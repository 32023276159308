import React from "react";

const WarningLabel = ({ label}) => {

    return (
        <div className="absolute top-2 left-2 flex justify-center items-center p-2 bg-[#F8BF00] rounded-xl gap-2 z-50">
            <img src="/warning.svg" alt="" />
            <p className="text-center text-[14px] font-open_sans text-white">{label}</p>
        </div>
    );
}

export default WarningLabel;