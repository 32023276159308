import React from "react";

const NewStudentsCard = ({ numberOfNewStudents, percentageOfNewStudents }) => {
    return (
        <div className="w-full flex rounded-[10px] border border-neutral-4 p-[16px] gap-[16px] lg:p-[24px] lg:gap-[24px]">
            <div className="flex gap-[16px] lg:gap-[24px]">
                <div className="p-[16px] rounded-[88px] bg-primary-9 lg:p-[27px]">
                    <img
                        src="/new_students.svg"
                        className="w-[24px] h-[24px] lg:w-[32px] lg:h-[32px]"
                    />
                </div>
                <div className="flex flex-col text-font_color-primary gap-[8px]">
                    <div className="flex items-end gap-[16px]">
                        <p className="text-left Heading-18 lg:Heading-32">{numberOfNewStudents}</p>
                        <div className="flex gap-[8px] lg:gap-[16px]">
                            <p className="text-left subtitle3 lg:subtitle2">{`+${percentageOfNewStudents}%`}</p>
                            <p className="text-left text-font_color-body subtitle3 lg:subtitle2">than last month</p>
                            <img
                                src="/newstudents_up_icon.svg"
                                className="w-[24px] h-[24px]"
                            />
                        </div>
                    </div>
                    <p className="text-left subtitle2 text-font_color-body lg:subtitle1">New Students</p>

                </div>

            </div>


        </div>
    )
}
export default NewStudentsCard;