import React from 'react';

const CourseFeatures = () => {
    const features = [
        {
            id: 1,
            icon: '/reading-book 1.svg',
            title: 'Personalized Learning',
            description: [
                'Our AI teacher adapts to your learning speed, ensuring that you grasp each concept before moving forward. Whether you\'re breezing through vocabulary or need extra time with grammar, the AI customizes the course to fit your needs.',
                'Receive instant, personalized feedback on your exercises and quizzes. The AI identifies your strengths and areas for improvement, offering targeted recommendations to help you excel.',
                'As you progress, the AI modifies your learning path, introducing more challenging content when you\'re ready and revisiting foundational concepts when needed.',
            ],
        },
        {
            id: 2,
            icon: '/live-streaming.svg',
            title: 'Real-Time Interaction',
            description: [
                'Improve your speaking skills with real-time pronunciation feedback. The AI listens to your pronunciation and provides immediate corrections, helping you achieve native-like fluency.',
                'Engage in live conversations with the AI teacher, simulating real-world scenarios. The AI responds naturally, allowing you to practice dialogue and build confidence in your speaking abilities.',
                'Take quizzes and assessments that are instantly graded by the AI. The system provides detailed insights into your performance, highlighting areas for improvement and reinforcing what you\'ve learned.',
            ],
        },
        {
            id: 3,
            icon: '/clipboard 1.svg',
            title: 'Progress Tracking',
            description: [
                'Stay motivated by tracking your progress with easy-to-read charts and graphs. The AI tracks your achievements in real-time, showing you exactly how far you\'ve come and what milestones are next.',
                'Set your learning goals, and let the AI help you stay on track with reminders and encouragement. Whether you\'re aiming to complete a lesson a day or master a specific skill, the AI keeps you focused.',
                'Celebrate your progress with badges and rewards. As you reach key milestones, the AI awards you with digital badges, motivating you to keep moving forward and achieve fluency.',
            ],
        },
    ];

    return (
        <div className='flex flex-col gap-[16px] rounded-[8px] lg:flex-row'>
            {features.map((feature) => (
                <div key={feature.id} className='flex flex-col w-full rounded-[16px] border border-neutral-3 gap-[8px] p-[16px]'>
                    <div className='flex items-center gap-[12px] lg:gap-[24px]'>
                        <button className='bg-primary-2 p-[10px] rounded-full lg:p-[16px]'>
                            <img
                                className='w-[20px] h-[20px] lg:w-[24px] lg:h-[24px]'
                                alt={`${feature.title.toLowerCase()} icon`}
                                src={feature.icon}
                            />
                        </button>
                        <h className="text-font_color-primary button2 lg:button1">{feature.title}</h>
                    </div>
                    <div className='flex flex-col gap-[16px]  text-font_color-body'>
                        {feature.description.map((desc, index) => (
                            <p
                            key={index}
                             className='body-2 text-font_color-body lg:body-1'>
                                {desc}
                            </p>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CourseFeatures;
