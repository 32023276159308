import React, { useEffect, useRef, useState } from "react";
import HeroSection from "./HeroSection";
import CourseList from "./CourseList";
import MeetAITeachers from "./MeetAITeachers";
import FutureLearning from "./FutureLearning";
import AskedQuestions from "./AskedQuestions";
import StartLearning from "./StartLearning";
import LandingPageFooter from "./LandingPageFooter";
import { useNavigate } from "react-router-dom";
import Header from "../../../UIComponents/Header";
import DemoModal from "./DemoModal";

export const LandingPage = () => {
  const animatedRefs = useRef([]);
  const animatedOnce = useRef([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [headerBackground, setHeaderBackground] = useState('transparent');
  const [isDemoVisible, setIsDemoVisible] = useState(false);

  const setRefs = (index) => (el) => {
    animatedRefs.current[index] = el;
  };

  // function to show animation on components 
  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        const index = animatedRefs.current.indexOf(entry.target);
        if (entry.isIntersecting && !animatedOnce.current[index]) {
          entry.target.classList.add(
            entry.target.dataset.animationType === "left"
              ? "animate-slide-in-left"
              : "animate-slide-in-right"
          );
          animatedOnce.current[index] = true;
        }
      });
    };

    const observerOptions = {
      threshold: 0.4,
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    animatedRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      animatedRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);
  const handleKnnectClick = () => {
    if (user) {
      navigate("/app/dashboard");
    } else {
      navigate("/signup");
    }

  };
  //change header background color depending on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHeaderBackground('bg-primary-1');
      } else {
        setHeaderBackground('transparent');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  // to display Demo Modal
  const handleDemoVisible = () => {
    setIsDemoVisible(true)
  }
  return (
    <div className="relative w-full">
      <div className="fixed top-0 left-0 z-10 w-full">
        <Header background={headerBackground} />
      </div>

      {/* Main Content */}
      <div className="pt-[header-height]">
        <HeroSection
          onWatchDemo={handleDemoVisible}
          onClick={handleKnnectClick} />
        <MeetAITeachers setRefs={setRefs} onClick={handleKnnectClick} />
        <CourseList setRefs={setRefs} />
        <FutureLearning setRefs={setRefs} onClick={handleKnnectClick} />
        <AskedQuestions />
        <StartLearning onClick={handleKnnectClick} />
        <LandingPageFooter />
        <DemoModal
          isVisible={isDemoVisible}
          onClose={() => setIsDemoVisible(false)}
        />
      </div>
    </div>
  );
};
