import { USER_COURSE_DETAIL_PENDING, USER_COURSE_DETAIL_SUCCESS, USER_COURSE_DETAIL_FAIL, RESET_CREATE_SESSION, CREATE_SESSION_FAIL, CREATE_SESSION_PENDING, CREATE_SESSION_SUCCESS } from "./Types"
import { toast } from "react-toastify"
import sessionServices from "../../Services/SessionServices";


export const getUserCourseDetail = (body) => async (dispatch) => {
    dispatch({
        type: USER_COURSE_DETAIL_PENDING,
        payload: { getUserCourseDetail: "isLoading" },
    });

    try {
        const data = await sessionServices.getUserCourseDetail(body);

        if (data.data.status === true) {
            dispatch({
                type: USER_COURSE_DETAIL_SUCCESS,
                payload: { getUserCourseDetail: data.data },
            });
        } else {
            dispatch({ type: USER_COURSE_DETAIL_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: USER_COURSE_DETAIL_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};


export const createSession = (body) => async (dispatch) => {
    dispatch({
        type: CREATE_SESSION_PENDING,
        payload: { createSession: "isLoading" },
    });

    try {
        const data = await sessionServices.createSession(body);

        if (data.data.status === true) {
            dispatch({
                type: CREATE_SESSION_SUCCESS,
                payload: { createSession: data.data },
            });
        } else {
            dispatch({ type: CREATE_SESSION_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: CREATE_SESSION_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};

export const resetCreateSession = () => ({
    type: RESET_CREATE_SESSION,
});