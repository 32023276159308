import React from "react";


const LessonCompleteModal = ({ title, message, lessonCompleteAction }) => {

    const ACTION_LESSON_RETAKE = "lesson_retake";
    const ACTION_NEXT_LESSON = "next_lesson";

    return (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-40 rounded-3xl">
            <div className="text-center bg-white rounded-3xl px-4 py-8 w-[80%] sm:w-[55%] items-center flex flex-col justify-center">
                <p className="text-[#333] text-2xl mb-4 font-bold">
                    {title}
                </p>
                <div className="w-[40%] h-[3px] bg-[#34D795] self-center "></div>
                <br />
                <p className="text-[#727674] text-sm mb-4 font-bold">
                    {message}
                </p>
                <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4 items-center">
                    <button
                        className="bg-white w-[140px] h-[48px] rounded-[6px] text-[#34D795] text-sm px-4 border-[2px] font-semibold border-[#34D795]"
                        onClick={() => lessonCompleteAction(ACTION_LESSON_RETAKE)}
                    >
                        Retake
                    </button>
                    <button
                        className="bg-[#34D795] w-[140px] h-[48px] rounded-[6px] text-white text-sm px-4 font-semi-bold"
                        onClick={() => lessonCompleteAction(ACTION_NEXT_LESSON)}
                    >
                        Next Lesson
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LessonCompleteModal;