import React from "react";
import DonutChart from "./DonutChart";

const CourseProgressCard = ({ CourseData }) => {
    return (
        <div className="flex flex-col w-[350px] rounded-[10px] p-[16px] justify-between text-font_color-primary gap-[16px] border border-neutral-4 lg:gap-[24px] lg:p-[24px] lg:max-h-[375px] lg:w-[460px]">
            <p className="text-left Heading-18">
                Course Progress
            </p>
            <DonutChart
                completed={CourseData.completed_sessions}
                total={CourseData.total_sessions}

            />
        </div>

    )
}
export default CourseProgressCard;

