import { COURSES_PENDING, COURSES_SUCCESS, COURSES_FAIL, COURSE_ENROLLMENT_PENDING, COURSE_ENROLLMENT_SUCCESS, COURSE_ENROLLMENT_FAIL, COURSE_DETAIL_PENDING, COURSE_DETAIL_SUCCESS, COURSE_DETAIL_FAIL, RESET_ENROLL_COURSE, USER_COURSES_PENDING, USER_COURSES_SUCCESS, USER_COURSES_FAIL } from "./Types"
import { toast } from "react-toastify"
import courseServices from "../../Services/CourseServices";

export const getCourses = () => async (dispatch) => {
    dispatch({
        type: COURSES_PENDING,
        payload: { getCourses: "isLoading" },
    });

    try {
        const data = await courseServices.getCourses();

        if (data.data.status === true) {
            dispatch({
                type: COURSES_SUCCESS,
                payload: { getCourses: data.data },
            });
        } else {
            dispatch({ type: COURSES_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: COURSES_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};



export const enrollCourse = (body) => async (dispatch) => {
    dispatch({
        type: COURSE_ENROLLMENT_PENDING,
        payload: { courseEnrollment: "isLoading" },
    });

    try {
        const data = await courseServices.enrollCourse(body);

        if (data.data.status === true) {
            dispatch({
                type: COURSE_ENROLLMENT_SUCCESS,
                payload: { courseEnrollment: data.data },
            });
        } else {
            dispatch({ type: COURSE_ENROLLMENT_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: COURSE_ENROLLMENT_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};

export const resetEnrollCourse = () => ({
    type: RESET_ENROLL_COURSE,
});


export const getCourseDetail = (id) => async (dispatch) => {
    dispatch({
        type: COURSE_DETAIL_PENDING,
        payload: { getCourseDetail: "isLoading" },
    });

    try {
        const data = await courseServices.getCourseDetail(id);

        if (data.data.status === true) {
            dispatch({
                type: COURSE_DETAIL_SUCCESS,
                payload: { getCourseDetail: data.data },
            });
        } else {
            dispatch({ type: COURSE_DETAIL_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: COURSE_DETAIL_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};


export const getUserCourses = (body) => async (dispatch) => {
    dispatch({
        type: USER_COURSES_PENDING,
        payload: { getUserCourses: "isLoading" },
    });

    try {
        const data = await courseServices.getUserCourses(body);

        if (data.data.status === true) {
            dispatch({
                type: USER_COURSES_SUCCESS,
                payload: { getUserCourses: data.data },
            });
        } else {
            dispatch({ type: USER_COURSES_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error: ", error);
        dispatch({ type: USER_COURSES_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};
