import React, { useEffect, useState } from 'react';
import ChooseTeacherModal from "../../UIComponents/ChooseTeacherModal";
import { useDispatch, useSelector } from "react-redux";
import CourseAccordion from '../../UIComponents/CourseAccordian';
import { useNavigate, useParams } from "react-router-dom";
import { enrollCourse, getCourseDetail, resetEnrollCourse } from '../../Redux/Actions/CourseActions';
import { getTeachers } from '../../Redux/Actions/CommonActions';
import { TailSpin } from 'react-loader-spinner';
import SuccessModal from '../../UIComponents/SuccessModal';
import CourseFeatures from './CourseFeatures';
import TeachersCarousel from '../../UIComponents/TeachersCrousel';
import Header from '../../UIComponents/Header';
import SignInRequiredModal from './SignInRequiredModal';
import CourseOverviewModal from '../../UIComponents/CourseOverviewModal';


export const CourseDetailPage = () => {
    //get course id from url prams
    const { courseId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem("user"));
    //check if user already enrolled in this course
    const isEnrolled = user?.user_courses?.includes(courseId);
    const [isChooseTeacherModalVisible, setIsChooseTeacherModalVisible] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isSigninRequiredModalVisible, setIsSigninRequiredModalVisible] = useState(false);
    const [isCourseOverviewVisible, setIsCourseOverviewVisible] = useState(false);
    const [isMorphing, setIsMorphing] = useState(false);
    const [activeTab, setActiveTab] = useState('ai-teachers');
    //seting teacher to show in overview modal
    const [watchIntroTeacherData, setWatchIntroTeacherData] = useState(null);
    const allTeachers = useSelector((state) => state.teachers_reducer.getTeachers);
    const courseDetail = useSelector((state) => state.course_detail_reducer.getCourseDetail);
    const enrollCourseStatus = useSelector((state) => state.course_enrollment_reducer.courseEnrollment);
    let outlineCount = 0;
    //function which dispaly Choose Teacher modal if user logged in or display sigin required modal if user not logged in
    const handleEnrollNowClick = () => {
        if (user) {
            setIsChooseTeacherModalVisible(true);
        } else {
            setIsSigninRequiredModalVisible(true);
        }

    };
    // function to dispatch action to get course detail and teachers
    useEffect(() => {
        dispatch(getCourseDetail(courseId));
        dispatch(getTeachers());
    }, [courseId])

    useEffect(() => {
        if (enrollCourseStatus && enrollCourseStatus.length !== 0 && enrollCourseStatus !== "isLoading" && enrollCourseStatus.status === true) {
            setIsChooseTeacherModalVisible(false);
            setIsSuccessModalVisible(true);
        }
    }, [enrollCourseStatus])
    //function to switch tabs
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const closeSuccessModal = () => {
        setIsSuccessModalVisible(false);
    };
    const closeSigninRequiredModal = () => {
        setIsSuccessModalVisible(false);
    };
    //navigate user to user enrolled course if logged in
    const handleGoToCourseClick = () => {
        navigate(`/app/my-learning/enrolled-course/${courseId}`);
    };
    //handle function to display watch intro modal and hide other modal if showed
    const handleWatchIntro = (teacher) => {
        setIsChooseTeacherModalVisible(false);
        setWatchIntroTeacherData(teacher);
        setIsCourseOverviewVisible(true);
    };
    //function to dispatch enroll course action
    const handleConfirm = (body) => {
        dispatch(enrollCourse(body));
    };
    return (
        <div className="relative w-full">
            <div className="fixed top-0 left-0 z-10 w-full">
                <Header background={"bg-primary-1"} />
            </div>
            <div className="mt-[80px]">
                {courseDetail && courseDetail === "isLoading" && (
                    <div className='flex items-center justify-center h-full'>
                        <TailSpin color="#34D795" height={50} width={50} />
                    </div>
                )}
                {
                    courseDetail && courseDetail !== "isLoading" && courseDetail.length !== 0 && courseDetail.result && courseDetail.result.length !== 0 && (
                        <div className="w-full flex flex-col gap-[24px] p-[20px]">
                            <div className="flex flex-col gap-[24px] bg-primary-9 rounded-[16px] lg:flex-row">
                                <div className="flex flex-col justify-center p-[32px] gap-[24px] lg:w-[50%]">
                                    <div className="flex flex-col gap-[32px]">
                                        <h3 className="Heading-20 text-font_color-primary lg:Heading-32">{courseDetail.result.title}</h3>
                                        <p className="body-2 text-body lg:body-1">{courseDetail.result.description}</p>
                                    </div>
                                    <button
                                        onClick={isEnrolled ? handleGoToCourseClick : handleEnrollNowClick}
                                        className="button3 px-[20px] py-[10px] gap-[10px] bg-primary-1 text-neutral-1 rounded-[6px] hover:bg-primary-4 w-fit lg:button1 lg:px-[28px] lg:py-[12px]"
                                    >
                                        {isEnrolled ? "Go to Course" : "Enroll now"}
                                    </button>

                                </div>
                                <img
                                    src={courseDetail.result.image_url}
                                    alt="course Image"
                                    className="h-auto object-cover lg:ml-auto rounded-b-[16px] lg:rounded-r-[16px] lg:w-[50%]"
                                />
                            </div>

                            <div className="flex border-b border-neutral-4 gap-[10px] lg:border-b-2">
                                <button
                                    className={`button3 py-[10px] px-[10px] border-b transition-colors duration-300 ease-in-out ${activeTab === 'ai-teachers' ? 'border-primary-1 text-primary-1' : 'border-transparent text-primary hover:border-primary-1 hover:text-primary-1'} lg:px-[40px] lg:py-[18px] lg:button1 lg:border-b-2`}
                                    onClick={() => handleTabClick('ai-teachers')}
                                >
                                    AI Teachers
                                </button>
                                <button
                                    className={`button3 border-b py-[10px] px-[10px] transition-colors duration-300 ease-in-out ${activeTab === 'course-outline' ? 'border-primary-1 text-primary-1' : 'border-transparent text-primary hover:border-primary-1 hover:text-primary-1'} lg:px-[40px] lg:py-[18px] lg:button1 lg:border-b-2`}
                                    onClick={() => handleTabClick('course-outline')}
                                >
                                    Course Outline
                                </button>
                                <button
                                    className={`button3 border-b py-[10px] px-[10px] transition-colors duration-300 ease-in-out ${activeTab === 'features' ? 'border-primary-1 text-primary-1' : 'border-transparent text-primary hover:border-primary-1 hover:text-primary-1'} lg:px-[40px] lg:py-[18px] lg:button1 lg:border-b-2`}
                                    onClick={() => handleTabClick('features')}
                                >
                                    Features
                                </button>
                            </div>

                            <div id="tab-content">
                                {activeTab === 'ai-teachers' && (
                                    <TeachersCarousel
                                        onWatchIntroClick={handleWatchIntro}
                                        allTeachers={allTeachers}
                                    />
                                )}
                                {activeTab === 'course-outline' && (
                                    <div className='rounded-[8px]'>
                                        {courseDetail.result.outline.length > 0 && courseDetail.result.outline.map((outline) => {
                                            outlineCount += 1;
                                            const formattedCount = outlineCount < 10 ? `0${outlineCount}` : outlineCount;
                                            return (
                                                <CourseAccordion
                                                    key={outline.index}
                                                    number={formattedCount}
                                                    outlineData={outline}
                                                />
                                            )
                                        })}

                                    </div>
                                )}
                                {activeTab === 'features' && (
                                    <CourseFeatures />
                                )}
                            </div>
                            {allTeachers && allTeachers.length !== 0 && allTeachers !== "isLoading" && allTeachers.result && allTeachers.result.length !== 0 &&
                                <ChooseTeacherModal
                                    isVisible={isChooseTeacherModalVisible}
                                    onClose={() => setIsChooseTeacherModalVisible(false)}
                                    teachersData={allTeachers.result}
                                    courseId={courseId}
                                    isMorphing={isMorphing}
                                    onConfirmClick={handleConfirm}
                                    onWatchIntroClick={handleWatchIntro}
                                />}
                            <SuccessModal
                                isVisible={isSuccessModalVisible}
                                onClose={closeSuccessModal}
                                isMorphing={isMorphing}
                            />
                            {isCourseOverviewVisible && (
                                <CourseOverviewModal
                                    isVisible={isCourseOverviewVisible}
                                    onClose={() => setIsCourseOverviewVisible(false)}
                                    courseTitle={courseDetail.result.title}
                                    teacherData={watchIntroTeacherData}
                                />
                            )}
                            <SignInRequiredModal
                                isVisible={isSigninRequiredModalVisible}
                                onClose={closeSigninRequiredModal}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}