import React, { useState } from "react";
import CircularProgressBar from "./CircularProgressBar";

const SessionScoreCard = ({ sessions }) => {

    return (
        <div className="flex flex-col rounded-[10px] p-[16px] text-font_color-primary gap-[16px] border border-neutral-4 lg:gap-[16px] lg:p-[24px] lg:max-h-[375px] lg:min-w-[300px] lg:max-w-[460px]">
            <p className="text-left Heading-18">
                Session Score
            </p>
            <CircularProgressBar
                progress={sessions.percentage}
                strokeWidth={25}
                totalScore={sessions.total_score}
                achievedScore={sessions.gained_score}
            />
        </div>
    )
}
export default SessionScoreCard;