import React from "react";

const TotalStudentsCard = ({totalStudents}) => {
    return (
        <div className="w-full flex rounded-[10px] border border-neutral-4 p-[16px] gap-[16px] lg:p-[24px] lg:gap-[24px]">
            <div className="flex gap-[16px] lg:gap-[24px]">
                <div className="p-[16px] rounded-[88px] bg-primary-9 lg:p-[27px]">
                    <img
                        src="/total_students_icon.svg"
                        className="w-[24px] h-[24px] lg:w-[32px] lg:h-[32px]"
                    />
                </div>
                <div className="flex flex-col text-font_color-primary gap-[8px]">
                    <p className="text-left Heading-18 lg:Heading-32">{totalStudents}</p>
                    <p className="text-left subtitle3 text-font_color-body lg:subtitle1">Total Students</p>

                </div>
            </div>
        </div>
    )
}
export default TotalStudentsCard;