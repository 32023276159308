import React from "react";
import { Outlet } from "react-router-dom";


export const StudentContent = () => {

    return (
        <div className="flex-grow h-full py-[20px] px-[6px] lg:pl-[40px] lg:py-6 lg:pr-[40px] overflow-y-auto">
            <Outlet />
        </div>
    )
}