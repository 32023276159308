import React from "react";

const FutureLearning = ({ setRefs, onClick }) => {
    return (
        <div className="flex flex-col w-full justify-center items-center p-[20px] gap-[8px] flex-shrink-0 lg:flex-row lg:gap-[156px]">

            <div
                ref={setRefs(2)}
                data-animation-type="left"
                className=" opacity-0"
            >
                <div className="p-[10px] w-[320px] lg:w-[670px]">
                    <img src="thumb-concept-1.png" alt="" />
                </div>
            </div>
            <div
                ref={setRefs(3)}
                data-animation-type="right"
                className="flex flex-col gap-[24px] opacity-0 lg:gap-[35px]"
            >
                <div className="w-[94px] h-[7px] bg-[#34D795]"></div>
                <h2 className="Heading-20 text-font_color-primary w-auto lg:Heading-40 lg:w-[454px]">
                    Ready to Experience the Future of Learning?
                </h2>
                <p className="text-font_color-body body-3 lg:body-0">
                    Join thousands of students who are transforming
                    <br />
                    their learning with Knnect's AI-driven personalized
                    <br />
                    education. Sign up today and see the difference AI
                    <br />
                    can make in your studies.
                </p>
                <button
                onClick={onClick}
                className="w-fit rounded-[6px] bg-primary-1 text-neutral-1 button3 py-[10px] px-[16px] hover:bg-primary-5 lg:button1 lg:py-[13px] lg:px-[28px]">
                    Get Knnected Today!
                </button>
            </div>
        </div>
    );
};

export default FutureLearning;