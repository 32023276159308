//SignUp
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_PENDING = "SIGNUP_PENDING";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const RESET_SIGNUP_STATE = 'RESET_SIGNUP_STATE';

//ConfirmSignUp
export const CONFIRM_SIGNUP_SUCCESS = "CONFIRM_SIGNUP_SUCCESS";
export const CONFIRM_SIGNUP_PENDING = "CONFIRM_SIGNUP_PENDING";
export const CONFIRM_SIGNUP_FAIL = "CONFIRM_SIGNUP_FAIL";
export const RESET_CONFIRM_STATE = 'RESET_CONFIRM_STATE';

// SignIN
export const SIGNIN_PENDING = 'SIGNIN_PENDING';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';
export const RESET_SIGNIN = 'RESET_SIGNIN';

// SignIN With Google
export const GOOGLE_SIGNIN_PENDING = 'GOOGLE_SIGNIN_PENDING';
export const GOOGLE_SIGNIN_SUCCESS = 'GOOGLE_SIGNIN_SUCCESS';
export const GOOGLE_SIGNIN_FAIL = 'GOOGLE_SIGNIN_FAIL';



// get Genders
export const GENDERS_PENDING = 'GENDERS_PENDING';
export const GENDERS_SUCCESS = 'GENDERS_SUCCESS';
export const GENDERS_FAIL = 'GENDERS_FAIL';

// get Roles
export const ROLES_PENDING = 'ROLES_PENDING';
export const ROLES_SUCCESS = 'ROLES_SUCCESS';
export const ROLES_FAIL = 'ROLES_FAIL';

// get Course Detail
export const COURSE_DETAIL_PENDING = 'COURSE_DETAIL_PENDING';
export const COURSE_DETAIL_SUCCESS = 'COURSE_DETAIL_SUCCESS';
export const COURSE_DETAIL_FAIL = 'COURSE_DETAIL_FAIL';

// get Course Enrollment
export const COURSE_ENROLLMENT_PENDING = 'COURSE_ENROLLMENT_PENDING';
export const COURSE_ENROLLMENT_SUCCESS = 'COURSE_ENROLLMENT_SUCCESS';
export const COURSE_ENROLLMENT_FAIL = 'COURSE_ENROLLMENT_FAIL';
export const RESET_ENROLL_COURSE = 'RESET_ENROLL_COURSE';

// get Courses
export const COURSES_PENDING = 'COURSES_PENDING';
export const COURSES_SUCCESS = 'COURSES_SUCCESS';
export const COURSES_FAIL = 'COURSES_FAIL';

// get user Courses
export const USER_COURSES_PENDING = 'USER_COURSES_PENDING';
export const USER_COURSES_SUCCESS = 'USER_COURSES_SUCCESS';
export const USER_COURSES_FAIL = 'USER_COURSES_FAIL';

// get user Course detail
export const USER_COURSE_DETAIL_PENDING = 'USER_COURSE_DETAIL_PENDING';
export const USER_COURSE_DETAIL_SUCCESS = 'USER_COURSE_DETAIL_SUCCESS';
export const USER_COURSE_DETAIL_FAIL = 'USER_COURSE_DETAIL_FAIL';

// get Teachers
export const TEACHERS_PENDING = 'TEACHERS_PENDING';
export const TEACHERS_SUCCESS = 'TEACHERS_SUCCESS';
export const TEACHERS_FAIL = 'RTEACHERS_FAIL';

// get Lesson Content
export const LESSON_CONTENT_PENDING = 'LESSON_CONTENT_PENDING';
export const LESSON_CONTENT_SUCCESS = 'LESSON_CONTENT_SUCCESS';
export const LESSON_CONTENT_FAIL = 'LESSON_CONTENT_FAIL';

// get User Dashboard
export const DASHBOARD_DATA_PENDING = 'DASHBOARD_DATA_PENDING';
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS';
export const DASHBOARD_DATA_FAIL = 'DASHBOARD_DATA_FAIL';

// get Admin Dashboard
export const ADMIN_DASHBOARD_DATA_PENDING = 'ADMIN_DASHBOARD_DATA_PENDING';
export const ADMIN_DASHBOARD_DATA_SUCCESS = 'ADMIN_DASHBOARD_DATA_SUCCESS';
export const ADMIN_DASHBOARD_DATA_FAIL = 'ADMIN_DASHBOARD_DATA_FAIL';

// get All Students
export const ALL_STUDENTS_PENDING = 'ALL_STUDENTS_PENDING';
export const ALL_STUDENTS_SUCCESS = 'ALL_STUDENTS_SUCCESS';
export const ALL_STUDENTS_FAIL = 'ALL_STUDENTS_FAIL';

// get Student Detail
export const STUDENT_DETAIL_PENDING = 'STUDENT_DETAIL_PENDING';
export const STUDENT_DETAIL_SUCCESS = 'STUDENT_DETAIL_SUCCESS';
export const STUDENT_DETAIL_FAIL = 'STUDENT_DETAIL_FAIL';

// get User Profile
export const PROFILE_PENDING = 'PROFILE_PENDING';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAIL = 'PROFILE_FAIL';

// get send Verification email
export const SEND_VERIFICATION_EMAIL_PENDING = 'SEND_VERIFICATION_EMAIL_PENDING';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_FAIL = 'SEND_VERIFICATION_EMAIL_FAIL';

// get UPDATE Password
export const UPDATE_PASSWORD_PENDING = 'UPDATE_PASSWORD_PENDING';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';
export const RESET_UPDATE_PASSWORD = 'RESET_UPDATE_PASSWORD';

// get UPDATE Profile
export const UPDATE_PROFILE_PENDING = 'UPDATE_PROFILE_PENDING';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PASSWORD_FAIL';

// evaluate assessment
export const EVALUATE_ASSESSMENT_PENDING = 'EVALUATE_ASSESSMENT_PENDING';
export const EVALUATE_ASSESSMENT_SUCCESS = 'EVALUATE_ASSESSMENT_SUCCESS';
export const EVALUATE_ASSESSMENT_FAIL = 'EVALUATE_ASSESSMENT_FAIL';

// get Change Password
export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const RESET_CHANGE_PASSWORD = 'RESET_CHANGE_PASSWORD';

// get forgot Password
export const FORGOT_PASSWORD_PENDING = 'FORGOT_PASSWORD_PENDING';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const RESET_FORGOT_PASSWORD = 'RESET_FORGOT_PASSWORD';

// create session
export const CREATE_SESSION_PENDING = 'CREATE_SESSION_PENDING';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAIL = 'CREATE_SESSION_FAIL';
export const RESET_CREATE_SESSION = 'RESET_CREATE_SESSION';

//messages
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// sidebar selection
export const SIDEBAR_SELECTION_SUCCESS = "SIDEBAR_SELECTION_SUCCESS"