import { BASE_URL } from '../../Constants';
import axiosInstance from '../AxiosInstance';

// Base URL for your API
const API_URL = BASE_URL;

const ADMIN_DASHBOARD_DATA = "admin-dashboard-data/";
const ALL_STUDENTS = "all-students/";
const GET_STUDENT_DETAIL = "get-student-detail/";


class AdminServices {
    async getAdminDashboardData() {
        return axiosInstance.get(API_URL + ADMIN_DASHBOARD_DATA
        );
    }
    async getAllStudents(body) {
        return axiosInstance.post(API_URL + ALL_STUDENTS, body
        );
    }
    async getStudentDetail(body) {
        return axiosInstance.post(API_URL + GET_STUDENT_DETAIL, body
        );
    }
}

// const adminServicesInstance = new AdminServices();

// export default adminServicesInstance;
export default new AdminServices();