import React, { useState } from "react";

const QuestionCard = ({ questionData, totalQuestion, questionNumber, scrollRight, onAnswerSelect }) => {
    const [selectedButton, setSelectedButton] = useState(null);

    const getOptionClass = (letter) =>
        `flex items-center h-[56px] w-[143px] rounded-[10px]  bg-[#FBF9F9] 
        ${selectedButton === letter ? 'border-2 border-blue-500' : ''}`;
    //handle function to set selected options
    const handleSelect = (letter) => {
        if (!selectedButton) {
            setSelectedButton(letter);
            onAnswerSelect(questionData.question_id, letter);
            setTimeout(() => {
                scrollRight();
            }, 600);
        }
    };

    return (
        <div className="flex flex-col w-[334px] h-[300px] bg-primary-1 rounded-[16px] p-[16px] shadow-questioncard justify-between">
            <div className="flex flex-col gap-[16px] items-center">
                <p className="subtitle-1 text-left w-full text-neutral-1">{`Question ${questionNumber}/${totalQuestion}`}</p>
                <hr className="w-[104px] border-2 border-neutral-1" />
                <p className="Heading-18 text-center text-neutral-1">{questionData.question}</p>
            </div>
            <div className="h-[128px] grid grid-cols-2 justify-between">
                {Object.entries(questionData.choices).map(([key, value]) => (
                    <button
                        key={key}
                        className={getOptionClass(key)}
                        onClick={() => handleSelect(key)}
                        disabled={selectedButton !== null}
                    >
                        <div className="w-[24px] h-full rounded-l-[10px] bg-neutral-4 flex items-center justify-center">
                            <p className="text-center text-neutral-1 font-quicksand text-[14px] font-semibold text-neutral-7">{key.toUpperCase()}</p>
                        </div>
                        <p className="text-center button2 text-neutral-8">{value}</p>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default QuestionCard;
