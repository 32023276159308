import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {

    const handlePrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const handlePageClick = (page) => {
        if (page !== currentPage) {
            onPageChange(page);
        }
    };

    // Generate page numbers
    const renderPageNumbers = () => {
        let pageNumbers = [];

        for (let i = 1; i <= totalPages; i++) {
            const isActive = currentPage === i;
            pageNumbers.push(
                <li key={i} className={`w-[35px] h-[35px] flex items-center justify-center border ${isActive ? 'border-primary-1 bg-primary-9 text-primary-1' : 'border-neutral-4'}`}>
                    <button
                        onClick={() => handlePageClick(i)}
                        className={`flex items-center justify-center w-full h-full ${isActive ? 'text-primary-1' : ''}`}
                    >
                        {i}
                    </button>
                </li>
            );
        }

        return pageNumbers;
    };

    return (
        <nav aria-label="Page navigation example" className="w-[261px] h-[35px]">
            <ul className="flex items-center text-font_color-body">
                {/* Previous Button */}
                <li className="w-[35px] h-[35px] flex items-center justify-center bg-white border border-e-0 border-neutral-4 rounded-s-lg text-[#8C8C8C]">
                    <button onClick={handlePrevious}>
                        <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                        </svg>
                    </button>
                </li>

                {/* Page Numbers */}
                {renderPageNumbers()}

                {/* Next Button */}
                <li className="w-[35px] h-[35px] flex items-center justify-center bg-white border border-neutral-4 rounded-e-lg text-[#8C8C8C]">
                    <button onClick={handleNext}>
                        <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                        </svg>
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
