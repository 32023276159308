import { UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAIL, UPDATE_PASSWORD_PENDING, RESET_UPDATE_PASSWORD } from "../../Actions/Types";

const initialState = {
    updatePassword: [],
};
const update_password_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                updatePassword: payload.updatePassword,
            };
        case UPDATE_PASSWORD_PENDING:
            return {
                ...state,
                updatePassword: payload.updatePassword,
            };
        case UPDATE_PASSWORD_FAIL:
            return {
                ...state,
                updatePassword: [],

            };
        case RESET_UPDATE_PASSWORD:
            return {
                ...state,
                updatePassword: initialState.updatePassword,
            };
        default:
            return state;
    }
};
export default update_password_reducer;