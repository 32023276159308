import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';



const SuccessModal = ({ isVisible, isMorphing, onStartLearningClick }) => {
    const [animationData, setAnimationData] = useState(null);
    useEffect(() => {
        // Fetch the Lottie JSON file from the public directory
        fetch('/course_enroll_success.json')
            .then((response) => response.json())
            .then((data) => setAnimationData(data))
            .catch((error) => console.error('Error loading Lottie animation:', error));
    }, []);
    return (
        <div
            className={`fixed inset-0 flex items-center m-[16px] justify-center z-50 bg-opacity-10 ${isVisible ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} transition-opacity duration-300`}
        >
            <div className="flex flex-col w-full lg:drop-shadow-2xl bg-white gap-[24px] p-[32px] rounded-[16px] items-center justify-center lg:w-[500px] lg:p-[40px] lg:gap-[40px] lg:rounded-[32px]">
                <Lottie
                    animationData={animationData}
                    className="mx-auto w-[180px] lg:w-[260px]"
                />
                <p className="subtitle3 text-font_color-body text-center lg:subtitle1">
                    Awesome! You've successfully joined the English course. Keep striving for excellence, and you'll achieve amazing things!
                </p>
                <button
                    onClick={onStartLearningClick}
                    className="button3 bg-primary-1 w-fit py-[10px] px-[20px] gap-[10px] text-white rounded-[6px] hover:bg-primary-4 w-fit">
                    Start Learning
                </button>
            </div>
        </div>
    )
}

export default SuccessModal;