import React from 'react';
import { TailSpin } from 'react-loader-spinner'; // Assuming you are using this spinner

const LoadingButton = ({
    onClick,
    isLoading,
    text,
    className
}) => {
    return (
        <button
            className={className}
            onClick={onClick}
            disabled={isLoading}
        >
            {isLoading ? (
                <TailSpin color="#FFFFFF" height={20} width={20} />
            ) : (
                text
            )}
        </button>
    );
};

export default LoadingButton;