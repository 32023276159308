import React from "react";
import { TailSpin } from "react-loader-spinner";

const RecapPreparingModal = ({ message}) => {

  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-40 rounded-3xl">
      <div className="text-center bg-white rounded-3xl  w-[300px] py-8 items-center flex flex-col justify-center gap-2">
        <p className="text-[#333] text-2xl mb-4 font-bold">
          {message}
        </p>
        <div className="w-[40%] h-[3px] bg-[#34D795] self-center"></div>
        <br />
        <TailSpin></TailSpin>
      </div>
    </div>
  );
}

export default RecapPreparingModal;