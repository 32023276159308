import React from "react";


const Search = ({text, onSearchChange}) => {
    return (
        <div class="relative flex items-center w-full h-[46px] rounded-[8px] border border-neutral-4 focus-within:shadow-lg bg-white overflow-hidden lg:w-[341px]">
            <div class="grid place-items-center h-full w-12">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-[16px] w-[16px]" fill="none" viewBox="0 0 24 24" stroke="#8C8C8C">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </div>
            <input
                class="peer h-full w-full outline-none body-2 text-font_color-body pr-2"
                type="text"
                onChange={onSearchChange}
                value={text}
                id="search"
                placeholder="Search" />
        </div>
    )
}
export default Search;