import { USER_COURSE_DETAIL_PENDING, USER_COURSE_DETAIL_SUCCESS, USER_COURSE_DETAIL_FAIL } from "../Actions/Types";

const initialState = {
    getUserCourseDetail: [],
};
const user_course_detail_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_COURSE_DETAIL_SUCCESS:
            return {
                ...state,
                getUserCourseDetail: payload.getUserCourseDetail,
            };
        case USER_COURSE_DETAIL_PENDING:
            return {
                ...state,
                getUserCourseDetail: payload.getUserCourseDetail,
            };
        case USER_COURSE_DETAIL_FAIL:
            return {
                ...state,
                getUserCourseDetail: [],
            };

        default:
            return state;
    }
};
export default user_course_detail_reducer;