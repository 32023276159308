import React from 'react';
import { useNavigate } from "react-router-dom";



export const SigninSignupImage = () => {
    const navigate = useNavigate();

    const handleBackToWebsite = () => {
        navigate("/")
    };
    return (

        <div className="relative max-w-full max-h-full h-[100vh] rounded-md flex items-center justify-center overflow-hidden bg-cover bg-center" style={{ backgroundImage: 'url("/signup-bg.jpeg")' }}>
            {/* Main image */}
            <img src="/AI-teacher.png" alt="signin_img" className="w-full h-full object-cover rounded-md" />

            {/* Knnect logo */}
            <img
                src="/LogoHeader.svg"
                alt="knnect"
                className="absolute w-[20%] max-w-[180px] h-auto top-4 left-4 md:top-8 md:left-8"
            />

            {/* Back to website button */}
            <button
                className="absolute min-w-[156px] min-h-[33px] top-4 right-4 md:top-8 md:right-8 px-4 py-2 rounded-full bg-[#FFFFFF4D] text-white flex items-center justify-center text-sm md:text-base"
                onClick={handleBackToWebsite}
            >
                Back to website
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-arrow-right-short ml-2"
                    viewBox="0 0 16 16"
                >
                    <path
                        fillRule="evenodd"
                        d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                    />
                </svg>
            </button>

            {/* Promotional text */}
            <div
                className="absolute w-[100%] bottom-0 rounded-b-lg bg-[#FFFFFF47] border border-[#FFFFFF1A] flex items-center justify-center p-4 backdrop-blur-md"
            >
                <h2
                    className="text-center text-white text-[24px] md:text-[48px] font-bold leading-tight"
                >
                    Want to boost your grades and achieve success? Let Knnect's AI make it happen!
                </h2>
            </div>
        </div>
    );
};