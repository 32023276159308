import { RESET_CREATE_SESSION, CREATE_SESSION_FAIL, CREATE_SESSION_PENDING, CREATE_SESSION_SUCCESS } from "../Actions/Types";

const initialState = {
    createSession: [],
};
const create_session_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CREATE_SESSION_SUCCESS:
            return {
                ...state,
                createSession: payload.createSession,
            };
        case CREATE_SESSION_PENDING:
            return {
                ...state,
                createSession: payload.createSession,
            };
        case CREATE_SESSION_FAIL:
            return {
                ...state,
                createSession: initialState.createSession,
            };
        case RESET_CREATE_SESSION:
            return {
                ...state,
                createSession: initialState.createSession,
            };

        default:
            return state;
    }
};
export default create_session_reducer;