import React from "react";
import Sidebar from "./Sidebar";
import { StudentContent } from "./StudentContent";
import Header from "../../../UIComponents/Header";



export const StudentMain = () => {
    return (
        <div className="h-[100vh] w-[100vw] flex flex-col overflow-hidden">
            <Header background={"bg-primary-1"} />
            <div className="flex flex-grow lg:overflow-hidden overflow-auto">
                <div className="hidden lg:block m-5">
                    <Sidebar />
                </div>
                <StudentContent />
            </div>
        </div>
    )
}