import React, { useEffect, useState } from 'react';
import Header from "../../UIComponents/Header";
import Lottie from 'lottie-react';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../Constants';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '../../Redux/Actions/Auth/UpdatePasswordAction';
import PasswordInputField from '../../UIComponents/PasswordInputField';
import LoadingButton from '../../UIComponents/LoadingButton';
const API_URL = BASE_URL


export const UpdatePassword = () => {
    const [AnimationData, setAnimationData] = useState(null);
    const [invalidAnimationData, setInvalidAnimationData] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useParams();
    const [isVerified, setIsVerified] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [formValues, setFormValues] = useState({
        "new_password": "",
        "confirm_password": ""
    });
    const [email, setEmail] = useState(null);
    const [errors, setErrors] = useState({});
    const updatePasswordStatus = useSelector((state) => state.update_password_reducer.updatePassword);


    useEffect(() => {
        if (updatePasswordStatus && updatePasswordStatus !== "isLoading" && updatePasswordStatus.length !== 0 && updatePasswordStatus.status === true) {
            setUpdateSuccess(true);
            setIsVerified(false);
        }
    }, [updatePasswordStatus]);

    useEffect(() => {
        // Fetch the Lottie JSON file from the public directory
        fetch('/update_password.json')
            .then((response) => response.json())
            .then((data) => setAnimationData(data))
            .catch((error) => console.error('Error loading Lottie animation:', error));
    }, []);

    useEffect(() => {
        // Fetch the Lottie JSON file from the public directory
        fetch('/invalid_link.json')
            .then((response) => response.json())
            .then((data) => setInvalidAnimationData(data))
            .catch((error) => console.error('Error loading Lottie animation:', error));
    }, []);

    useEffect(() => {
        // Function to verify the token
        const verifyToken = async () => {
            try {
                const response = await fetch(`${API_URL}verify-email/${token}`, {
                    method: 'GET',
                });
                const data = await response.json();
                console.log(data, "data of token");

                if (data.status === true) {
                    setEmail(data.result.email);
                    setIsVerified(true);

                } else {
                    setIsVerified(false);
                }
            } catch (error) {
                console.error('Error verifying token:', error);
                setIsVerified(false);
            } finally {
                setLoading(false);
            }
        };

        verifyToken();
    }, [token, navigate]);
    const validateForm = () => {
        const newErrors = {};
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#+])[A-Za-z\d@$!%*?&#+]{8,}$/;
        if (!formValues.new_password.trim()) newErrors.new_password = "Password is required.";
        else if (!passwordRegex.test(formValues.new_password)) newErrors.new_password = "Password must include: length greater than 8 characters, at least 1 uppercase, 1 numeric value and 1 special character";
        if (!formValues.confirm_password.trim()) newErrors.confirm_password = "Confirm password is required";
        else if (formValues.new_password !== formValues.confirm_password) newErrors.confirm_password = "Password do not match!"
        return newErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
        setErrors({});
    };
    const handleUpdatePassword = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const body = { ...formValues, email };
            dispatch(updatePassword(body));
        } else {
            // Set errors to display validation messages
            setErrors(formErrors);
        }
    };
    const handleTryAgain = () => {
        navigate("/signin");
    }
    return (
        <div className="w-full h-[100vh] overflow-hidden">
            <Header background={"bg-primary-1"} />
            <div
                className="lg:relative w-full h-[90vh] rounded-md flex items-start lg:items-center justify-center overflow-hidden"
            >
                <div
                    className="hidden lg:block absolute inset-0"
                    style={{
                        backgroundImage: "url('/verify_bg_image.png')",
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        zIndex: -10
                    }}
                ></div>
                <div
                    className="lg:absolute lg:top-[center] lg:left-[center] p-[20px] lg:p-[32px] flex flex-col lg:items-center gap-[12px] rounded-[16px] bg-white lg:shadow-[0px_12px_34px_0px_rgba(13,10,44,0.08),0px_34px_26px_0px_rgba(13,10,44,0.05)]"
                >
                    <div className='flex flex-col w-screen px-[20px] gap-[20px] text-font_color-primary lg:w-[504px] lg:gap-[40px]'>
                        {isVerified ? (
                            <>
                                <div className='flex flex-col gap-[8px] lg:gap-[16px]'>
                                    <h3 className='Heading-24 text-center lg:Heading-48'>Let’s Secure your account</h3>
                                    <p className='body-3 text-font_color-body text-center lg:body-2'>Create a new password, make it strong, make it yours!</p>
                                </div>
                                <div className='flex flex-col gap-[12px] lg:gap-[24px]'>
                                    <PasswordInputField
                                        label="New Password"
                                        name="new_password"
                                        value={formValues.new_password}
                                        onChange={handleChange}
                                        placeholder="Enter your password"
                                        errorMessage={errors.new_password}
                                    />
                                    <PasswordInputField
                                        label="Confirm Password"
                                        name="confirm_password"
                                        value={formValues.confirm_password}
                                        onChange={handleChange}
                                        placeholder="Enter your password"
                                        onEnterPress={handleUpdatePassword}
                                        errorMessage={errors.confirm_password}
                                    />
                                    <LoadingButton
                                        onClick={handleUpdatePassword}
                                        isLoading={updatePasswordStatus === 'isLoading'}
                                        text="Update Password"
                                        className="bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button3 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center transition-all duration-[300ms] ease-out lg:py-[13px] lg:button1"
                                    />

                                </div>
                            </>
                        )
                            : (
                                <div className='flex flex-col items-center gap-[16px] lg:gap-[32px]'>
                                    {updateSuccess && (
                                        <>
                                            <Lottie
                                                animationData={AnimationData}
                                                className='h-[168px] lg:h-[300px]'
                                            />
                                            <p className="body-3 text-font_color-body justify-center text-center lg:body-1">
                                                Your password has been successfully updated! Ready to dive back into your learning journey?
                                            </p>
                                            <button
                                                className="bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button3 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center transition-all duration-[300ms] ease-out lg:py-[13px] lg:button1"
                                                onClick={() => window.location.href = '/signin'}
                                            >
                                                Continue to login
                                            </button>
                                        </>
                                    )}
                                    {!updateSuccess && (
                                        <>
                                            <Lottie
                                                animationData={invalidAnimationData}
                                                className='w-[168px] lg:h-[300px]'
                                            // style={{ width: 300, height: 300 }}
                                            />
                                            <p className="body-3 text-font_color-body justify-center text-center lg:body-1">
                                                The verification link you used is either invalid or has expired.
                                            </p>
                                            <button
                                                className="bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button3 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center transition-all duration-[300ms] ease-out lg:py-[13px] lg:button1"
                                                onClick={handleTryAgain}
                                            >
                                                Try Again
                                            </button>
                                        </>

                                    )}
                                </div>
                            )
                        }

                    </div>
                    <footer className="mt-5 body-3 text-font_color-body text-center lg:hidden">
                        <p>© 2024 Knnect. All Rights Reserved.</p>
                    </footer>
                </div>

            </div>

        </div >
    )
}