import React, { useEffect, useState } from "react";
import "./courseavatar.css";
import { AVATAR_SDK_KEY } from '../Constants';
import { AVATAR_SDK_SCRIPT } from '../Constants';

const CourseOverviewModal = ({ isVisible, onClose, courseTitle, teacherData }) => {
    const [teacherIntro, setTeacherIntro] = useState([]);
    const TERMINAL_MESSAGE = "RESPONSE_IS_ENDED";
    const [delivered, setDelivered] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [introPause, setIntroPause] = useState(false);
    const [pauseContentDelivery, setPauseContentDelivery] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const SDK_LOADING_WAIT = 3000;
    // state to allow first time initialize speech
    const [initializeAvatarSpeech, setInitializeAvatarSpeech] = useState(false);
    // fuction to show avatar sdk in component
    useEffect(() => {
        const script = document.createElement('script');
        script.src = AVATAR_SDK_SCRIPT;
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
            const sdkOption = { sdk_key: AVATAR_SDK_KEY };
            const avatarOption = {
                avatar_id: teacherData.avatar_id,
                voice_code: "en_us", subtitle_code: "en_us", voice_tts_speech_speed: 0.9,
                // radius: 24,
            };

            if (window.KlleonChat) {
                window.KlleonChat.init(sdkOption);
                window.KlleonChat.showStreaming(avatarOption);
                window.KlleonChat.onChatEvent((eventData) => {
                    document.title = 'Knnect AI';
                    const sdkResponse = JSON.parse(eventData);
                    if (sdkResponse.chat_type === "ACTIVATE_VOICE") {
                        setTimeout(() => {
                            setInitializeAvatarSpeech(true);
                            handleEcho();
                        }, SDK_LOADING_WAIT);
                    }
                    if (sdkResponse.chat_type === TERMINAL_MESSAGE) {
                        setDelivered(true);
                    }
                });
            }
        };
        return () => {
            if (document.body.contains(script)) {
                document.body.removeChild(script);
            }
        };
    }, []);
    useEffect(() => {
        if (!pauseContentDelivery && delivered) {
            callNextLine();
        }
    }, [pauseContentDelivery, delivered]);
    // function to handle echo first time
    const handleEcho = () => {
        if (initializeAvatarSpeech && teacherIntro.length !== 0 && currentIndex < teacherIntro.length) {
            window.KlleonChat.echo(teacherIntro[currentIndex].sentence);
            setShowButton(true);
            setInitializeAvatarSpeech(false);
        }
    };
    useEffect(() => {
        handleEcho();
    }, [teacherIntro, initializeAvatarSpeech]);
    // function to echo next line after first object is echoed
    const callNextLine = () => {
        if (!pauseContentDelivery && delivered && currentIndex < teacherIntro.length - 1) {
            setDelivered(false);
            setCurrentIndex(currentIndex + 1);
            window.KlleonChat.echo(teacherIntro[currentIndex + 1].sentence);
        }
    };

    // fuction to pause delivering intro and stop echo
    const pauseIntro = () => {
        if (currentIndex !== 0) {
            setCurrentIndex(currentIndex - 1);
        }
        setIntroPause(true);
        setPauseContentDelivery(true);
        window.KlleonChat.stopEcho();
    };
    // function to resume echo
    const resumeIntro = () => {
        setPauseContentDelivery(false);
        setDelivered(true);
        setIntroPause(false);
    };

    // handle function to close modal and close avatar 
    const handleClose = () => {
        if (window.KlleonChat && typeof window.KlleonChat.close === "function") {
            window.KlleonChat.close();
        }
        onClose();
        setTeacherIntro([]);
    };
    const createIntroArray = (intro) => {
        return intro.split('.').filter(sentence => sentence.trim() !== "").map((sentence, index) => {
            return {
                id: index + 1,
                sentence: sentence.trim() + '.'
            };
        });
    };
    // Set the teacher intro in state
    useEffect(() => {
        if (teacherData && teacherData.intro) {
            const introArray = createIntroArray(teacherData.intro);
            setTeacherIntro(introArray);
        }
        console.log("teacher data", teacherData);

    }, []);
    if (!isVisible) return null;
    return (
        <div
            className={`fixed inset-0 z-50 px-[16px] flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ${isVisible ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
        >
            <div className="flex flex-col p-[16px] gap-[16px] rounded-[16px] bg-neutral-1 max-w-[350px] max-h-[80vh] shadow-shadow-6 shadow-shadow-7 lg:rounded-[24px] lg:p-[24px] lg:gap-[24px] lg:min-w-[900px] lg:max-h-[600px]">
                <div className="flex justify-between">
                    <div className="flex flex-col ">
                        <p className="text-left text-font_color-primary Heading-20 lg:Heading-24">Course overview by {teacherData.name}</p>
                        <p className="text-left text-neutral-7 subtitle3 lg:subtitle2">{courseTitle}</p>
                    </div>
                    <button
                        onClick={handleClose}
                        className="flex items-center justify-center w-[24px] h-[24px] hover:bg-neutral-4 rounded-[48px]">
                        <span className="w-[12px] h-[12px]">
                            <svg width="100%" height="100%" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.2 12.5001L22.35 3.3501C22.6875 3.0126 22.6875 2.4876 22.35 2.1501C22.0125 1.8126 21.4875 1.8126 21.15 2.1501L12 11.3001L2.84998 2.1501C2.51248 1.8126 1.98748 1.8126 1.64998 2.1501C1.31248 2.4876 1.31248 3.0126 1.64998 3.3501L10.8 12.5001L1.64998 21.6501C1.31248 21.9876 1.31248 22.5126 1.64998 22.8501C1.79998 23.0001 2.02498 23.1126 2.24998 23.1126C2.47498 23.1126 2.69998 23.0376 2.84998 22.8501L12 13.7001L21.15 22.8501C21.3 23.0001 21.525 23.1126 21.75 23.1126C21.975 23.1126 22.2 23.0376 22.35 22.8501C22.6875 22.5126 22.6875 21.9876 22.35 21.6501L13.2 12.5001Z" fill="#8C8C8C" />
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="flex-grow flex overflow-hidden">
                    <div className="w-full h-full relative overflow-hidden">
                        <div id="klleon_chat" className="w-full h-full"></div>
                        {!introPause && showButton && (
                            <div className="absolute top-6 right-6 flex justify-center">
                                <button onClick={pauseIntro}>
                                    <img src="/pause-button.svg" alt="" />
                                </button>
                            </div>
                        )}
                        {introPause && (
                            <div className="absolute top-6 right-6 flex justify-center">
                                <button onClick={resumeIntro}>
                                    <img src="/play-button.svg" alt="" />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CourseOverviewModal;
