import React, { useEffect, useRef } from "react";
import { KNNECT_DEMO_VIDEO_URL } from "../../../Constants"

const DemoModal = ({ isVisible, onClose }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (isVisible) {
            const video = videoRef.current;

            const playVideo = () => {
                video.play().catch((error) => {
                    console.error("Autoplay with sound blocked:", error);
                });
            };
            playVideo();
        }
    }, [isVisible]);

    if (!isVisible) return null;

    return (
        <div
            onClick={onClose}
            className={`fixed inset-0 z-50 px-[16px] flex items-center justify-center bg-black bg-opacity-30 transition-opacity duration-300 ${isVisible ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
        >
            <div className="rounded-[16px] max-w-[350px] lg:rounded-[24px] lg:min-w-[900px]">
                <video ref={videoRef} className="w-full h-auto rounded-[8px] lg:rounded-[16px]" controls
                    onClick={(e) => e.stopPropagation()}>
                    <source src={KNNECT_DEMO_VIDEO_URL} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default DemoModal;
