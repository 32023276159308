import { CHANGE_PASSWORD_FAIL, CHANGE_PASSWORD_PENDING, CHANGE_PASSWORD_SUCCESS, RESET_CHANGE_PASSWORD } from "../../Actions/Types";

const initialState = {
    changePassword: [],
};
const change_password_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePassword: payload.changePassword,
            };
        case CHANGE_PASSWORD_PENDING:
            return {
                ...state,
                changePassword: payload.changePassword,
            };
        case CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                changePassword: [],

            };
        case RESET_CHANGE_PASSWORD:
            return {
                ...state,
                changePassword: initialState.changePassword,
            };
        default:
            return state;
    }
};
export default change_password_reducer;