import React from "react";

const EmailNotVerified = ({ onSendVerificationClick, onCloseClick }) => {
    return (
        <div className="w-full flex justify-between items-center rounded-[8px] border boder-[#F27430] bg-[#FFF0E9] px-[16px] py-[10px]">
            <p className="subtitle3 text-left text-[#F27430]">Your email needs to be verified</p>
            <div className="flex items-center gap-[12px]">
                <button
                    onClick={onSendVerificationClick}
                    className="text-primary-1 button3 rounded-[6px] bg-neutral-1 py-[6px] lg:px-[16px] lg:button2 hover:text-primary-5">
                    Send Verification Link
                </button>
                <button
                    className="p-[1.05px] w-[16px] h-[16px] lg:w-[18px] lg:h-[18px]"
                    onClick={onCloseClick}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                </button>

            </div>

        </div>
    )

}

export default EmailNotVerified;