import React from 'react';

const InputField = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  placeholder,
  errorMessage
}) => {
  return (
    <div className="w-full flex flex-col gap-2 font-open_sans">
      <label className="subtitle2 lg:subtitle1">{label}</label>
      <div className="w-full h-10 bg-white border border-[#DFE4EA] rounded-[6px] flex items-center">
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="w-full h-full bg-transparent p-[8px_16px] border-none outline-none"
        />
      </div>
      {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
    </div>
  );
};

export default InputField;