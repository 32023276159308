import { FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAIL, FORGOT_PASSWORD_PENDING, RESET_FORGOT_PASSWORD } from "../../Actions/Types";

const initialState = {
  forgotPassword: [],
};
const forgot_password_reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: payload.forgotPassword,
      };
    case FORGOT_PASSWORD_PENDING:
      return {
        ...state,
        forgotPassword: payload.forgotPassword,
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        forgotPassword: [],

      };
    case RESET_FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: initialState.forgotPassword,
      };
    default:
      return state;
  }
};
export default forgot_password_reducer;