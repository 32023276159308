import React, { useState, useEffect, useRef } from 'react';

const StartLearning = ({ onClick }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [hasAnimated, setHasAnimated] = useState(false);
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !hasAnimated) {
                    setIsVisible(true);
                    setHasAnimated(true);
                }
            },
            { threshold: 0.4 }
        );

        if (ref1.current) observer.observe(ref1.current);
        if (ref2.current) observer.observe(ref2.current);

        return () => {
            if (ref1.current) observer.unobserve(ref1.current);
            if (ref2.current) observer.unobserve(ref2.current);
        };
    }, []);

    return (
        <div className="flex flex-col lg:flex-row w-full bg-[#EBFBF4] p-[20px] text-font_color-primary items-center justify-center lg:p-[80px] lg:gap-[40px]">
            <div
                ref={ref1}
                data-animation-type="bottom"
                className={`w-full lg:w-[40%] flex flex-col justify-center gap-[24px] lg:gap-[40px] transition-transform duration-[2000ms] ease-out ${isVisible ? 'transform translate-y-0 scale-100 opacity-100' : 'transform translate-y-12 scale-95 opacity-0'
                    }`}
            >
                <div className='flex flex-col gap-[24px]'>
                    <p className="Heading-20 lg:Heading-40">
                        Start learning with 50k students around the world.
                    </p>
                    <p className="body-3 text-font_color-body lg:body-1">
                        Join a global community of learners and experience personalized,
                        interactive lessons tailored to your goals. Connect, learn, and grow
                        together with Knnect’s AI-driven education.
                    </p>
                </div>
                <button
                    onClick={onClick}
                    className="w-fit bg-primary-1 text-neutral-1 button3 py-[8px] px-[16px] rounded-[6px] hover:bg-primary-5 lg:py-[13px] lg:px-[28px] lg:button1">
                    Start Learning Today
                </button>
            </div>
            <div
                ref={ref2}
                data-animation-type="bottom"
                className={`w-full lg:w-[60%] mt-6 lg:mt-0 transition-transform duration-[2000ms] ease-out ${isVisible ? 'transform translate-y-0 scale-100 opacity-100' : 'transform translate-y-12 scale-95 opacity-0'
                    }`}
            >
                <img className="w-full h-auto" src="./Union.png" alt="Learning Illustration" />
            </div>
        </div>
    );
};

export default StartLearning;

