import { BASE_URL } from '../Constants';
import axiosInstance from './AxiosInstance';

// Base URL for your API
const API_URL = BASE_URL;
const COURSES = "courses/";
const COURSE_DETAIL = "course-details/";
const USER_COURSES = "user-courses/";
const ENROLL_COURSE = "user_enrollments/create/";


class CourseServices {
    async getCourses() {
        return axiosInstance.get(API_URL + COURSES);
    }
    async getCourseDetail(id) {
        return axiosInstance.get(API_URL + COURSE_DETAIL + id);
    }
    async enrollCourse(body) {
        return axiosInstance.post(API_URL + ENROLL_COURSE, body);
    }
    async getUserCourses(body) {
        return axiosInstance.post(BASE_URL + USER_COURSES, body);
    }
}
export default new  CourseServices();