import React, { useEffect, useState } from "react";
import StudentAccordion from "./StudentAccordion";
import { useDispatch, useSelector } from "react-redux";
import { getStudentDetail } from "../../../Redux/Actions/Admin/AdminDashboardAction";
import { useParams } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { Select, MenuItem } from "@mui/material";
import CourseProgressCard from "../CourseProgressCard";
import SessionScoreCard from "../SessionScoreCard";
import NumbersCards from "../NumbersCards";


export const StudentDetail = () => {
    const dispatch = useDispatch();
    const { studentId } = useParams();
    const studentDetail = useSelector((state) => state.student_detail_reducer.getStudentDetail);
    const [selectedCourse, setSelectedCourse] = useState(studentDetail?.result?.courses ? studentDetail.result.courses[0] : null);
    const [selectedSession, setSelectedSession] = useState(selectedCourse ? selectedCourse.sessions[0] : null);
    // dispatch action to get student detail 
    useEffect(() => {
        const body = {
            "user_id": studentId
        }
        dispatch(getStudentDetail(body));
    }, [])

    // function to set selected course 
    const handleCourseSelect = (event) => {
        const selectedCourseId = event.target.value;
        const course = studentDetail.result.courses.find((course) => course._id === selectedCourseId);
        setSelectedCourse(course);
    };
    // function to set selected session
    const handleSessionSelect = (event) => {
        const selectedSessionId = event.target.value;
        const session = selectedCourse.sessions.find((session) => session.session_id === selectedSessionId);
        setSelectedSession(session);
    };
    return (
        <div className="flex flex-col mx-[10px] gap-[16px] lg:gap-[24px]">
            {studentDetail && studentDetail === "isLoading" && (
                <div className='flex items-center justify-center h-full'>
                    <TailSpin color="#34D795" height={50} width={50} />
                </div>
            )}
            {studentDetail && studentDetail !== "isLoading" && studentDetail.length !== 0 && studentDetail.result && studentDetail.result.length !== 0 && (
                <>
                    <div className="flex flex-col gap-[16px]">
                        <p className="text-left text-font_color-primary Heading-20 lg:Heading-32">{studentDetail.result.user.first_name + " " + studentDetail.result.user.last_name}</p>
                        <p className="text-left text-font_color-body body-2 lg:body-00">{studentDetail.result.user.email}</p>
                    </div>
                    {studentDetail.result.courses && studentDetail.result.courses.length !== 0 && (
                        <>
                            <div className="flex flex-col gap-[16px]">
                                <div className="gap-[24px] hidden lg:flex">
                                    <Select
                                        labelId="my-select-label"
                                        value={selectedCourse ? selectedCourse._id : setSelectedCourse(studentDetail.result.courses[0])}
                                        onChange={handleCourseSelect}
                                        className="w-[457px] rounded-[6px] h-[40px]"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 100,
                                                },
                                            },
                                        }}
                                    >
                                        {studentDetail.result.courses.map((course) => (
                                            <MenuItem
                                                className="body-2"
                                                key={course._id}
                                                value={course._id}
                                            >
                                                <span
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        width: '100%',
                                                    }}>{course.title}</span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Select
                                        labelId="my-select-label"
                                        value={selectedSession ? selectedSession.session_id : setSelectedSession(selectedCourse?.sessions[0])}
                                        onChange={handleSessionSelect}
                                        className="max-w-[457px] min-w-[300px] rounded-[6px] h-[40px]"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 100,
                                                },
                                            },
                                        }}
                                    >
                                        {selectedCourse?.sessions.map((session) => (
                                            <MenuItem
                                                className="body-2"
                                                key={session.session_id}
                                                value={session.session_id}
                                            >
                                                <span
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        width: '100%',
                                                    }}>{"Session with " + session.teacher.teacher_name}</span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>


                                <div className="flex flex-col w-[350px] gap-[16px] lg:gap-[24px] lg:flex-row lg:w-full">
                                    <div className="relative select-wrapper flex-col lg:hidden lg:order-1 order-2 lg:block">
                                        <Select
                                            labelId="my-select-label"
                                            value={selectedCourse ? selectedCourse._id : setSelectedCourse(studentDetail.result.courses[0])}
                                            onChange={handleCourseSelect}
                                            className="w-full rounded-[6px] h-[40px]"
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 100,
                                                    },
                                                },
                                            }}
                                        >
                                            {studentDetail.result.courses.map((course) => (
                                                <MenuItem
                                                    className="body-2"
                                                    key={course._id}
                                                    value={course._id}
                                                >
                                                    <span
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            width: '100%',
                                                        }}>{course.title}</span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="flex-col lg:flex lg:order-1 order-2">
                                        <CourseProgressCard
                                            CourseData={selectedCourse}
                                        />
                                    </div>
                                    <div className="relative select-wrapper flex-col lg:hidden lg:order-1 order-2 lg:block">
                                        <Select
                                            labelId="my-select-label"
                                            value={selectedSession ? selectedSession.session_id : setSelectedSession(selectedCourse?.sessions[0])}
                                            onChange={handleSessionSelect}
                                            className="w-full rounded-[6px] h-[40px]"
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 100,
                                                    },
                                                },
                                            }}
                                        >
                                            {selectedCourse?.sessions.map((session) => (
                                                <MenuItem
                                                    className="body-2"
                                                    key={session.session_id}
                                                    value={session.session_id}
                                                >
                                                    <span
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            width: '100%',
                                                        }}>{"Session with " + session.teacher.teacher_name}</span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="flex-col lg:order-2 order-3">
                                        <SessionScoreCard sessions={selectedSession} />
                                    </div>
                                    <div className="flex-col lg:order-3 order-1">
                                        <NumbersCards numberData={studentDetail.result} />
                                    </div>
                                </div>
                            </div>
                            {studentDetail.result.courses.map((course) => (
                                <StudentAccordion
                                    courseData={course}
                                />
                            ))}
                        </>
                    )}
                    {studentDetail.result.courses && studentDetail.result.courses.length === 0 && (
                        <p className="text-left body-1">Currently student is not enrolled in any course</p>
                    )}

                </>
            )}


        </div>
    )
}