import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const StudentProtected = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const isLoggedIn = useSelector((state) => state.signin_reducer.isLoggedIn);
  // navigate user to landing page if not logged in or not student
  if ((!isLoggedIn && !user) || (user && user.user.role !== 2)) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

export default StudentProtected;