import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDelivering: false, 
};

const lessonSlice = createSlice({
  name: "lesson",
  initialState,
  reducers: {
    startLessonDelivery: (state) => {
      state.isDelivering = true;
    },
    endLessonDelivery: (state) => {
      state.isDelivering = false;
    },
  },
});


export const { startLessonDelivery, endLessonDelivery } = lessonSlice.actions;

export default lessonSlice.reducer;