import React, { useEffect } from "react";
import NewStudentsCard from "./NewStudentsCard";
import TotalStudentsCard from "./TotalStudentsCard";
import StudentsTable from "./StudentsTable";
import Pagination from "../../../UIComponents/Pagination";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminDashboardData, getAllStudents } from "../../../Redux/Actions/Admin/AdminDashboardAction";
import { TailSpin } from 'react-loader-spinner';
import Search from "./Search";


export const AdminDashboard = () => {
    const [text, setText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const dashboardData = useSelector((state) => state.admin_dashboard_data_reducer.getDashboardData);
    const allStudentsData = useSelector((state) => state.all_student_reducer.getAllStudents);

    //dispatch action to get all students data
    useEffect(() => {
        const body = {
            "page_number": currentPage,
            "search_query": text
        }
        if (text.trim().length >= 3 || !text.trim()) {
            dispatch(getAllStudents(body));
        }
    }, [currentPage, text])
    // dispatch action to get admin data other than student detail
    useEffect(() => {
        dispatch(getAdminDashboardData());
    }, [])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleSearchChange = (event) => {
        setText(event.target.value);
        setCurrentPage(1);
    };
    return (
        <div className="flex flex-col gap-[40px] text-font_color-primary mx-[10px]">
            {dashboardData && dashboardData === "isLoading" && (
                <div className='flex items-center justify-center h-full'>
                    <TailSpin color="#34D795" height={50} width={50} />
                </div>
            )}
            {dashboardData && dashboardData !== "isLoading" && dashboardData.length !== 0 && dashboardData.result && dashboardData.result.length !== 0 && (
                <>
                    <p className="text-left Heading-20 lg:Heading-32">
                        Admin Dashboard
                    </p>
                    <div className="flex flex-col gap-[32px] lg:gap-[40px]">
                        <div className="flex flex-col gap-[16px] lg:flex-row lg:gap-[24px]">
                            <NewStudentsCard
                                numberOfNewStudents={dashboardData.result.no_of_new_students}
                                percentageOfNewStudents={dashboardData.result.percentage_of_new_students}
                            />
                            <TotalStudentsCard
                                totalStudents={dashboardData.result.no_of_students}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-[16px]">
                            <div className="flex flex-col gap-[24px] lg:flex-row lg:justify-between lg:items-center">
                                <p className="text-left text-font_color-primary Heading-18 lg:Heading-20">Students</p>
                                <Search
                                    text={text}
                                    onSearchChange={handleSearchChange}
                                />
                            </div>
                            <StudentsTable
                                studentsData={allStudentsData}
                            />
                        </div>
                        {allStudentsData && allStudentsData !== "isLoading" && allStudentsData.length !== 0 && allStudentsData.result && allStudentsData.result.length !== 0 && (
                            <div className="flex flex-col items-center gap-[12px] lg:flex-row lg:justify-between">
                                <p className="flex body-2 text-left text-font_color-body">{`Showing ${allStudentsData.result.from}-${allStudentsData.result.to} of ${dashboardData.result.no_of_students} results`}</p>
                                <div>
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={allStudentsData.result.total_pages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}

        </div>
    )
}