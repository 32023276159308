import { SIGNUP_FAIL, SIGNUP_PENDING, SIGNUP_SUCCESS, RESET_SIGNUP_STATE } from "../Types"
import AuthServices from "../../../Services/Auth/AuthServices"
import { toast } from "react-toastify"


export const signUp = (body) => async (dispatch) => {
    dispatch({
        type: SIGNUP_PENDING,
        payload: { signUp: "isLoading" },
    });

    try {
        const data = await AuthServices.signUp(body);

        if (data.data.status === true) {
            dispatch({
                type: SIGNUP_SUCCESS,
                payload: { signUp: data.data },
            });
            toast.success(data.data.message);
        } else {
            dispatch({ type: SIGNUP_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: SIGNUP_FAIL });
        toast.error(
            error?.response?.data?.message || "Something went wrong");
    }
};



export const resetSignupState = () => ({
    type: RESET_SIGNUP_STATE,
});