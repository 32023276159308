import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./hero.css"

const HeroSection = ({ onClick, onWatchDemo }) => {
    const navigate = useNavigate();
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className={`w-full flex flex-col hero-gradient gap-[40px] items-center justify-center py-[24px] overflow-hidden transition-all duration-500 ${scrolled ? 'pt-[50px]' : ''}`}>
            <div className="wrapper z-0">
                <svg className="circle top"></svg>
                <svg className="circle bottom"></svg>
            </div>
            <div
                className={`relative  transform flex flex-col items-center gap-[32px] lg:gap-[64px] transition-all ease-in-out duration-500 ${scrolled ? 'top-[0vh]' : 'top-[12vh] lg:top-[18vh]'}`}
            >
                <img
                    src="/knnect_logo_white.svg"
                    alt="logo"
                    className="w-[80px] h-[64px] lg:w-[96px] lg:h-[80px]"
                />
                <div className="flex flex-col gap-[24px] lg:gap-[48px]">
                    <div className="flex flex-col gap-[12px]">
                        <p
                            className="text-center text-neutral-1 Heading-24 lg:Heading-48"
                        >Unlock Potential
                        </p>
                        <p
                            className="font-sm-normal font-montserrat text-[28px] text-center text-neutral-1 lg:text-[80px]"
                        >with Knnect's AI Teacher</p>
                    </div>
                    <div className="flex gap-[24px] z-10 items-center justify-center">
                        <button
                            onClick={onWatchDemo}
                            className="flex items-center justify-center rounded-[6px] text-[14px] py-[10px] px-[16px] gap-[10px] font-montserrat font-semi-bold border-2 text-neutral-1 transition-all duration-300 ease-in-out hover:opacity-50 lg:py-[13px] lg:px-[32px] lg:text-[20px]">
                            Watch Demo
                            <span className="flex items-center justify-center lg:h-[24px] lg:w-[24px] h-[16px] w-[16px]">
                                <svg
                                    width="100%"
                                    height="100%"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M4.6665 2.66602V13.3327L13.3332 7.99935L4.6665 2.66602Z"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </span>
                        </button>
                        <button
                            onClick={onClick}
                            className="flex items-center bg-neutral-1 justify-center rounded-[6px] py-[10px] px-[16px] gap-[10px] font-montserrat font-semi-bold text-[14px] text-primary-6 border-2 hover:border-primary-5 lg:py-[13px] lg:px-[32px] lg:text-[20px]">
                            Explore
                            <span className="flex items-center justify-center lg:h-[24px] lg:w-[24px] h-[16px] w-[16px]">
                                <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 2.66663L7.06 3.60663L10.78 7.33329H2.66667V8.66663H10.78L7.06 12.3933L8 13.3333L13.3333 7.99996L8 2.66663Z" fill="#185E40" />
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div className={`w-full px-[20px] h-auto lg:w-[1024px] transition-all duration-500 ${scrolled ? 'transform translate-y-0' : 'transform translate-y-1/2'}`}>
                <svg width="100%" height="100%" viewBox="0 0 1046 331" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.957949 4.6369H51.4579V140.068L115.73 4.6369H166.23L106.09 122.623L167.149 326H114.353L71.6578 182.764L51.4579 223.623V326H0.957949V4.6369ZM186.459 4.6369H249.813L298.936 196.996H299.854V4.6369H344.845V326H292.968L232.368 91.4049H231.45V326H186.459V4.6369ZM377.788 4.6369H441.143L490.266 196.996H491.184V4.6369H536.175V326H484.297L423.697 91.4049H422.779V326H377.788V4.6369ZM569.118 4.6369H706.845V50.5459H619.618V135.478H688.94V181.387H619.618V280.091H706.845V326H569.118V4.6369ZM802.465 330.591C778.286 330.591 759.769 323.705 746.915 309.932C734.366 296.159 728.092 276.724 728.092 251.627V79.0095C728.092 53.9126 734.366 34.4778 746.915 20.7051C759.769 6.93235 778.286 0.0459984 802.465 0.0459984C826.643 0.0459984 845.007 6.93235 857.556 20.7051C870.41 34.4778 876.837 53.9126 876.837 79.0095V112.982H829.092V75.7959C829.092 55.902 820.675 45.955 803.842 45.955C787.009 45.955 778.592 55.902 778.592 75.7959V255.3C778.592 274.888 787.009 284.682 803.842 284.682C820.675 284.682 829.092 274.888 829.092 255.3V206.177H876.837V251.627C876.837 276.724 870.41 296.159 857.556 309.932C845.007 323.705 826.643 330.591 802.465 330.591ZM941.942 50.5459H889.147V4.6369H1045.24V50.5459H992.442V326H941.942V50.5459Z" fill="white" fillOpacity="0.1" />
                </svg>
            </div>
        </div>
    );
};

export default HeroSection;
