import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showModal: false,
  path: null, // Initialize path state
};

const LeaveAvatarSlice = createSlice({
  name: "lesson",
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.showModal = true;
      state.path = action.payload; 
    },
    endModal: (state) => {
      state.showModal = false;
      state.path = null; 
    },
  },
});


export const { showModal, endModal } = LeaveAvatarSlice.actions;

export default LeaveAvatarSlice.reducer;