import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const TimerManager = () => {
    const navigate = useNavigate();

    // Check and load remaining time from localStorage
    const savedTime = localStorage.getItem("remainingTime");
    const remainingTime = savedTime ? parseInt(savedTime, 10) : 0;

    useEffect(() => {
        if (!savedTime || remainingTime <= 0) {
            return;
        }

        // Create a persistent timer to update the remaining time
        const timer = setInterval(() => {
            const currentTime = localStorage.getItem("remainingTime");
            const updatedTime = currentTime ? parseInt(currentTime, 10) - 1 : 0;

            if (updatedTime < 0) {
                clearInterval(timer);
                localStorage.removeItem("remainingTime"); // Clear remaining time when it reaches zero
                window.dispatchEvent(new Event('timerComplete')); // Dispatch event for other components if needed
            } else {
                localStorage.setItem("remainingTime", updatedTime); // Update the remaining time in localStorage
            }
        }, 1000);

        return () => clearInterval(timer); // Cleanup on component unmount
    }, [navigate, remainingTime]);

    return null; // No need to render anything
};

export default TimerManager;
