import { BASE_URL } from '../Constants';
import { AI_BASE_URL } from '../Constants';
import axiosInstance from './AxiosInstance';

const USER_INTERRUPT_ENDPOINT = "handle-interrupt/";

// Base URL for your API
const API_URL = BASE_URL;
const GET_LESSON = "get-lesson/";
const EVALUATE_ASSESSMENT = "evaluate-assessment/";


class LessonServices {
    async getLessonsContent(body) {
        return axiosInstance.post(API_URL + GET_LESSON, body);
    }
    async evaluateAssessment(body) {
        return axiosInstance.post(API_URL + EVALUATE_ASSESSMENT, body);
    }
    

}

export default new LessonServices();

export const quizData = async (quizId) => {
    const requestPayload = {
      question_id: quizId,
    };
  
    try {
      const response = await axiosInstance.post(`${BASE_URL}get-quiz/`, requestPayload, {
      });
      return response.data;
    } catch (error) {
      console.error("Error while making API call:", error);
      return null;
    }
  };
  
  export const assessmentData = async (lesson_id) => {
    const requestPayload = {
      lesson_id: lesson_id,
    };
  
    try {
      const response = await axiosInstance.post(`${BASE_URL}get-assessment/`, requestPayload, {
      });
      return response.data;
    } catch (error) {
      console.error("Error while making API call:", error);
      return null;
    }
  };
  
  export const sendInterruptRequest = async (requestPayload) => {
    try {
      const response = await fetch(`${BASE_URL}${USER_INTERRUPT_ENDPOINT}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
      });
  
      if (response.ok) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let bufferedText = "";
        const jsonArray = []; 
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          bufferedText += decoder.decode(value, { stream: true });
          const jsonStrings = bufferedText.split(/(?<=})\s*(?={)/);
          for (let i = 0; i < jsonStrings.length - 1; i++) {
            try {
              const item = JSON.parse(jsonStrings[i]);
              jsonArray.push(item);
            } catch (error) {
              console.error("Failed to parse JSON:", error);
            }
          }
          bufferedText = jsonStrings[jsonStrings.length - 1];
        }
        try {
          if (bufferedText) {
            const item = JSON.parse(bufferedText);
            jsonArray.push(item);
          }
        } catch (error) {
          console.error("Failed to parse remaining JSON:", error);
        }
        return jsonArray;
      } else {
        console.error("Failed to fetch the API response");
        return null;
      }
    } catch (error) {
      console.error("Error while making API call:", error);
      throw error;
    }
  };

  export const sendStartLessonContentRequest = async (requestPayload) => {
    try {
      const response = await axiosInstance.post(`${BASE_URL}start-lesson/`, requestPayload);
      
      if (response.data.status === true) {
        const data = response.data;
        const correctedText = data.result.streaming_array;
        return correctedText; 
      } else {
        console.error("Failed to fetch the API response");
        return null;
      }
    } catch (error) {
      console.error("Error while making API call:", error);
      throw error;
    }
  };
  
  
    export const getLessonRecap = async (sessionLessonId) => {
      const requestPayload = {
        session_lesson_id: sessionLessonId,
      };
      try {
        const response = await axiosInstance.post(`${BASE_URL}get-lesson-recap/`, requestPayload, {
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching lesson recap:", error);
        throw error;
      }
    };
    
    export const getAIRecap = async (dataResult) => {
      try {
        const AIresponse = await axiosInstance.post(`${AI_BASE_URL}get_lesson_recap`, dataResult, {
        });
        return AIresponse;
      } catch (error) {
        console.error("Error fetching AI recap:", error);
        throw error;
      }
    };
    
    export const getSessionRecap = async (sessionId) => {
      const requestPayload = {
        session_id: sessionId,
      };
      try {
        const response = await axiosInstance.post(`${BASE_URL}get-session-recap/`, requestPayload, {
        });
        return response.data;
      } catch (error) {
        console.error("Error fetching session recap:", error);
        throw error;
      }
    };

    export const StoreNewSummary = async (sessionLessonId) => {
      try {
        const requestPayload = {
          session_lesson_id: sessionLessonId,
        };
        const response = await axiosInstance.post(`${BASE_URL}store-summary/`, requestPayload);
        return response.data;
      } catch (error) {
        console.error("Error storing summary:", error);
        throw error;
      }
    };