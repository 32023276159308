import { LESSON_CONTENT_FAIL, LESSON_CONTENT_SUCCESS, LESSON_CONTENT_PENDING, EVALUATE_ASSESSMENT_FAIL, EVALUATE_ASSESSMENT_PENDING, EVALUATE_ASSESSMENT_SUCCESS } from "./Types"
import UserServices from "../../Services/CommonServices"
import { toast } from "react-toastify"
import LessonServices from "../../Services/LessonServices";



export const evaluateAssessment = (body) => async (dispatch) => {
    dispatch({
        type: EVALUATE_ASSESSMENT_PENDING,
        payload: { evaluateAssessment: "isLoading" },
    });

    try {
        const data = await LessonServices.evaluateAssessment(body);

        if (data.data.status === true) {
            dispatch({
                type: EVALUATE_ASSESSMENT_SUCCESS,
                payload: { evaluateAssessment: data.data },
            });
        } else {
            dispatch({ type: EVALUATE_ASSESSMENT_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: EVALUATE_ASSESSMENT_FAIL });
        toast.error(error.message || "An unexpected error occurred");
    }
};


export const getLessonsContent = (body) => async (dispatch) => {
    dispatch({
        type: LESSON_CONTENT_PENDING,
        payload: { getLessonsContent: "isLoading" },
    });

    try {
        const data = await LessonServices.getLessonsContent(body);

        if (data.data.status === true) {
            dispatch({
                type: LESSON_CONTENT_SUCCESS,
                payload: { getLessonsContent: data.data },
            });
        } else {
            dispatch({ type: LESSON_CONTENT_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: LESSON_CONTENT_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};