import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../UIComponents/Header";
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import "./accountlocked.css"; // Importing the CSS file

export const AccountLocked = () => {
    const navigate = useNavigate();
    const BLOCKED_TIME = 1800;

    // Load the remaining time from localStorage
    const savedTime = localStorage.getItem("remainingTime");
    const initialRemainingTime = savedTime ? parseInt(savedTime, 10) : 0;

    const [isLocked, setIsLocked] = useState(true); // Track if the account is locked
    const [remainingTime, setRemainingTime] = useState(initialRemainingTime); // Set initial duration

    const handleBackToHome = () => {
        navigate("/");
    };

    const handleTimerComplete = () => {
        setIsLocked(false); // Unlock the account when timer completes
        localStorage.removeItem("remainingTime"); // Clear remaining time from localStorage
    };

    useEffect(() => {
        const handleTimerFinish = () => {
            handleTimerComplete(); // Unlock the account when timer completes
        };

        // Listen for the timerComplete event
        window.addEventListener('timerComplete', handleTimerFinish);

        return () => {
            window.removeEventListener('timerComplete', handleTimerFinish); // Cleanup event listener
        };
    }, []);

    useEffect(() => {
        // Update remaining time every second based on localStorage
        const interval = setInterval(() => {
            const currentTime = localStorage.getItem("remainingTime");
            if (currentTime) {
                const timeLeft = parseInt(currentTime, 10);
                setRemainingTime(timeLeft);
                if (timeLeft < 0) {
                    handleTimerComplete(); // Unlock if time is up
                }
            }
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    const renderTime = () => {
        const minutes = Math.floor(remainingTime / 60);
        const seconds = remainingTime % 60;

        return (
            <div className="flex flex-col items-center justify-center text-center gap-[8px]">
                <img
                    src={isLocked ? "/lock.svg" : "/unlock.svg"} // Change image based on lock state
                    alt="Countdown Icon"
                    width={80}
                    height={80}
                />
                <div className="text-[40px] font-medium font-montserrat text-font_color-primary">
                    <span>{minutes < 10 ? `0${minutes}` : minutes}:</span>
                    <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
                </div>
            </div>
        );
    };

    return (
        <div className="w-[100vw] h-[100vh]">
            <div className="fixed top-0 left-0 z-10 w-full">
                <Header background={"bg-primary-1"} />
            </div>
            <div className="w-full h-full flex flex-col items-center justify-center gap-[24px] px-[10px]">
                <CountdownCircleTimer
                    isPlaying={isLocked} // Only play if locked
                    duration={BLOCKED_TIME} // Set this only once
                    size={250}
                    strokeWidth={6}
                    trailStrokeWidth={4}
                    initialRemainingTime={initialRemainingTime}
                    rotation="counterclockwise"
                    trailColor="#DEE2E6"
                    colors='#34D795'
                    onComplete={handleTimerComplete}
                >
                    {renderTime}
                </CountdownCircleTimer>
                <div className={`flex flex-col gap-[32px] h-[133px] max-w-[350px] items-center lg:max-w-[515px]`}>
                    {isLocked ? (
                        <div className={`flex flex-col gap-[8px] text-center ${isLocked ? '' : 'flip-out'}`}>
                            <p className="text-font_color-primary Heading-18 lg:Heading-24">
                                Account Locked Temporarily
                            </p>
                            <p className="text-font_color-body body-3 lg:body-2">
                                It seems like you've tried signing in a few too many times. For your security, your account is temporarily locked for 30 minutes.
                            </p>
                        </div>
                    ) : (
                        <div className={`flex flex-col gap-[8px] text-center flip-in`}>
                            <p className="text-font_color-primary Heading-18 lg:Heading-24">
                                You're All Set!
                            </p>
                            <p className="text-font_color-body body-3 lg:body-2">
                                The 30-minute wait is over. You can now try signing in again.
                            </p>
                        </div>
                    )}
                    <button
                        onClick={handleBackToHome}
                        className="button1 text-neutral-1 bg-primary-1 rounded-[6px] w-[144px] py-[10px] hover:bg-primary-4">
                        Back to Home
                    </button>
                </div>
            </div>
        </div>
    );
};
