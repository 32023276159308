import React, { useState, useEffect, useRef } from "react";
import "./quizcard.css";
import SpeedRecognitionLib from 'annyang';
import Lottie from "lottie-react";
import { BASE_URL } from "../../Constants";

const QuizCard = ({ question, onQuizEnd, onQuizResponse, session_lesson_id, question_id }) => {
    const [selectedButton, setSelectedButton] = useState(null);
    const [isAnswered, setIsAnswered] = useState(false);
    const [shouldShake, setShouldShake] = useState(false);
    const [timeLeft, setTimeLeft] = useState(8);
    const [progress, setProgress] = useState(100);
    const [isStarted, setIsStarted] = useState(false);
    const [hasAnimated, setHasAnimated] = useState(false);
    const intervalRef = useRef(null);
    const [isListening, setIsListening] = useState(false);
    const [animationData, setAnimationData] = useState(null);
    const [inputMethodLocked, setInputMethodLocked] = useState(false); 

    let selectedAnswer = null;
    let queryAnswered = false;

    useEffect(() => {
        fetch('/voice_waves.json')
            .then((response) => response.json())
            .then((data) => setAnimationData(data))
            .catch((error) => console.error('Error loading Lottie animation:', error));
    }, []);

    const handleSelection = (letter, feedback) => {
        if (!isAnswered) {
            setSelectedButton(letter);
            setIsAnswered(true);

            if (letter !== question.correct_option) {
                setShouldShake(true);
                setTimeout(() => {
                    setShouldShake(false);
                }, 500);
            }

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            setTimeout(() => {
                onQuizResponse(feedback);
            }, 2000);
        }
    };

    const CheckUserAnswer = async (requestPayload) => {
        try {
            const AvatarResponse = await fetch(`${BASE_URL}evaluate-quiz/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestPayload),
            });

            if (AvatarResponse.ok) {
                const data = await AvatarResponse.json();
                if (requestPayload.is_attempted === true) {
                    handleSelection(data.result.user_option, data.result.feedback);
                }
            } else {
                console.error("Failed to fetch the API response");
            }
        } catch (error) {
            console.error("Error while making API call:", error);
        }
    };

    const handleSelect = (letter) => {
        if (inputMethodLocked) return; // Prevent selection if another method was triggered
        setInputMethodLocked(true); // Lock input method
        SpeedRecognitionLib.abort(); // Stop speech recognition if a button is clicked
        selectedAnswer = letter;
        const requestPayload = {
            question_id: question_id,
            session_lesson_id: session_lesson_id,
            user_answer: letter,
            is_attempted: true,
            is_verbal: false,
        };
        CheckUserAnswer(requestPayload);
    };

    const handleSpeechRecognition = async () => {
        if (inputMethodLocked) return; 

        setIsListening(true);

        if (SpeedRecognitionLib) {
            let finalAnswer = "";
            queryAnswered = false;
            SpeedRecognitionLib.removeCallback();

            const processSpeechResult = (speechResult) => {
                if (speechResult.trim() !== "") {
                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }
                    queryAnswered = true;
                    finalAnswer = speechResult;
                    SpeedRecognitionLib.abort();
                    setInputMethodLocked(true); 
                    const requestPayload = {
                      question_id: question_id,
                      session_lesson_id: session_lesson_id,
                      user_answer: finalAnswer,
                        is_attempted: true,
                        is_verbal: true,
                    };
                    CheckUserAnswer(requestPayload);
                }
            };

            SpeedRecognitionLib.start({ continuous: true });

            SpeedRecognitionLib.addCallback("start", () => {
                console.log("Speech recognition started. Please speak...");
                setIsListening(true);
            });

            SpeedRecognitionLib.addCallback("result", (speechResult) => {
                processSpeechResult(speechResult[0]);
            });

            SpeedRecognitionLib.addCallback("error", (event) => {
                console.error("Speech recognition error:", event);
            });

            SpeedRecognitionLib.addCallback("end", () => {
                setIsListening(false);
                console.log("Speech recognition ended.");
                if (!queryAnswered) {
                    SpeedRecognitionLib.abort();
                }
            });
        } else {
            console.error("Speech recognition not supported in this browser.");
        }
    };

    const optionBackground = (letter) => {
        if (!isAnswered) return 'bg-[#FBF9F9]';

        if (letter === question.correct_option) return 'bg-primary-2';

        if (letter === selectedButton && letter !== question.correct_option) {
            return shouldShake ? 'bg-red-500 animate-circular-shake' : 'bg-red-500';
        }

        return 'bg-[#FBF9F9]';
    };

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            handleSpeechRecognition();
            setIsStarted(true);
            const totalDuration = 15 * 1000;
            const startTime = Date.now();

            intervalRef.current = setInterval(() => {
                const elapsedTime = Date.now() - startTime;
                const timeRemaining = Math.max(15 - elapsedTime / 1000, 0);
                setTimeLeft(Math.floor(timeRemaining));

                const progressPercentage = Math.max(100 - (elapsedTime / totalDuration) * 100, 0);
                setProgress(progressPercentage);

                if (elapsedTime >= totalDuration) {
                    clearInterval(intervalRef.current);
                    if (!isAnswered) {
                        const requestPayload = {
                            question_id: question_id,
                            session_lesson_id: session_lesson_id,
                            user_answer: "",
                            is_attempted: false,
                            is_verbal: false,
                        };
                        CheckUserAnswer(requestPayload);
                        onQuizEnd();
                    }
                }
            }, 100);

            return () => clearInterval(intervalRef.current);
        }, 2000);

        return () => clearTimeout(delayTimer);
    }, []);

    useEffect(() => {
        setHasAnimated(true);
    }, []);

    return (
     
        <div className="flex flex-col bg-primary-1 rounded-[16px] gap-[16px] max-w-[350px] p-[16px] shadow-questioncard lg:max-w-[600px] lg:min-w-[600px] lg:p-[24px] lg:gap-[24px] lg:rounded-[24px]">
          <div className="flex gap-[8px] items-center">
            <div className="flex-grow bg-neutral-1 rounded-[24px] h-[8px]">
              <div
                className="bg-primary-2 h-[8px] rounded-[24px]"
                style={{
                  width: `${progress}%`,
                  transition: "width 0.1s linear",
                }}
              ></div>
            </div>
            <p className="subtitle2 text-left text-neutral-1">
              {isStarted ? `${timeLeft} sec` : "15 sec"}
            </p>
          </div>
          <div className="flex flex-col gap-[16px]">
            <p className="subtitle1 text-center text-neutral-1 lg:Heading-24">
              {question.question}
            </p>
            <div className="flex grid grid-cols-2 justify-center gap-[16px] lg:gap-[24px]">
              {Object.keys(question.choices).map((letter, index) => (
                <button
                  key={`button-${letter}-${shouldShake}`}
                  className={`flex items-center h-[56px] lg:h-[100px] rounded-[10px] ${optionBackground(
                    letter
                  )} ${hasAnimated && !isAnswered ? `animate-slide-in` : ""}`}
                  style={
                    hasAnimated && !isAnswered
                      ? { animationDelay: `${index * 0.3}s` }
                      : {}
                  }
                  onClick={() => handleSelect(letter)}
                  disabled={inputMethodLocked}
                >
                  <div className="min-w-[24px] h-full rounded-l-[10px] bg-neutral-4 flex items-center justify-center lg:min-w-[48px]">
                    <p className="text-center subtitle2 font-semibold text-neutral-7 lg:subtitle00">
                      {letter.toUpperCase()}
                    </p>
                  </div>
                  <p className="text-center p-[16px] text-font_color-primary text-[14px] font-weight-500">
                    {question.choices[letter]}
                  </p>
                </button>
              ))}
            </div>
          </div>
          {isListening && animationData && (
            <div className="w-fit bg-white p-4 rounded-xl self-center">
              <Lottie
                className="h-[80px] lg:h-[120px]"
                animationData={animationData}
                loop
                autoplay
              />
            </div>
          )}
        </div>

    );
};

export default QuizCard;
