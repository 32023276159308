import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';

const AssesmentResultModal = ({ assessmentResult, nextLesson }) => {
    const [animationData, setAnimationData] = useState(null);
    useEffect(() => {
        // Fetch the Lottie JSON file from the public directory
        fetch('/quiz_completed.json')
            .then((response) => response.json())
            .then((data) => setAnimationData(data))
            .catch((error) => console.error('Error loading Lottie animation:', error));
    }, []);
    return (
        <div className="flex flex-col w-[350px] shadow-questioncard rounded-[10px] items-center gap-[24px] py-[32px] px-[16px] bg-neutral-1 relative">
            <div className="flex flex-col gap-[16px]">
                <div className="flex flex-col items-center gap-[8px]">
                    <Lottie
                        animationData={animationData}
                        className="w-[146px] h-[96px]"
                    />
                    <p className="Heading-18 text-center text-font_color-primary">Congratulations on completing the quiz!</p>
                </div>
                <div className="flex items-center justify-center gap-[16px]">
                    <div className="flex flex-col p-[8px]">
                        <p className="text-center font-open_sans text-[34px] font-bold text-primary-1">{assessmentResult.correct}</p>
                        <p className="text-center text-font_color-primary button1">Correct</p>
                    </div>
                    <hr className="w-[24px] border-1 border-[#BFBFBF] transform rotate-90"></hr>
                    <div className="flex flex-col p-[8px]">
                        <p className="text-center font-open_sans text-[34px] font-bold text-redlight">{assessmentResult.wrong}</p>
                        <p className="text-center text-font_color-primary button1">Wrong</p>
                    </div>
                </div>
            </div>
            <button
                onClick={nextLesson}
                className="button2 px-[20px] py-[10px] gap-[10px] bg-primary-1 text-neutral-1 rounded-[6px] hover:bg-primary-4 w-fit lg:button2 lg:px-[28px] lg:py-[13px]"
            >
                Start Next Lesson
            </button>

        </div>
    )
}

export default AssesmentResultModal;