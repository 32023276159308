import { BASE_URL } from '../Constants';
import axiosInstance from './AxiosInstance';

// Base URL for your API
const API_URL = BASE_URL;
const DASHBOARD_DATA = "get-dashboard-data/";


class StudentDashboardServices {
    async getDashboardData(body) {
        return axiosInstance.post(API_URL + DASHBOARD_DATA, body
        );
    }
   
}
export default new StudentDashboardServices();