import { GOOGLE_SIGNIN_FAIL, GOOGLE_SIGNIN_PENDING, GOOGLE_SIGNIN_SUCCESS } from "../Types"
import AuthServices from "../../../Services/Auth/AuthServices"
import { toast } from "react-toastify"


export const signInWithGoogle = (body) => async (dispatch) => {
    dispatch({
        type: GOOGLE_SIGNIN_PENDING,
        payload: { signIn: "isLoading" },
    });

    try {
        const data = await AuthServices.signInWithGoogle(body);

        if (data.data.status === true) {
            localStorage.setItem('user', JSON.stringify(data.data.result));
            dispatch({
                type: GOOGLE_SIGNIN_SUCCESS,
                payload: { signIn: data.data },
            });
        } else {
            dispatch({ type: GOOGLE_SIGNIN_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: GOOGLE_SIGNIN_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};

