import { TEACHERS_FAIL, TEACHERS_PENDING, TEACHERS_SUCCESS } from "../Actions/Types";

const initialState = {
    getTeachers: [],
};
const teachers_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TEACHERS_SUCCESS:
            return {
                ...state,
                getTeachers: payload.getTeachers,
            };
        case TEACHERS_PENDING:
            return {
                ...state,
                getTeachers: payload.getTeachers,
            };
        case TEACHERS_FAIL:
            return {
                ...state,
                getTeachers: initialState.getTeachers,
            };

        default:
            return state;
    }
};
export default teachers_reducer;