import { LESSON_CONTENT_FAIL, LESSON_CONTENT_PENDING, LESSON_CONTENT_SUCCESS } from "../Actions/Types";

const initialState = {
    getLessonsContent: [],
};
const lesson_content_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case LESSON_CONTENT_SUCCESS:
            return {
                ...state,
                getLessonsContent: payload.getLessonsContent,
            };
        case LESSON_CONTENT_PENDING:
            return {
                ...state,
                getLessonsContent: payload.getLessonsContent,
            };
        case LESSON_CONTENT_FAIL:
            return {
                ...state,
                getLessonsContent: [],
            };

        default:
            return state;
    }
};
export default lesson_content_reducer;