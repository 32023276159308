import React, { useRef, useEffect, useState } from 'react';
import TeacherCard from './TeacherCard';

const TeachersCarousel = ({ allTeachers, onWatchIntroClick }) => {
    const carouselRef = useRef(null);
    const [showLeftGradient, setShowLeftGradient] = useState(false);
    const [showRightGradient, setShowRightGradient] = useState(false);

    // function to handle scroll functionality
    const handleScroll = () => {
        if (!carouselRef.current) return;
        const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
        setShowLeftGradient(scrollLeft > 0);
        setShowRightGradient(scrollLeft + clientWidth < scrollWidth);
    };

    // function to check and show scroll button if content more than carousel size
    const checkContentOverflow = () => {
        if (!carouselRef.current) return;
        const { scrollWidth, clientWidth } = carouselRef.current;
        setShowRightGradient(scrollWidth > clientWidth);
    };

    const handleScrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };

    const handleScrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;

        if (carousel) {
            checkContentOverflow();
            carousel.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', checkContentOverflow);
        }

        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', handleScroll);
            }
            window.removeEventListener('resize', checkContentOverflow);
        };
    }, []);

    return (
        <div>
            <div className="relative">
                <div
                    ref={carouselRef}
                    className="overflow-x-auto whitespace-nowrap scroll-smooth"
                    style={{
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                    }}
                >
                    {allTeachers && allTeachers.length !== 0 && allTeachers !== "isLoading" && allTeachers.result && allTeachers.result.length !== 0 && allTeachers.result.map((teacher) => (
                        <TeacherCard
                            key={teacher._id}
                            teacher={teacher}
                            onWatchIntro={() => onWatchIntroClick(teacher)}
                        />
                    ))}
                </div>
                {showLeftGradient && (
                    <div className="absolute top-0 left-0 w-[80px] h-[338px] bg-[linear-gradient(270deg,rgba(255,255,255,0)_0%,rgba(255,255,255,0.76)_53.06%,#FFFFFF_100%)] flex items-center lg:flex hidden">
                        <button
                            onClick={handleScrollLeft}
                            className="absolute top-1/2 left-0 w-[40px] h-[40px] flex items-center justify-center border border-neutral-5 rounded-full bg-white"
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.075 6.33711C14.7375 5.99961 14.2125 5.99961 13.875 6.33711L8.925 11.3996C8.5875 11.7371 8.5875 12.2621 8.925 12.5996L13.875 17.6621C14.025 17.8121 14.25 17.9246 14.475 17.9246C14.7 17.9246 14.8875 17.8496 15.075 17.6996C15.4125 17.3621 15.4125 16.8371 15.075 16.4996L10.6875 11.9996L15.075 7.53711C15.4125 7.19961 15.4125 6.63711 15.075 6.33711Z" fill="#434343" />
                            </svg>
                        </button>
                    </div>
                )}
                {showRightGradient && (
                    <div className="absolute top-0 right-0 w-[80px] h-[338px] bg-[linear-gradient(90deg,rgba(255,255,255,0)_0%,rgba(255,255,255,0.76)_53.06%,#FFFFFF_100%)] lg:flex hidden">
                        <button
                            onClick={handleScrollRight}
                            className="absolute top-1/2 right-0 transform w-[40px] h-[40px] flex items-center justify-center border border-neutral-5 rounded-full bg-white"
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.125 6.3376C9.7875 6.0001 9.2625 6.0001 8.925 6.3376C8.5875 6.6751 8.5875 7.2001 8.925 7.5376L13.3125 12.0001L8.925 16.4626C8.5875 16.8001 8.5875 17.3251 8.925 17.6626C9.075 17.8126 9.3 17.8876 9.525 17.8876C9.75 17.8876 9.975 17.8126 10.125 17.6251L15.1125 12.5626C15.45 12.2251 15.45 11.7001 15.1125 11.3626L10.125 6.3376Z" fill="#434343" />
                            </svg>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TeachersCarousel;

