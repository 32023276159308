import React, { useState } from "react";
import { SigninSignupImage } from "../../UIComponents/SignupSigninImage";
import { useDispatch, useSelector } from "react-redux";
import VerificationResend from "../../UIComponents/VerificationResend";
import { forgotPassword, resetForgotPassword } from "../../Redux/Actions/Auth/ForgotPasswordAction";
import Header from "../../UIComponents/Header";
import { useEffect } from "react";
import InputField from "../../UIComponents/InputField";
import LoadingButton from "../../UIComponents/LoadingButton";


export const ForgotPassword = () => {
    const [formValues, setFormValues] = useState({
        email: ''
    });
    const [errors, setErrors] = useState({});
    const [resendEmail, setResendEmail] = useState(null);
    //To show resend verification component
    const [showResend, setShowRensend] = useState(false);
    const dispatch = useDispatch();
    //reading response of forgot password from reducer state
    const forgotPasswordStatus = useSelector((state) => state.forgot_password_reducer.forgotPassword);
    const validateForm = () => {
        let valid = true;
        const newErrors = { email: '' };

        // Email validation
        if (!formValues.email.trim()) {
            newErrors.email = 'Email is required';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formValues.email.trim())) {
            newErrors.email = 'Invalid email format';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setErrors({});
    };
    //function to dispatch forgot password action
    const handleSendResetLink = () => {
        const isFormValid = validateForm(); // Run validation
        // Only dispatch the action if form is valid (no errors)
        if (isFormValid) {
            const body = {
                email: formValues.email.trim(),
            };
            // Dispatch action to send reset link
            dispatch(forgotPassword(body));
        }
    };

    //to close resend verification link component
    const handleClose = () => {
        dispatch(resetForgotPassword());
    };
    //to dispaly resend verification link component and reseting form values
    useEffect(() => {
        if (forgotPasswordStatus && forgotPasswordStatus !== "isLoading" && forgotPasswordStatus.status === true) {
            setShowRensend(true);
            setResendEmail(formValues.email);
            setFormValues({ email: '' });
        } else {
            setShowRensend(false);
        }
    }, [forgotPasswordStatus]);

    // resend email
    const handleResendEmail = () => {
        const body = {
            "email": resendEmail.trim()
        }
        dispatch(forgotPassword(body));
    };
    return (
        <div className="p-0 m-0 w-full h-screen lg:w-screen lg:h-screen lg:flex lg:p-5">

            <div className="hidden lg:flex lg:flex-1 items-center justify-end">
                <SigninSignupImage />
            </div>

            <div className="lg:hidden w-full flex">
                <Header background={"bg-primary-1"} />
            </div>

            <div className="w-full py-4 px-4 text-font_color-primary lg:flex-1 flex flex-col items-center justify-center lg:pl-24 lg:pr-[14%] lg:gap-4 lg:max-w-[50vw]">
                <div className="w-full flex flex-col text-font_color-primary gap-[24px] lg:gap-[40px]">
                    <div className="w-full flex flex-col gap-[8px] items-center lg:items-start lg:justify-between lg:gap-4">
                        <h2 className="Heading-24 lg:Heading-48 text-left">
                            Forgot Password?
                        </h2>
                        <p className="body-2 text-font_color-body text-center lg:body-1 lg:text-left">
                            No worries! Enter your email, and we'll help you reset it quickly.
                        </p>

                    </div>
                    {showResend === true && (
                        <VerificationResend
                            text="A password reset link has been sent to your email."
                            resendEmail={handleResendEmail}
                            onClose={handleClose} />
                    )}
                    <div className="w-full flex flex-col gap-6">
                        <InputField
                            label="Email"
                            name="email"
                            value={formValues.email}
                            onChange={handleInputChange}
                            placeholder="Enter your email"
                            errorMessage={errors.email}
                        />
                        <LoadingButton
                            onClick={handleSendResetLink}
                            isLoading={forgotPasswordStatus === 'isLoading'}
                            text="Send Reset Link"
                            className={"bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button2 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center lg:py-[13px] lg:button1"}
                        />
                    </div>
                    <footer className="body-3 text-font_color-body text-center lg:hidden">
                        <p>© 2024 Knnect. All Rights Reserved.</p>
                    </footer>
                </div>
                <footer className="hidden lg:block text-font_color-body body-1 text-center relative bottom-0">
                    <p>© 2024 Knnect. All Rights Reserved.</p>
                </footer>
            </div>

        </div>
    );
}