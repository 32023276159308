import { ROLES_FAIL, ROLES_PENDING, ROLES_SUCCESS } from "../Actions/Types";

const initialState = {
    getRoles: [],
};
const role_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ROLES_SUCCESS:
            return {
                ...state,
                getRoles: payload.getRoles,
            };
        case ROLES_PENDING:
            return {
                ...state,
                getRoles: payload.getRoles,
            };
        case ROLES_FAIL:
            return {
                ...state,
                getRoles: [],
            };

        default:
            return state;
    }
};
export default role_reducer;