import { COURSE_ENROLLMENT_PENDING, COURSE_ENROLLMENT_SUCCESS, COURSE_ENROLLMENT_FAIL, RESET_ENROLL_COURSE } from "../Actions/Types";

const initialState = {
    courseEnrollment: [],
};
const course_enrollment_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case COURSE_ENROLLMENT_SUCCESS:
            return {
                ...state,
                courseEnrollment: payload.courseEnrollment,
            };
        case COURSE_ENROLLMENT_PENDING:
            return {
                ...state,
                courseEnrollment: payload.courseEnrollment,
            };
        case COURSE_ENROLLMENT_FAIL:
            return {
                ...state,
                courseEnrollment: initialState.courseEnrollment,
            };
            case RESET_ENROLL_COURSE:
            return {
                ...state,
                courseEnrollment: initialState.courseEnrollment,
            };

        default:
            return state;
    }
};
export default course_enrollment_reducer;