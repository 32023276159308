import { ADMIN_DASHBOARD_DATA_PENDING, ADMIN_DASHBOARD_DATA_SUCCESS, ADMIN_DASHBOARD_DATA_FAIL } from "../Actions/Types";

const initialState = {
    getDashboardData: [],
};
const admin_dashboard_data_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADMIN_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                getDashboardData: payload.getDashboardData,
            };
        case ADMIN_DASHBOARD_DATA_PENDING:
            return {
                ...state,
                getDashboardData: payload.getDashboardData,
            };
        case ADMIN_DASHBOARD_DATA_FAIL:
            return {
                ...state,
                getDashboardData: initialState.getDashboardData,
            };

        default:
            return state;
    }
};
export default admin_dashboard_data_reducer;