import { ADMIN_DASHBOARD_DATA_FAIL, ADMIN_DASHBOARD_DATA_PENDING, ADMIN_DASHBOARD_DATA_SUCCESS, ALL_STUDENTS_FAIL, ALL_STUDENTS_SUCCESS, ALL_STUDENTS_PENDING, STUDENT_DETAIL_FAIL, STUDENT_DETAIL_PENDING, STUDENT_DETAIL_SUCCESS } from "../Types"
import { toast } from "react-toastify"
import AdminServices from "../../../Services/Admin/AdminServices";

export const getAdminDashboardData = () => async (dispatch) => {
    dispatch({
        type: ADMIN_DASHBOARD_DATA_PENDING,
        payload: { getDashboardData: "isLoading" },
    });

    try {
        const data = await AdminServices.getAdminDashboardData();

        if (data.data.status === true) {
            dispatch({
                type: ADMIN_DASHBOARD_DATA_SUCCESS,
                payload: { getDashboardData: data.data },
            });
        } else {
            dispatch({ type: ADMIN_DASHBOARD_DATA_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: ADMIN_DASHBOARD_DATA_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};

export const getAllStudents = (body) => async (dispatch) => {
    dispatch({
        type: ALL_STUDENTS_PENDING,
        payload: { getAllStudents: "isLoading" },
    });

    try {
        const data = await AdminServices.getAllStudents(body);

        if (data.data.status === true) {
            dispatch({
                type: ALL_STUDENTS_SUCCESS,
                payload: { getAllStudents: data.data },
            });
        } else {
            dispatch({ type: ALL_STUDENTS_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: ALL_STUDENTS_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};


export const getStudentDetail = (body) => async (dispatch) => {
    dispatch({
        type: STUDENT_DETAIL_PENDING,
        payload: { getStudentDetail: "isLoading" },
    });

    try {
        const data = await AdminServices.getStudentDetail(body);

        if (data.data.status === true) {
            dispatch({
                type: STUDENT_DETAIL_SUCCESS,
                payload: { getStudentDetail: data.data },
            });
        } else {
            dispatch({ type: STUDENT_DETAIL_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: STUDENT_DETAIL_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};