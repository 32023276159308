import React, { useState, useEffect, useRef } from 'react';

const LandingPageFooter = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [hasAnimated, setHasAnimated] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !hasAnimated) {
                    setIsVisible(true);
                    setHasAnimated(true);
                }
            },
            { threshold: 0.4 }
        );

        if (ref.current) observer.observe(ref.current);

        return () => {
            if (ref.current) observer.unobserve(ref.current);
        };
    }, []);
    return (
        <div
            ref={ref}
            data-animation-type="bottom"
            className={`flex flex-col w-full justify-center items-center gap-[24px] p-[20px] lg:px-[80px] lg:pt-[80px] lg:pb-[40px] lg:gap-[40px] transition-transform duration-[2000ms] ease-out ${isVisible ? 'transform translate-y-0 scale-100 opacity-100' : 'transform translate-y-12 scale-95 opacity-0'
                }`}>
            <div
                className="flex flex-col w-full items-start gap-[20px] text-font_color-primary justify-start lg:flex-row lg:gap-[16px]">
                <div className="flex-1 flex flex-col items-start gap-[20px] lg:gap-[24px]">
                    <div className="flex flex-col gap-[16px] lg:gap-[24px]">
                        <img src="./Frame 2.svg" alt="Logo" />
                        <p className=" text-font_color-body body-3 lg:body-0">
                            Interactive lessons, real-time quizzes, and personalized
                            <br />
                            feedback to help you master any subject, and personalized
                            <br />
                            feedback to help you master any subject.
                        </p>
                    </div>

                    <div className="flex gap-[16px]">
                        <img src="./Facebook_landing.svg" alt="Facebook" />
                        <img src="./Youtube_landing.svg" alt="YouTube" />
                        <img src="./instagram_landing.svg" alt="Instagram" />
                    </div>
                </div>
                <div className="flex-[0.6] flex flex-col items-start gap-[20px]">
                    <h2 className="button1 lg:Heading-16">
                        Resources
                    </h2>
                    <div className="grid grid-cols-2 gap-[12px] lg:grid-cols-1 text-font_color-body body-1">
                        <div className="col-span-1">
                            <p>Privacy policy</p>
                        </div>
                        <div className="col-span-1">
                            <p>Legal</p>
                        </div>
                        <div className="col-span-1">
                            <p>Terms of service</p>
                        </div>
                        <div className="col-span-1">
                            <p>Help center</p>
                        </div>
                    </div>

                </div>
                <div className="flex-1 flex flex-col items-start gap-[20px]">
                    <h2 className="button1 lg:Heading-16">
                        Get in touch
                    </h2>
                    <div className="flex flex-col gap-[16px] text-font_color-body body-1">
                        <div className="flex gap-2">
                            <img src="./map-marker.svg" alt="Location" />
                            <p>
                                Kualalumpur, Malaysia
                            </p>
                        </div>
                        <div className="flex gap-2">
                            <img src="./phone.svg" alt="Phone" />
                            <p>
                                +60 09 743 1039
                            </p>
                        </div>
                        <div className="flex gap-2">
                            <img src="./mail.svg" alt="Email" />
                            <p>
                                support@knnect
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <p className="text-font_color-body body-2 text-center lg:body-0">
                © 2024 All rights reserved.
            </p>
        </div>
    );
};

export default LandingPageFooter;
