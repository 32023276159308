import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import AccordionItem from './AccordionItem';

const AskedQuestions = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.4,
    });

    const itemVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <div
            ref={ref}
            className="flex bg-[#F1F5F9] flex-col justify-center items-center gap-[24px] text-font_color-primary p-[20px] lg:p-[80px]"
        >
            <h2 className="Heading-20 text-center lg:Heading-40">
                Frequently asked questions
            </h2>
            <div className="w-full flex flex-col justify-center gap-[24px]">
                {[
                    {
                        title: "How does Knnect personalize my study plan?",
                        content:
                            "Knnect uses advanced AI algorithms to analyze your learning style, academic goals, and progress. It then creates a tailored study plan that adapts as you learn, ensuring you stay on track and achieve your objectives.",
                    },
                    {
                        title: "Is Knnect suitable for all age groups?",
                        content:
                            "Absolutely! Knnect is designed to cater to students of all ages and educational levels, from elementary school to university and beyond.",
                    },
                    {
                        title: "What kind of support does Knnect provide?",
                        content:
                            "Knnect offers 24/7 support through our AI teachers, who provide real-time feedback and assistance. Additionally, our help center and customer support team are always available to answer your questions.",
                    },
                    {
                        title: "How does progress tracking work in Knnect?",
                        content:
                            "Knnect tracks your progress through interactive lessons, quizzes, and assignments. You can view detailed analytics and insights on your dashboard to monitor your performance and identify areas for improvement.",
                    },
                    {
                        title: "Is my data secure with Knnect?",
                        content:
                            "Yes, we take data security very seriously. Knnect uses state-of-the-art encryption and security protocols to protect your personal information and learning data.",
                    },
                ].map((item, i) => (
                    <motion.div
                        key={i}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={itemVariants}
                        transition={{
                            duration: 0.7,
                            ease: "easeOut",
                            delay: i * 0.2,
                        }}
                    >
                        <AccordionItem title={item.title} content={item.content} />
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default AskedQuestions;




