import { COURSE_DETAIL_PENDING, COURSE_DETAIL_SUCCESS, COURSE_DETAIL_FAIL } from "../Actions/Types";

const initialState = {
    getCourseDetail: [],
};
const course_detail_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case COURSE_DETAIL_SUCCESS:
            return {
                ...state,
                getCourseDetail: payload.getCourseDetail,
            };
        case COURSE_DETAIL_PENDING:
            return {
                ...state,
                getCourseDetail: payload.getCourseDetail,
            };
        case COURSE_DETAIL_FAIL:
            return {
                ...state,
                getCourseDetail: initialState.getCourseDetail,
            };

        default:
            return state;
    }
};
export default course_detail_reducer;