import React from "react";


const CourseEnrolementCard = ({ course, onExploreCourseClick }) => {
    // Handler to be used for both div and button clicks
    const handleClick = () => {
        if (course.is_active) {
            onExploreCourseClick(course._id);
        }
    };
    return (
        <div
            onClick={handleClick}
            className="rounded-[8px] shadow-enrolcard-light w-[308px] cursor-pointer">
            <img
                className="h-[172px] w-[308px] rounded-t-sm"
                src={course.image_url}
                alt="card enrollment image"
            />
            <div className="flex flex-col w-[308px] gap-[28px] p-[20px] bg-white">
                <div className="flex flex-col w-[268px] gap-[8px]">
                    <p className="font-open_sans text-md font-normal leading-normal text-enrolcard-grey">{course.course_category_name}</p>
                    <h2 className="w-[268px] h-[66px] Heading-18 text-font_color-primary">
                        {course.title}
                    </h2>
                </div>

                {course.is_active ? (
                    <button
                        onClick={handleClick}
                        className="flex items-center text-md font-normal py-[12px] gap-[10px] text-primary-1 bg-transparent border-none cursor-pointer">
                        Explore Course Outline
                        <span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 2.66663L7.06 3.60663L10.78 7.33329H2.66667V8.66663H10.78L7.06 12.3933L8 13.3333L13.3333 7.99996L8 2.66663Z" fill="#34D795" />
                            </svg>
                        </span>
                    </button>
                )
                    : (<p className="body-1 text-neutral-7 py-[12px]">Coming Soon</p>)
                }
            </div>
        </div>
    )
}
export default CourseEnrolementCard;