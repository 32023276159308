import { UPDATE_PROFILE_FAIL, UPDATE_PROFILE_PENDING, UPDATE_PROFILE_SUCCESS } from "../Types"
import { toast } from "react-toastify"
import AuthServices from "../../../Services/Auth/AuthServices";

export const updateProfile = (body) => async (dispatch) => {
    dispatch({
        type: UPDATE_PROFILE_PENDING,
        payload: { updateProfile: "isLoading" },
    });

    try {
        const data = await AuthServices.updateProfile(body);

        if (data.data.status === true) {
            const currentUserData = JSON.parse(localStorage.getItem('user'));
            const updatedUser = {
                ...currentUserData,
                user: {
                    ...currentUserData.user,
                    ...data.data.result,
                },
            };
            localStorage.setItem('user', JSON.stringify(updatedUser));
            dispatch({
                type: UPDATE_PROFILE_SUCCESS,
                payload: { updateProfile: data.data },
            });
            toast.success(data.data.message);
        } else {
            dispatch({ type: UPDATE_PROFILE_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: UPDATE_PROFILE_FAIL });
        toast.error(error.message || "An unexpected error occurred");
    }
};