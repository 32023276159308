import React from "react";

const StatusLabel = ({ label, labelAction }) => {

    return (
        <div className="absolute top-2 right-2 sm:top-6 sm:right-6 flex justify-center items-center p-2 sm:p-4 bg-[#EBFBF4] w-auto sm:w-auto rounded-lg z-40">
            <p className="text-center text-[12px] sm:text-[14px] font-open_sans text-[#333]">{label}</p>

            <div>
                <button
                    className="w-[70px] sm:w-[100px] h-[24px] sm:h-[30px] bg-white rounded-lg flex justify-center text-[#34D795] border-2 border-[#34D795] text-center items-center ml-4"
                    onClick={labelAction}
                >
                    Skip
                </button>
            </div>
        </div>
    );
}

export default StatusLabel;