import { BASE_URL } from '../Constants';
import axiosInstance from './AxiosInstance';

// Base URL for your API
const API_URL = BASE_URL;
const USER_COURSE_DETAIL = "user-course-detail/";
const CREATE_SESSION = "create-session/";


class SessionServices {
    async getUserCourseDetail(body) {
        return axiosInstance.post(API_URL + USER_COURSE_DETAIL, body);
    }
    async createSession(body) {
        return axiosInstance.post(API_URL + CREATE_SESSION, body
        );
    }
   
}

export default new SessionServices();