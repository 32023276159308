import { SIGNUP_FAIL, SIGNUP_PENDING, SIGNUP_SUCCESS, RESET_SIGNUP_STATE } from "../../Actions/Types";
const initialState = {
  signUp: [],
};
const signup_reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SIGNUP_SUCCESS:
      return {
        ...state,
        signUp: payload.signUp,
      };
    case SIGNUP_PENDING:
      return {
        ...state,
        signUp: payload.signUp,
      };
    case SIGNUP_FAIL:
      return {
        ...state,
        signUp: [],

      };
    case RESET_SIGNUP_STATE:
      return {
        ...state,
        signUp: initialState.signUp,
      };
    default:
      return state;
  }
};
export default signup_reducer;