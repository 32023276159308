import { GOOGLE_SIGNIN_FAIL, GOOGLE_SIGNIN_PENDING, GOOGLE_SIGNIN_SUCCESS } from "../../Actions/Types";
const initialState = {
    signIn: [],
    isLoggedIn: false,
};
const google_signin_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GOOGLE_SIGNIN_SUCCESS:
            return {
                ...state,
                signIn: payload.signIn,
                isLoggedIn: true,
            };
        case GOOGLE_SIGNIN_PENDING:
            return {
                ...state,
                signIn: payload.signIn,
                isLoggedIn: false,
            };
        case GOOGLE_SIGNIN_FAIL:
            return {
                ...state,
                signIn: [],
                isLoggedIn: false,

            };

        default:
            return state;
    }
};
export default google_signin_reducer;