import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetSignIn } from '../../../Redux/Actions/Auth/SignInAction';


const AdminSidebar = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [sidebar, setSidebar] = useState("");
    // function to close side bar
    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };
    // set side bar state when path changed
    useEffect(() => {
        setSidebar(location.pathname);
    }, [location])
    // logout function
    const handleLogout = () => {
        localStorage.removeItem("user");
        dispatch(resetSignIn());
        navigate("/");
    }
    // handle function to set side bar option
    const handleSidebarClick = (path) => () => {
        navigate(path);

    };
    return (
        <div
            className={`${isCollapsed ? 'w-[80px]' : 'w-[280px]'
                } h-full flex flex-col gap-6 rounded-lg bg-white shadow-[0px_16px_44px_0px_rgba(0,0,0,0.07)] transition-all duration-700 ease-in-out relative`}
        >
            <button
                onClick={toggleSidebar}
                className="absolute top-4 right-[-16px] w-[24px] h-[24px] p-1 flex items-center justify-center rounded-full border border-gray-300 bg-white hover:bg-gray-100 transition-colors duration-200"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`w-4 h-4 transform transition-transform duration-300 ${isCollapsed ? 'rotate-180' : ''
                        }`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 19l-7-7 7-7"
                    />
                </svg>
            </button>
            <nav className="w-full py-[32px] flex flex-col gap-[24px]">
                <ul className="flex flex-col gap-4 items-center px-3">

                    <li
                        className={`w-full min-h-[44px] px-4 py-4 flex items-center gap-3 rounded-full font-medium cursor-pointer transition-all duration-500 ${isCollapsed ? 'hover:text-sidebar-black' : 'hover:gap-4 hover:text-sidebar-black'
                            } ${sidebar.startsWith("/admin/dashboard") ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'
                            }`}
                        onClick={handleSidebarClick("dashboard")}
                    >
                        <span className="h-[20px] w-[20px]">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                stroke="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                className="transition-colors duration-300 ease-in-out"
                            >
                                <path
                                    d="M14.1663 11.6663V16.6663M11.6663 14.1663H16.6663M4.99967 8.33301H6.66634C7.58682 8.33301 8.33301 7.58682 8.33301 6.66634V4.99967C8.33301 4.0792 7.58682 3.33301 6.66634 3.33301H4.99967C4.0792 3.33301 3.33301 4.0792 3.33301 4.99967V6.66634C3.33301 7.58682 4.0792 8.33301 4.99967 8.33301ZM13.333 8.33301H14.9997C15.9201 8.33301 16.6663 7.58682 16.6663 6.66634V4.99967C16.6663 4.0792 15.9201 3.33301 14.9997 3.33301H13.333C12.4125 3.33301 11.6663 4.0792 11.6663 4.99967V6.66634C11.6663 7.58682 12.4125 8.33301 13.333 8.33301ZM4.99967 16.6663H6.66634C7.58682 16.6663 8.33301 15.9201 8.33301 14.9997V13.333C8.33301 12.4125 7.58682 11.6663 6.66634 11.6663H4.99967C4.0792 11.6663 3.33301 12.4125 3.33301 13.333V14.9997C3.33301 15.9201 4.0792 16.6663 4.99967 16.6663Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </span>

                        <span
                            className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-500 ease-in-out ${isCollapsed ? 'opacity-0' : 'opacity-100'
                                }`}
                        >
                            Dashboard
                        </span>
                    </li>
                </ul>
                <hr className="w-full mt-4 border-[#E2E8F0]" />
            </nav>
            <div className="w-full h-[74px] py-4 px-4 flex items-center justify-between border-t border-gray-200 mt-auto">
                <div
                    onClick={handleSidebarClick("profile")}
                    className={`flex gap-3 items-center rounded-full px-2 py-2 cursor-pointer transition-colors duration-500 ease-in-out ${sidebar === "/admin/profile" ? 'bg-sidebar-bg' : 'hover:bg-sidebar-bg'
                        }`}
                >
                    <img
                        src={user?.user?.profile_image ? user.user.profile_image : "/avatar.png"}
                        className='h-[40px] w-[40px] rounded-full'
                    />
                    <div
                        className={`flex flex-col justify-center gap-[2px] min-h-[38px] transition-opacity duration-500 ease-in-out ${isCollapsed ? 'opacity-0' : 'opacity-100'}`}
                    >
                        <p className="text-title-black font-poppins leading-normal text-sm text-left font-semibold">
                            {user.user.first_name}
                        </p>
                    </div>
                </div>
                {!isCollapsed && (
                    <span
                        onClick={handleLogout}
                        className="group p-2 rounded-full cursor-pointer text-sidebar-grey hover:bg-sidebar-bg hover:text-sidebar-black transition-colors duration-300 ease-in-out">
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="group-hover:stroke-black"
                        >
                            <path
                                d="M14.1667 13.333L17.5 9.99967M17.5 9.99967L14.1667 6.66634M17.5 9.99967L5.83333 9.99967M10.8333 13.333V14.1663C10.8333 15.5471 9.71405 16.6663 8.33333 16.6663H5C3.61929 16.6663 2.5 15.5471 2.5 14.1663V5.83301C2.5 4.4523 3.61929 3.33301 5 3.33301H8.33333C9.71405 3.33301 10.8333 4.4523 10.8333 5.83301V6.66634"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>

                )}
            </div>
        </div>
    );
};

export default AdminSidebar;