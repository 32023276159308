import { PROFILE_FAIL, PROFILE_PENDING, PROFILE_SUCCESS } from "../Types"
import { toast } from "react-toastify"
import AuthServices from "../../../Services/Auth/AuthServices";


export const getProfile = () => async (dispatch) => {
    dispatch({
        type: PROFILE_PENDING,
        payload: { getProfile: "isLoading" },
    });

    try {
        const data = await AuthServices.getProfile();

        if (data.data.status === true) {
            dispatch({
                type: PROFILE_SUCCESS,
                payload: { getProfile: data.data },
            });
        } else {
            dispatch({ type: PROFILE_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: PROFILE_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};