import { FORGOT_PASSWORD_FAIL, FORGOT_PASSWORD_PENDING, FORGOT_PASSWORD_SUCCESS, RESET_FORGOT_PASSWORD } from "../Types"
import AuthServices from "../../../Services/Auth/AuthServices"
import { toast } from "react-toastify"

export const forgotPassword = (body) => async (dispatch) => {
    dispatch({
        type: FORGOT_PASSWORD_PENDING,
        payload: { forgotPassword: "isLoading" },
    });

    try {
        const data = await AuthServices.forgotPassword(body);

        if (data.data.status === true) {
            dispatch({
                type: FORGOT_PASSWORD_SUCCESS,
                payload: { forgotPassword: data.data },
            });
        } else {
            dispatch({ type: FORGOT_PASSWORD_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: FORGOT_PASSWORD_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};

export const resetForgotPassword = () => ({
    type: RESET_FORGOT_PASSWORD,
});