
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetSignIn } from '../Redux/Actions/Auth/SignInAction';
import { showModal, endModal } from '../Redux/Reducers/LeaveAvatarSlice';
import { useSelector } from 'react-redux';

const UserDropMenu = () => {
    const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const isDelivering = useSelector((state) => state.lesson.isDelivering);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [sidebar, setSidebar] = useState("");
    const menuRef = useRef(null);
    // set path to sidebar state when path changed
    useEffect(() => {
        setSidebar(location.pathname);
    }, [location])
    const handleLogout = () => {
        localStorage.removeItem("user");
        dispatch(resetSignIn());
        navigate("/");
    }
    // set selected option and navigate to path
    const handleSidebarClick = (path) => () => {
        if (isDelivering === true) {
            // Dispatch the modal with the appropriate path
            dispatch(showModal(!location.pathname.includes("app") ? `app/${path}` : path));
        } else {
            // Navigate to the path based on the current location
            if (!location.pathname.includes("app")) {
                navigate(`app/${path}`);
            } else {
                navigate(path);
            }
        }
        toggleMenu();
    };

    // Close menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsUserMenuVisible(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setIsUserMenuVisible(!isUserMenuVisible);
    }
    return (
        <div ref={menuRef}
            onClick={toggleMenu}
            className="hidden lg:flex min-w-[204px] items-center min-h-[40px] gap-[12px] cursor-pointer">
            <div className="flex flex-col gap-[2px] min-h-[38px]">
                <p className="text-neutral-1 font-poppins leading-normal text-sm text-right font-semibold">
                    {user.user.first_name}
                </p>
                <p className="text-xs text-neutral-1 font-poppins leading-tight font-semibold">
                    {user.user.email}
                </p>
            </div>
            <img
                src={user?.user?.profile_image ? user.user.profile_image : "/avatar.png"}
                className='h-[40px] w-[40px] rounded-full'
            />
            <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.41444 0.535507L1.41443 0.535524L1.41725 0.538277L6.76725 5.76328L7.00126 5.99182L7.23418 5.76216L12.5842 0.487165L12.5842 0.487171L12.5858 0.485507C12.6807 0.390681 12.8196 0.390682 12.9144 0.485507C13.009 0.580053 13.0093 0.718458 12.9153 0.813263C12.915 0.813543 12.9147 0.813823 12.9144 0.814102L7.16652 6.46204L7.16651 6.46203L7.16444 6.4641C7.06813 6.56041 7.02327 6.56647 7.00015 6.56647C6.94122 6.56647 6.89018 6.55203 6.8204 6.4989L1.08502 0.863269C0.991024 0.768465 0.991301 0.630055 1.08585 0.535507C1.18067 0.440682 1.31962 0.440682 1.41444 0.535507Z" fill="white" stroke="white" stroke-width="2" />
            </svg>
            {isUserMenuVisible && (
                <div
                    className="absolute right-[center] top-20 w-[200px] bg-white shadow-sm border rounded-[6px] overflow-hidden transition-all ease-in-out duration-300 z-[100]"
                >
                    <nav className="w-full py-[12px] flex flex-col gap-[24px]">
                        <ul className="flex flex-col gap-2 items-center">
                            <li
                                className={`w-full min-h-[44px] px-[16px] py-[10px] flex items-center gap-3 font-medium cursor-pointer transition-all duration-300 hover:gap-4 hover:text-sidebar-black
${sidebar === "/app/dashboard" ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'}
${isUserMenuVisible ? 'opacity-100' : 'opacity-0'}
`}
                                onClick={handleSidebarClick("dashboard")}
                            >
                                <span className="h-[20px] w-[20px]">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        stroke="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="transition-colors duration-300 ease-in-out"
                                    >
                                        <path
                                            d="M14.1663 11.6663V16.6663M11.6663 14.1663H16.6663M4.99967 8.33301H6.66634C7.58682 8.33301 8.33301 7.58682 8.33301 6.66634V4.99967C8.33301 4.0792 7.58682 3.33301 6.66634 3.33301H4.99967C4.0792 3.33301 3.33301 4.0792 3.33301 4.99967V6.66634C3.33301 7.58682 4.0792 8.33301 4.99967 8.33301ZM13.333 8.33301H14.9997C15.9201 8.33301 16.6663 7.58682 16.6663 6.66634V4.99967C16.6663 4.0792 15.9201 3.33301 14.9997 3.33301H13.333C12.4125 3.33301 11.6663 4.0792 11.6663 4.99967V6.66634C11.6663 7.58682 12.4125 8.33301 13.333 8.33301ZM4.99967 16.6663H6.66634C7.58682 16.6663 8.33301 15.9201 8.33301 14.9997V13.333C8.33301 12.4125 7.58682 11.6663 6.66634 11.6663H4.99967C4.0792 11.6663 3.33301 12.4125 3.33301 13.333V14.9997C3.33301 15.9201 4.0792 16.6663 4.99967 16.6663Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>

                                <span
                                    className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-300 ease-in-out`}
                                >
                                    Dashboard
                                </span>
                            </li>
                            <li
                                className={`w-full min-h-[44px] px-[16px] py-[10px] flex items-center gap-3 font-medium cursor-pointer transition-all duration-300 hover:gap-4 hover:text-sidebar-black
${sidebar.startsWith("/app/courses") ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'}
${isUserMenuVisible ? 'opacity-100' : 'opacity-0'}
`}
                                onClick={handleSidebarClick("courses")}
                            >
                                <span className='h-[20px] w-[20px] text-current'>
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="transition-colors duration-300 ease-in-out"
                                    >
                                        <path
                                            d="M18.2812 16.6562L15.625 2.8125C15.5312 2.3125 15.25 1.84375 14.8125 1.5625C14.375 1.28125 13.875 1.15625 13.3438 1.25L11.1875 1.65625C10.875 1 10.1875 0.53125 9.4375 0.53125H6.53125H3.625C2.53125 0.5625 1.65625 1.4375 1.65625 2.5V17.5C1.65625 18.5625 2.53125 19.4687 3.625 19.4687H6.53125H9.4375C10.5 19.4687 11.4062 18.5937 11.4062 17.5V8.53125L13.125 17.6562C13.2188 18.1562 13.5 18.625 13.9375 18.9062C14.25 19.125 14.6562 19.25 15.0312 19.25C15.1562 19.25 15.2812 19.25 15.4062 19.2187L16.75 18.9687C17.8125 18.75 18.5 17.7187 18.2812 16.6562ZM9.96875 2.5V6.1875H7.21875V1.96875H9.40625C9.71875 1.96875 9.96875 2.1875 9.96875 2.5ZM5.8125 14.1875H3.0625V6.6875H5.8125V14.1875ZM7.21875 7.59375H9.96875V12.125H7.21875V7.59375ZM3.625 1.96875H5.8125V5.3125H3.0625V2.5C3.0625 2.1875 3.3125 1.96875 3.625 1.96875ZM3.0625 17.5V15.5937H5.8125V18.0312H3.625C3.3125 18.0625 3.0625 17.8125 3.0625 17.5ZM9.4375 18.0625H7.25V13.5312H10V17.5C9.96875 17.8125 9.71875 18.0625 9.4375 18.0625ZM16.4688 17.5625L15.1562 17.8125C15 17.8437 14.875 17.8125 14.75 17.7187C14.625 17.625 14.5625 17.5 14.5312 17.375L11.75 3L13.625 2.65625C13.7812 2.625 13.9063 2.65625 14.0313 2.75C14.1563 2.84375 14.2188 2.96875 14.25 3.09375L16.9062 16.9375C16.9688 17.2187 16.7812 17.5 16.4688 17.5625Z"
                                        />
                                    </svg>
                                </span>

                                <span
                                    className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-300 ease-in-out ${!isUserMenuVisible ? 'opacity-0' : 'opacity-100'}`}
                                >
                                    Courses
                                </span>
                            </li>

                            <li
                                className={`w-full min-h-[44px] px-[16px] py-[12px] flex items-center gap-3 font-medium cursor-pointer transition-all duration-300 hover:gap-4 hover:text-sidebar-black
                     } ${sidebar.startsWith("/app/my-learning") ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'
                                    }`}
                                onClick={handleSidebarClick("my-learning")}
                            >
                                <span className="h-[20px] w-[20px]">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="transition-colors duration-300 ease-in-out"
                                    >
                                        <path
                                            d="M19 4.5625L10.25 1.40625C10.0938 1.34375 9.9375 1.34375 9.78125 1.40625L1.03125 4.5625C0.75 4.65625 0.5625 4.9375 0.5625 5.21875C0.5625 5.5 0.75 5.78125 1.03125 5.875L3.3125 6.71875V11.7188C3.3125 12.2812 3.6875 12.7812 4.21875 12.9688C6.59375 13.75 8.5 14.0625 10.2188 14.0625C11.0625 14.0625 11.875 13.9688 12.6875 13.8125V14.4062C11.8438 14.6875 11.2188 15.5 11.2188 16.4688C11.2188 17.6562 12.1875 18.6562 13.4063 18.6562C14.625 18.6562 15.5938 17.6875 15.5938 16.4688C15.5938 15.5313 14.9688 14.7188 14.125 14.4062V13.5C14.6875 13.3438 15.25 13.1563 15.8438 12.9688C16.375 12.7812 16.7188 12.2812 16.7188 11.75V6.71875L19 5.875C19.2812 5.78125 19.4688 5.5 19.4688 5.21875C19.4688 4.9375 19.2812 4.65625 19 4.5625ZM13.375 17.25C12.9375 17.25 12.5938 16.9063 12.5938 16.4688C12.5938 16.0313 12.9375 15.6875 13.375 15.6875C13.8125 15.6875 14.1562 16.0313 14.1562 16.4688C14.1562 16.9063 13.8125 17.25 13.375 17.25ZM10 2.8125L16.6875 5.21875L13.4688 6.375L11.2812 5.5625C10.9062 5.4375 10.5 5.625 10.375 5.96875C10.25 6.34375 10.4375 6.75 10.7812 6.875L11.4375 7.125L10 7.625L3.3125 5.21875L10 2.8125ZM4.71875 11.6562V7.21875L9.78125 9.03125C9.84375 9.0625 9.9375 9.0625 10.0312 9.0625C10.125 9.0625 10.1875 9.0625 10.2813 9.03125L12.7188 8.15625V12.4063C10.375 12.875 8.125 12.75 4.71875 11.6562ZM15.2813 11.6562C14.875 11.8125 14.4688 11.9375 14.0938 12.0312V7.65625L15.2813 7.21875V11.6562Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </span>

                                <span
                                    className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-300 ease-in-out ${isUserMenuVisible ? 'opacity-100' : 'opacity-0'}`}
                                >
                                    My Learning
                                </span>
                            </li>
                            <hr className="w-full border-[#E2E8F0]" />
                            {/* <li
                                className={`w-full min-h-[44px] px-[16px] py-[12px] flex items-center gap-3 font-medium cursor-pointer transition-all duration-300 hover:gap-4 hover:text-sidebar-black
                     } ${sidebar === "/app/settings" ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'
                                    }`}
                                onClick={handleSidebarClick("settings")}
                            >
                                <span className="h-[20px] w-[20px]">
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.60386 3.59776C8.95919 2.13408 11.0408 2.13408 11.3961 3.59776C11.6257 4.54327 12.709 4.99198 13.5398 4.48571C14.8261 3.70199 16.298 5.17392 15.5143 6.46015C15.008 7.29105 15.4567 8.37431 16.4022 8.60386C17.8659 8.95919 17.8659 11.0408 16.4022 11.3961C15.4567 11.6257 15.008 12.709 15.5143 13.5398C16.298 14.8261 14.8261 16.298 13.5398 15.5143C12.709 15.008 11.6257 15.4567 11.3961 16.4022C11.0408 17.8659 8.95919 17.8659 8.60386 16.4022C8.37431 15.4567 7.29105 15.008 6.46016 15.5143C5.17392 16.298 3.70199 14.8261 4.48571 13.5398C4.99198 12.709 4.54327 11.6257 3.59776 11.3961C2.13408 11.0408 2.13408 8.95919 3.59776 8.60386C4.54327 8.37431 4.99198 7.29105 4.48571 6.46015C3.70199 5.17392 5.17392 3.70199 6.46015 4.48571C7.29105 4.99198 8.37431 4.54327 8.60386 3.59776Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M12.5 10C12.5 11.3807 11.3807 12.5 10 12.5C8.61929 12.5 7.5 11.3807 7.5 10C7.5 8.61929 8.61929 7.5 10 7.5C11.3807 7.5 12.5 8.61929 12.5 10Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>

                                <span
                                    className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-300 ease-in-out ${isUserMenuVisible ? 'opacity-100' : 'opacity-0'}`}
                                >
                                    Settings
                                </span>
                            </li>

                            <li
                                className={`w-full min-h-[44px] px-[16px] py-[12px] flex items-center gap-3 font-medium cursor-pointer transition-all duration-500 hover:gap-4 hover:text-sidebar-black
                     } ${sidebar === "/app/support" ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'
                                    }`}
                                onClick={handleSidebarClick("support")}
                            >
                                <span className="h-[20px] w-[20px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                                <span
                                    className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-300 ease-in-out ${isUserMenuVisible ? 'opacity-100' : 'opacity-0'}`}
                                >
                                    Support
                                </span>
                            </li> */}
                            {/* <hr className="w-full border-[#E2E8F0]" /> */}
                            <li
                                className={`w-full min-h-[44px] px-[16px] py-[12px] flex items-center gap-3 font-medium cursor-pointer transition-all duration-300 hover:gap-4 hover:text-sidebar-black
                     } ${sidebar === "/app/settings" ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'
                                    }`}
                                onClick={handleSidebarClick("profile")}
                            >
                                <span className="h-[20px] w-[20px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 11.5C12.5938 11.5 14.6875 9.40625 14.6875 6.8125C14.6875 4.21875 12.5938 2.125 10 2.125C7.40625 2.125 5.3125 4.21875 5.3125 6.8125C5.3125 9.40625 7.40625 11.5 10 11.5ZM10 3.53125C11.8125 3.53125 13.2812 5 13.2812 6.8125C13.2812 8.625 11.8125 10.0938 10 10.0938C8.1875 10.0938 6.71875 8.625 6.71875 6.8125C6.71875 5 8.1875 3.53125 10 3.53125Z" fill="currentColor" />
                                        <path d="M19.2191 16.625C16.6566 14.4688 13.4066 13.2812 10.0004 13.2812C6.59412 13.2812 3.34412 14.4688 0.781616 16.625C0.500366 16.875 0.437866 17.3125 0.687866 17.625C0.937866 17.9063 1.37537 17.9688 1.68787 17.7188C4.00037 15.75 6.93787 14.6875 10.0004 14.6875C13.0629 14.6875 16.0004 15.75 18.2816 17.6875C18.4066 17.8125 18.5629 17.8438 18.7504 17.8438C18.9379 17.8438 19.1566 17.75 19.2816 17.5938C19.5316 17.3125 19.5004 16.875 19.2191 16.625Z" fill="currentColor" />
                                    </svg>

                                </span>

                                <span
                                    className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-300 ease-in-out ${isUserMenuVisible ? 'opacity-100' : 'opacity-0'}`}
                                >
                                    Profile
                                </span>
                            </li>

                            <li
                                className={`w-full min-h-[44px] px-[16px] py-[12px] flex items-center gap-3 font-medium cursor-pointer transition-all duration-500 hover:gap-4 hover:text-sidebar-black
                     } ${sidebar === "/app/support" ? 'bg-sidebar-bg text-sidebar-blue hover:text-sidebar-blue' : 'text-sidebar-grey hover:bg-sidebar-bg'
                                    }`}
                                onClick={handleLogout}
                            >
                                <span className="h-[20px] w-[20px]">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.1667 13.333L17.5 9.99967M17.5 9.99967L14.1667 6.66634M17.5 9.99967L5.83333 9.99967M10.8333 13.333V14.1663C10.8333 15.5471 9.71405 16.6663 8.33333 16.6663H5C3.61929 16.6663 2.5 15.5471 2.5 14.1663V5.83301C2.5 4.4523 3.61929 3.33301 5 3.33301H8.33333C9.71405 3.33301 10.8333 4.4523 10.8333 5.83301V6.66634"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                                <span
                                    className={`font-open_sans text-sm font-semibold leading-normal transition-all duration-300 ease-in-out ${isUserMenuVisible ? 'opacity-100' : 'opacity-0'}`}
                                >
                                    Sign out
                                </span>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}

        </div>
    )
}
export default UserDropMenu;