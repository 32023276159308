import { SIDEBAR_SELECTION_SUCCESS } from "../Actions/Types";
const initialState = {
    sidebarSelection: 0,

};
const sidebar_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SIDEBAR_SELECTION_SUCCESS:
            return {
                ...state,
                sidebarSelection: payload.sidebarSelection,
            };

        default:
            return state;
    }
};
export default sidebar_reducer;