import React, { useState, useRef, useEffect } from 'react';

const CourseAccordion = ({ outlineData, number }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [maxHeight, setMaxHeight] = useState('0px');
    const contentRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setMaxHeight(`${contentRef.current.scrollHeight}px`);
        } else {
            setMaxHeight('0px');
        }
    }, [isOpen]);

    return (
        <div className="w-full border-b border-neutral-5 overflow-hidden">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex  items-center justify-between w-full button3 bg-white text-font_color-primary lg:button1 lg:py-[16px] lg:px-[24px]"
            >
                <div className='flex gap-[12px] w-[90%] items-center'>
                    <p className='Heading-16 text-neutral-6 lg:Heading-24'>{number}</p>
                    <p className='subtitle2 text-left lg:subtitle1 truncate whitespace-nowrap overflow-hidden flex-grow'>
                        {outlineData.name}
                    </p>
                </div>

                <span className="flex-shrink-0 flex items-center justify-center border border-neutral-4 rounded-[40px] w-[16px] h-[16px] lg:p-[4px] lg:w-[24px] lg:h-[24px]">
                    <svg
                        className={`transform transition-transform duration-300 ${isOpen ? 'rotate-90' : 'rotate-0'}`}
                        width="100%"
                        height="100%"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.125 6.3376C9.7875 6.0001 9.2625 6.0001 8.925 6.3376C8.5875 6.6751 8.5875 7.2001 8.925 7.5376L13.3125 12.0001L8.925 16.4626C8.5875 16.8001 8.5875 17.3251 8.925 17.6626C9.075 17.8126 9.3 17.8876 9.525 17.8876C9.75 17.8876 9.975 17.8126 10.125 17.6251L15.1125 12.5626C15.45 12.2251 15.45 11.7001 15.1125 11.3626L10.125 6.3376Z"
                            fill="#434343"
                        />
                    </svg>
                </span>
            </button>
            <div
                ref={contentRef}
                className="transition-[max-height,opacity] duration-300 ease-out overflow-hidden"
                style={{ maxHeight, opacity: isOpen ? 1 : 0 }}
            >
                <div className="px-6 pb-6 bg-white text-font_color-primary text-left">
                    <ul className="space-y-[8px] list-disc list-outside">
                        <li className="ml-4">
                            <h3 className='subtitle2 lg:subtitle1'>Goal: <span className='body-2 text-font_color-body lg:body-1'>
                                {outlineData.lesson_details.goal}
                            </span> </h3>

                        </li>
                        <li className="ml-4">
                            <h3 className='subtitle2 lg:subtitle1'>Materials: <span className='body-2 text-font_color-body lg:body-1'>
                                {outlineData.lesson_details.materials.join(", ")}
                            </span></h3>

                        </li>
                        <li className="ml-4">
                            <h3 className='subtitle2 lg:subtitle1'>Activities: </h3>
                            {outlineData.lesson_details.activities.map((activity, index) => (
                                <li key={index} className='ml-4 body-2 text-font_color-body lg:body-1'>
                                    {activity}
                                </li>
                            ))}
                        </li>
                        <li className="ml-4">
                            <h3 className='subtitle2 lg:subtitle1'>Assesment: <span className='body-2 text-font_color-body lg:body-1'>
                                {outlineData.lesson_details.assessment}
                            </span></h3>

                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CourseAccordion;