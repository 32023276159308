import React, { useState } from "react";
// import { Select, MenuItem } from "@mui/material";
import EmailNotVerified from "./EmailNotVerified";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from '../../Redux/Actions/Auth/GetProfileAction';
import { sendVerificationEmail } from '../../Redux/Actions/Auth/SendVerificationEmailAction';
import { updateProfile } from '../../Redux/Actions/Auth/UpdateProfileAction';
import { TailSpin } from 'react-loader-spinner';
import VerificationResend from "../../UIComponents/VerificationResend";
import ChangePasswordModal from "./ChangePasswordModal";
import { toast } from "react-toastify";
import InputField from "../../UIComponents/InputField";


export const Profile = () => {
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem("user"))
    const [showEmailNotVerified, setShowEmailNotVerified] = useState(false);
    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({});
    // state for setting new image 
    const [newImage, setNewImage] = useState(null);
    const [showSuccessVerificationResend, setShowSuccessVerificationResend] = useState(false);
    const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [formValues, setFormValues] = useState({
        first_name: "",
        last_name: "",
        email: "",
        // institute: "",
        // degree: "",
        // currently_studying: true
    })
    const profile = useSelector((state) => state.profile_reducer.getProfile);
    const verificationEmailStatus = useSelector((state) => state.send_verification_email_reducer.sendVerificationEmail);
    const updateProfileStatus = useSelector((state) => state.update_profile_reducer.updateProfile);
    // dispatch action to get user data
    useEffect(() => {
        dispatch(getProfile());
    }, [])
    // set form values from user data 
    useEffect(() => {
        if (profile && profile !== "isLoading" && profile.length !== 0 && profile.result && profile.result.length !== 0) {
            const userProfile = profile.result;
            setFormValues({
                first_name: userProfile.first_name || "",
                last_name: userProfile.last_name || "",
                email: userProfile.email || "",
                // institute: userProfile.institute || "",
                // degree: userProfile.degree || "",
                // currently_studying: userProfile.currently_studying || false
            });
            if (userProfile.profile_image) {
                setImage(userProfile.profile_image);
            }
            if (profile.result.is_verified === false) {
                setShowEmailNotVerified(true);
            }
        }
    }, [profile]);
    // show verification link send successfully and hide email not verified modal
    useEffect(() => {
        if (verificationEmailStatus && verificationEmailStatus !== "isLoading" && verificationEmailStatus.length !== 0 && verificationEmailStatus.status === true) {
            setShowEmailNotVerified(false);
            setShowSuccessVerificationResend(true);
        }
    }, [verificationEmailStatus]);
    useEffect(() => {
        if (updateProfileStatus && updateProfileStatus !== "isLoading" && updateProfileStatus.length !== 0 && updateProfileStatus.status === true) {
            dispatch(getProfile());
        }
    }, [updateProfileStatus]);
    // handle function for input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
        setDisabled(false);
        setErrors({});
    };
    // handle function to dispatch action to send verification link 
    const handleSendVerificationEmail = () => {
        const body = {
            "email": user.user.email
        }
        dispatch(sendVerificationEmail(body));
    }
    const handleSendVerificationEmailClose = () => {
        setShowEmailNotVerified(false);
    }
    // show change password modal
    const handleChangePassword = () => {
        setIsChangePasswordVisible(true);
    }
    const handleClose = () => {
        setShowSuccessVerificationResend(false);
    }

    // function to validate form values
    const validateForm = () => {
        const newErrors = {};
        const nameRegex = /^[a-zA-Z\s]+$/;
        if (!formValues.first_name.trim()) {
            newErrors.first_name = "First Name is required.";
        } else if (!nameRegex.test(formValues.first_name)) {
            newErrors.first_name = "First Name cannot contain special characters.";
        } else if (formValues.first_name.length > 15) {
            newErrors.first_name = "First Name cannot exceed 15 characters.";
        }
        if (!formValues.last_name.trim()) {
            newErrors.last_name = "Last Name is required.";
        } else if (!nameRegex.test(formValues.last_name)) {
            newErrors.last_name = "Last Name cannot contain special characters.";
        } else if (formValues.last_name.length > 15) {
            newErrors.last_name = "Last Name cannot exceed 15 characters.";
        }

        return newErrors;
    };
    //function to choose new image from user device
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && file.size <= 10 * 1024 * 1024) {
            const fileType = file.type.split("/")[1];
            if (["png", "jpg", "jpeg"].includes(fileType)) {
                setImage(file);
                setNewImage(file);
                setDisabled(false);
            } else {
                toast.error("Only PNG, JPG, and JPEG file types are allowed.");
            }
        } else {
            toast.error("Maximum file size allowed is 10MB.");
        }
    };
    // function to dispatch update profile action after validation
    const handleSaveChanges = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const body = new FormData();
            body.append('first_name', formValues.first_name.trim());
            body.append('last_name', formValues.last_name.trim());
            if (newImage) {
                body.append('image', newImage);
            }
            dispatch(updateProfile(body));
        } else {
            setErrors(formErrors);
        }
    };


    return (
        <div className="flex flex-col gap-[40px] text-font_color-primary mx-[10px]">
            {profile && profile === "isLoading" && (
                <div className='flex items-center justify-center h-full'>
                    <TailSpin color="#34D795" height={50} width={50} />
                </div>
            )}
            {profile && profile !== "isLoading" && profile.length !== 0 && profile.result && profile.result.length !== 0 && (
                <>
                    <div className="flex flex-col gap-[24px]">
                        {!showEmailNotVerified && showSuccessVerificationResend && (
                            <VerificationResend text="A verification link has been sent to your email, please verify."
                                onClose={handleClose}
                                resendEmail={handleSendVerificationEmail} />
                        )}
                        {showEmailNotVerified === true && (
                            <EmailNotVerified
                                onSendVerificationClick={handleSendVerificationEmail}
                                onCloseClick={handleSendVerificationEmailClose}
                            />
                        )}
                        <p className="text-left Heading-18 lg:Heading-24">Personal Details</p>
                        <div className="flex flex-col px-[16px] border-[1.6px] border-neutral-3 rounded-[4px] py-[24px] gap-[24px] lg:flex-row lg:gap-[64px] lg:px-[32px]">
                            <div className="flex flex-col items-center gap-[18px]">
                                <div className="relative flex flex-col items-center">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        id="fileInput"
                                        className="absolute inset-0 opacity-0 cursor-pointer"
                                        onChange={handleImageChange}
                                    />
                                    <img
                                        src={image
                                            ? typeof image === "string"
                                                ? image
                                                : URL.createObjectURL(image)
                                            : "/avatar.png"}
                                        alt="Profile"
                                        className="w-[140px] h-[140px] rounded-full object-cover transition-transform duration-300"
                                    />
                                    <button
                                        onClick={() => document.getElementById("fileInput").click()}
                                        className="absolute flex items-center justify-center bottom-[-5px] right-10 bg-primary-1 w-[32px] h-[32px] rounded-full"
                                    >
                                        <img className="w-[18px] h-[18px]" src="/edit.svg" alt="Edit" />
                                    </button>
                                </div>
                                <p className="Heading-18 h-[49px] lg:Heading-20">{`${profile.result.first_name} ${profile.result.last_name}`}</p>
                            </div>

                            <div className="grid gap-[16px] lg:gap-[24px] lg:max-w-[944px] lg:grid-cols-2">
                                {/* First Name */}
                                <InputField
                                    label="First Name"
                                    name="first_name"
                                    value={formValues.first_name}
                                    onChange={handleChange}
                                    placeholder="Enter your first name"
                                    errorMessage={errors.first_name}
                                />

                                {/* Last Name */}
                                <InputField
                                    label="Last Name"
                                    name="last_name"
                                    value={formValues.last_name}
                                    onChange={handleChange}
                                    placeholder="Enter your last name"
                                    errorMessage={errors.last_name}
                                />
                                {/* Email */}
                                <div className="w-full flex flex-col gap-[10px] font-open_sans lg:col-span-2 lg:gap-[5px]">
                                    <label className="subtitle2 lg:subtitle1">
                                        Email
                                    </label>
                                    <div
                                        className="w-full h-[38px] bg-white border border-[#DFE4EA] rounded-[6px] flex items-center gap-[10px]"
                                    >
                                        <input
                                            type="email"
                                            name="email"
                                            value={formValues.email}
                                            disabled
                                            placeholder="Enter your email"
                                            className="w-full h-full bg-transparent p-[8px_16px] border-none outline-none text-neutral-7"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="flex justify-end">
                        <button
                            onClick={handleSaveChanges}
                            disabled={disabled}
                            className={`button3 px-[20px] py-[10px] gap-[10px] min-w-[122px] items-center justify-center flex rounded-[6px] lg:button1 lg:px-[28px] lg:py-[12px] lg:min-w-[165px] ${disabled ? "bg-neutral-5 cursor-not-allowed" : "bg-primary-1 hover:bg-primary-4"
                                } text-neutral-1`}
                        >
                            {updateProfileStatus === "isLoading" ? (
                                <TailSpin color="#FFFFFF" height={20} width={20} />
                            ) : (
                                "Save Changes"
                            )}
                        </button>
                    </div>
                    <div className="flex flex-col gap-[24px]">
                        <p className="text-left Heading-18 lg:Heading-24">Security</p>
                        <div className="flex flex-col p-[16px] border-[1.6px] border-neutral-3 rounded-[4px] lg:py-[24px] gap-[24px] lg:flex-row lg:justify-between lg:px-[32px] lg:items-center">
                            <p className="text-font_color-body body-2 text-left lg:body-1">Keep your account secure by regularly updating your password.</p>
                            <button
                                onClick={handleChangePassword}
                                className=" border-2 button3 border-primary-1 w-fit px-[16px] py-[10px] text-primary-1 rounded-[6px] transition-colors duration-300 hover:text-primary-5 hover:border-primary-5 lg:button1 lg:px-[16px]">
                                Change Password
                            </button>
                        </div>
                    </div>
                    {/* <div className="flex flex-col gap-[24px]">
                        <p className="text-left Heading-18 lg:Heading-24">Highest Qualification</p>
                        <div className="flex flex-col p-[16px] border-[1.6px] border-neutral-3 rounded-[4px] lg:py-[24px] gap-[24px] lg:flex-row lg:px-[32px] lg:items-end">
                            <div className="w-full flex flex-col lg:flex-1 gap-[10px]">
                                <label className="subtitle2 lg:subtitle1">
                                    Institute Name
                                </label>
                                <Select
                                    labelId="my-select-label"
                                    value={selectedCourse ? selectedCourse._id : ""}
                                    onChange={handleSelectChange}
                                    className="w-full rounded-[6px] h-[40px]"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 100,
                                            },
                                        },
                                    }}
                                >
                                    {courseProgressData.map((course) => (
                            <MenuItem
                                className="body-2"
                                key={course._id}
                                value={course._id}
                            >
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%',
                                    }}>{course.title}</span>
                            </MenuItem>
                        ))}
                                </Select>
                            </div>
                            <div className="w-full flex flex-col lg:flex-1 gap-[10px]">
                                <label className="subtitle2 lg:subtitle1">
                                    Degree
                                </label>
                                <Select
                                    labelId="my-select-label"
                                    value={selectedCourse ? selectedCourse._id : ""}
                                    onChange={handleSelectChange}
                                    className="w-full rounded-[6px] h-[40px]"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 100,
                                            },
                                        },
                                    }}
                                >
                                    {courseProgressData.map((course) => (
                            <MenuItem
                                className="body-2"
                                key={course._id}
                                value={course._id}
                            >
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%',
                                    }}>{course.title}</span>
                            </MenuItem>
                        ))}
                                </Select>
                            </div>
                            <div className="w-full flex flex-col lg:flex-1 gap-[10px]">
                                <label className="subtitle2 lg:subtitle1">
                                    Graduation Date
                                </label>
                                <Select
                                    labelId="my-select-label"
                                    value={selectedCourse ? selectedCourse._id : ""}
                                    onChange={handleSelectChange}
                                    className="w-full rounded-[6px] h-[40px]"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 100,
                                            },
                                        },
                                    }}
                                >
                                    {courseProgressData.map((course) => (
                            <MenuItem
                                className="body-2"
                                key={course._id}
                                value={course._id}
                            >
                                <span
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%',
                                    }}>{course.title}</span>
                            </MenuItem>
                        ))}
                                </Select>
                            </div>

                            <div className="w-full flex lg:flex-1 gap-[10px] py-[10px]">
                                <div class="inline-flex items-center">
                                    <label class="flex items-center cursor-pointer relative">
                                        <input type="checkbox" checked={true} class="peer h-[20px] w-[20px] cursor-pointer transition-all appearance-none rounded hover:shadow-md border border-neutral-6 checked:bg-primary-1 checked:border-primary-1" id="check4" />
                                        <span class="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" stroke-width="1">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                            </svg>
                                        </span>
                                    </label>
                                </div>
                                <p className="text-font_color-body body-2 text-left lg:body-1">I currently study here</p>
                            </div>
                        </div>
                    </div> */}

                    <ChangePasswordModal
                        isVisible={isChangePasswordVisible}
                        onClose={() => setIsChangePasswordVisible(false)}
                    />
                </>
            )}

        </div >
    )
}