import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import { SessionCard } from "./SessionCard";
import CourseAccordion from "../../UIComponents/CourseAccordian";
import { getTeachers } from "../../Redux/Actions/CommonActions";
import { createSession, getUserCourseDetail, resetCreateSession } from "../../Redux/Actions/SessionActions"
import ChooseTeacherModal from "../../UIComponents/ChooseTeacherModal";
import SuccessModal from "../../UIComponents/SuccessModal";
import CourseOverviewModal from "../../UIComponents/CourseOverviewModal";



export const EnrolledCourse = () => {
    const DOCUMENT_TITLE_DELAY = 200;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { courseId } = useParams();
    const user = JSON.parse(localStorage.getItem("user"));
    const userCourseDetail = useSelector((state) => state.user_course_detail_reducer.getUserCourseDetail);
    let outlineCount = 0;
    const allTeachers = useSelector((state) => state.teachers_reducer.getTeachers);
    const [isChooseTeacherModalVisible, setIsChooseTeacherModalVisible] = useState(false);
    const [isMorphing, setIsMorphing] = useState(false);
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
    const [isCourseOverviewVisible, setIsCourseOverviewVisible] = useState(false);
    const createSessionStatus = useSelector((state) => state.create_session_reducer.createSession);
    const [watchIntroTeacherData, setWatchIntroTeacherData] = useState(null);

    // dispatch user course detail action and get teacher action
    useEffect(() => {
        const body = {
            "user_id": user.user._id,
            "course_id": courseId
        }
        dispatch(getUserCourseDetail(body));
        dispatch(getTeachers());

    }, []);

    const handleStartNewSession = () => {
        setIsChooseTeacherModalVisible(true);
    };
    // dispatch action to create new session
    const handleConfirm = (body) => {
        dispatch(createSession(body));
    };
    const closeSuccessModal = () => {
        setIsSuccessModalVisible(false);
    };
    useEffect(() => {
        if (createSessionStatus && createSessionStatus.length !== 0 && createSessionStatus !== "isLoading" && createSessionStatus.status === true) {
            setIsChooseTeacherModalVisible(false);
            setIsSuccessModalVisible(true);
        }
    }, [createSessionStatus]);
    // fuction to navigate user to my lesson after creating new session with new teacher
    const handleStartLearning = () => {
        if (createSessionStatus && createSessionStatus.length !== 0 && createSessionStatus !== "isLoading" && createSessionStatus.status === true && createSessionStatus.result && createSessionStatus.result.length !== 0) {
            const sessionId = createSessionStatus.result.session_id;
            const teacherId = createSessionStatus.result.teacher_id;
            const lessonId = createSessionStatus.result.lesson_id;
            dispatch(resetCreateSession());
            navigate(`/app/my-learning/enrolled-course/my-lesson/${sessionId}/${teacherId}/${lessonId}`);
        }
    }
    //function to show watch intro modal
    const handleWatchIntro = (teacher) => {
        setIsChooseTeacherModalVisible(false);
        setWatchIntroTeacherData(teacher);
        setIsCourseOverviewVisible(true);
    };
    useEffect(() => {
        const updateTitle = () => {
            document.title = 'Knnect AI';
        };
        const timeoutId = setTimeout(updateTitle, DOCUMENT_TITLE_DELAY);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [isCourseOverviewVisible]);
    return (
        <>
            {userCourseDetail && userCourseDetail === "isLoading" && (
                <div className='flex items-center justify-center h-full'>
                    <TailSpin color="#34D795" height={50} width={50} />
                </div>
            )}
            {userCourseDetail && userCourseDetail !== "isLoading" && userCourseDetail.length !== 0 && userCourseDetail.result && userCourseDetail.result.length !== 0 && (
                <div className="w-full flex flex-col gap-[24px] text-font_color-primary px-[10px]">
                    <div className="flex flex-col gap-[12px] justify-between lg:flex-row">
                        <h3 className="flex-1 Heading-20 text-font_color-primary lg:Heading-32 lg:max-w-[1032px]">{userCourseDetail.result.title}</h3>
                        {/* <p className="body-1 text-font_color-body">{userCourseDetail.result.description}</p> */}
                        <button
                            onClick={handleStartNewSession}
                            className="button3 px-[20px] py-[10px] gap-[10px] bg-primary-1 text-neutral-1 rounded-[6px] hover:bg-primary-4 w-fit h-fit lg:button1 lg:px-[28px] lg:py-[12px]"
                        >
                            Start New Session
                        </button>
                    </div>
                    <div className="flex flex-col gap-[16px]">
                        <h4 className="Heading-18 lg:Heading-20 ">
                            Ongoing Sessions
                        </h4>
                        <div className="flex flex-wrap gap-[12px] lg:gap-[24px] ">
                            {userCourseDetail.result.sessions.map((session) => (
                                <SessionCard
                                    key={session.index}
                                    sessionData={session}
                                />
                            ))}

                        </div>

                    </div>

                    <div className="flex flex-col gap-[16px]">
                        <h4 className="Heading-18 text-font_color-primary lg:Heading-20">
                            Course Content
                        </h4>
                        <div className="flex flex-col ">
                            {userCourseDetail.result.outline.map((outline) => {
                                outlineCount += 1;
                                const formattedCount = outlineCount < 10 ? `0${outlineCount}` : outlineCount;
                                return (
                                    <CourseAccordion
                                        key={outline.index}
                                        number={formattedCount}
                                        outlineData={outline}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    {allTeachers && allTeachers.length !== 0 && allTeachers !== "isLoading" && allTeachers.result && allTeachers.result.length !== 0 &&
                        <ChooseTeacherModal
                            isVisible={isChooseTeacherModalVisible}
                            onClose={() => setIsChooseTeacherModalVisible(false)}
                            teachersData={allTeachers.result}
                            courseId={courseId}
                            isMorphing={isMorphing}
                            onConfirmClick={handleConfirm}
                            onWatchIntroClick={handleWatchIntro}
                        />}
                    <SuccessModal
                        isVisible={isSuccessModalVisible}
                        onClose={closeSuccessModal}
                        isMorphing={isMorphing}
                        onStartLearningClick={handleStartLearning}
                    />
                    {isCourseOverviewVisible && (
                        <CourseOverviewModal
                            isVisible={isCourseOverviewVisible}
                            onClose={() => setIsCourseOverviewVisible(false)}
                            courseTitle={userCourseDetail.result.title}
                            teacherData={watchIntroTeacherData}
                        />
                    )}

                </div>
            )}

        </>
    )
}