import { EVALUATE_ASSESSMENT_SUCCESS, EVALUATE_ASSESSMENT_FAIL, EVALUATE_ASSESSMENT_PENDING } from "../Actions/Types";

const initialState = {
    evaluateAssessment: [],
};
const evaluate_assessment_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case EVALUATE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                evaluateAssessment: payload.evaluateAssessment,
            };
        case EVALUATE_ASSESSMENT_PENDING:
            return {
                ...state,
                evaluateAssessment: payload.evaluateAssessment,
            };
        case EVALUATE_ASSESSMENT_FAIL:
            return {
                ...state,
                evaluateAssessment: [],

            };
        default:
            return state;
    }
};
export default evaluate_assessment_reducer;