import { DASHBOARD_DATA_PENDING, DASHBOARD_DATA_SUCCESS, DASHBOARD_DATA_FAIL } from "./Types"
import { toast } from "react-toastify"
import studentDashboardServices from "../../Services/StudentDashboardServices";

export const getDashboardData = (body) => async (dispatch) => {
    dispatch({
        type: DASHBOARD_DATA_PENDING,
        payload: { getDashboardData: "isLoading" },
    });

    try {
        const data = await studentDashboardServices.getDashboardData(body);

        if (data.data.status === true) {
            dispatch({
                type: DASHBOARD_DATA_SUCCESS,
                payload: { getDashboardData: data.data },
            });
        } else {
            dispatch({ type: DASHBOARD_DATA_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error: ", error);
        toast.error(error?.response?.data?.message);
        dispatch({ type: DASHBOARD_DATA_FAIL });
    }
};