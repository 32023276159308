import './App.css';
import { Routes, Route } from "react-router-dom";
import { LandingPage } from './Components/Main/LandingPage/LandingPage';
import { SignUp } from './Components/AuthComponents/SignUp';
import { SignIn } from './Components/AuthComponents/SignIn';
import { EmailVerification } from './Components/AuthComponents/EmailVerification';
import { StudentMain } from './Components/Main/Student/StudentMain';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import StudentProtected from './Routes/StudentProtected';
import { StudentDashboard } from './Components/Dashboard/Student/StudentDashboard';
import { Courses } from './Components/Courses/Courses';
import { CourseDetail } from './Components/Courses/CourseDetail';
import { Profile } from './Components/Profile/Profile';
import { MyLearning } from './Components/MyLearning/MyLearning';
import { ForgotPassword } from './Components/AuthComponents/ForgotPassword';
import { UpdatePassword } from './Components/AuthComponents/UpdatePassword';
import { EnrolledCourse } from './Components/MyLearning/EnrolledCourse';
import { MyLesson } from './Components/MyLearning/MyLesson';
import { CourseDetailPage } from './Components/Courses/CourseDetailPage';
import { AdminMain } from './Components/Main/Admin/AdminMain';
import AdminProtected from './Routes/AdminProtected';
import { AdminDashboard } from './Components/Dashboard/Admin/AdminDashboard';
import { StudentDetail } from './Components/Dashboard/Admin/StudentDetail';
import { AccountLocked } from './Components/AuthComponents/AccountLocked';
import TimerManager from './TimerManager';
import ChatComponent from './Components/AuthComponents/ChatComponent';




function App() {

  return (
    <div className="App">
      <TimerManager />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/chat" element={<ChatComponent />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/update-password/:token" element={<UpdatePassword />} />
        <Route path="/verify-email/:token" element={<EmailVerification />} />
        <Route path="/course-detail/:courseId" element={<CourseDetailPage />} />
        <Route path="/account-locked/" element={<AccountLocked />} />

        <Route element={<StudentProtected />}>
          <Route path="/app" element={<StudentMain />}>
            <Route path="dashboard" element={<StudentDashboard />} />
            <Route path="courses" element={<Courses />} />
            <Route path="courses/course-detail/:courseId" element={<CourseDetail />} />
            <Route path="my-learning" element={<MyLearning />} />
            <Route path="my-learning/enrolled-course/:courseId" element={<EnrolledCourse />} />
            <Route path="/app/my-learning/enrolled-course/my-lesson/:session_id/:teacher_id/:lesson_id" element={<MyLesson />} />
            <Route path="profile" element={<Profile />} />
          </Route>
        </Route>

        <Route element={<AdminProtected />}>
          <Route path="/admin" element={<AdminMain />}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="dashboard/student-detail/:studentId" element={<StudentDetail />} />
            <Route path="profile" element={<Profile />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div >
  );
}

export default App;
