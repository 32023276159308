import React from 'react';
import "./button.css"

const TeacherCardSelect = ({ teacher, isChecked, onSelect, onWatchIntro }) => {

    return (
        <div
            onClick={onSelect}
            className="w-[250px] h-[338px] inline-block  text-primary overflow-hidden mx-[12px]">
            <div className="relative w-[250px] h-[300px]">
                <img
                    className="w-[250px] h-[300px] object-cover rounded-[16px]"
                    src={teacher.image_url}
                    alt="Teacher"
                />
                <div className="absolute inset-0 bg-gradient-to-b rounded-[16px] from-transparent to-[#333333]"></div>
                <button
                    onClick={onSelect}
                    className={`absolute w-[20px] h-[20px] top-[16px] left-[214px] rounded-full flex items-center justify-center ${isChecked ? 'bg-blue-500 text-white' : 'bg-transparent border border-white'}`}
                >
                    {isChecked && <span className="text-[10px]">&#10003;</span>}
                </button>
                <button
                    onClick={onWatchIntro}
                    className="animated-button absolute flex items-center left-[60px] bottom-5 rounded-[6px] py-[10px] px-[20px] gap-[10px] bg-neutral-1 button3 text-primary-1">
                    <span className="icon"><svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.6665 2.66602V13.3327L13.3332 7.99935L4.6665 2.66602Z"
                            stroke="#34D795"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg></span>
                    <span className="title">Watch Intro</span>
                </button>

            </div>
            <p className="Heading-18 text-center items-center mt-2">{teacher.name}</p>
        </div>
    );
}

export default TeacherCardSelect;