import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "../../../Redux/Actions/CourseActions";
import CourseEnrolementCard from "../../../UIComponents/CourseEnrolementCard";

const CourseList = ({ setRefs }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"))
  const allCourses = useSelector((state) => state.courses_reducer.getCourses);

  const handleExploreCourseClick = (courseId) => {
    if (user) {
      navigate(`/app/courses/course-detail/${courseId}`);
    } else {
      navigate(`/course-detail/${courseId}`);
    }
  };

  useEffect(() => {
    dispatch(getCourses());
  }, []);
  return (
    <div className="w-full flex flex-col text-font_color-primary gap-[40px] p-[20px] lg:p-[80px]">
      <h3 className="Heading-24 lg:Heading-40">Courses</h3>
      <div
        ref={setRefs(4)}
        data-animation-type="right"
        className="flex gap-[16px] p-[10px] overflow-x-auto opacity-0"
        style={{
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
      >
        {allCourses &&
          allCourses !== "isLoading" &&
          allCourses.length !== 0 &&
          allCourses.result &&
          allCourses.result.length > 0 &&
          allCourses.result.map((course) => (
            <CourseEnrolementCard
              key={course._id}
              course={course}
              onExploreCourseClick={handleExploreCourseClick}
            />
          ))}
      </div>
    </div>
  );
};

export default CourseList;
