import React from "react";


const BeginLessonModal = ({ message, beginLessonAction }) => {


    return (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-40 rounded-3xl">
          <div className="text-center">
            <p className="text-white text-2xl mb-4 font-bold">
              {message}
            </p>
            <button
              className="bg-[#34D795] w-[140px] h-[48px] rounded-[6px] text-white font-bold px-4"
              onClick={beginLessonAction}
            >
              Begin Lesson
            </button>
          </div>
        </div>
    );
}

export default BeginLessonModal;