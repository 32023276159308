import { BASE_URL } from '../../Constants';
import axiosInstance from '../AxiosInstance';
// Base URL for your API
const API_URL = BASE_URL;
const SIGNIN_WITH_GOOGLE = "login-with-google/";
const REFRESH_TOKEN = "refresh-token/";
const LOGIN = "login/";
const SIGNUP = "signup/";
const CHANGE_PASSWORD = "change-password/";
const FORGOT_PASSWORD_VERIFICATION_EMAIL = "send-forgot-password-verification-email/";
const UPDATE_PASSWORD = "update-password/";
const GET_PROFILE = "get-profile/";
const SEND_VERIFICATION_EMAIL = "send-verification-email/";
const UPDATE_PROFILE = "update-profile/";



class AuthServices {
    async signUp(body) {
        return axiosInstance.post(API_URL + SIGNUP, body);
    }

    async signIn(body) {
        return axiosInstance.post(API_URL + LOGIN, body);
    }

    async signInWithGoogle(body) {
        return axiosInstance.post(API_URL + SIGNIN_WITH_GOOGLE, body);
    }

    async refreshToken(body) {
        return axiosInstance.post(API_URL + REFRESH_TOKEN, body);
    }

    async changePassword(body) {
        return axiosInstance.post(API_URL + CHANGE_PASSWORD, body);
    }
    async sendVerificationEmail(body) {
        return axiosInstance.post(API_URL + SEND_VERIFICATION_EMAIL, body
        );
    }
    async updateProfile(body) {
        return axiosInstance.post(API_URL + UPDATE_PROFILE, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
    async forgotPassword(body) {
        return axiosInstance.post(API_URL + FORGOT_PASSWORD_VERIFICATION_EMAIL, body);
    }

    async updatePassword(body) {
        return axiosInstance.post(API_URL + UPDATE_PASSWORD, body);
    }
    async getProfile() {
        return axiosInstance.get(API_URL + GET_PROFILE
        );
    }

    logout() {
        localStorage.removeItem("user");
        window.location.href = '/'
    }
}

export default new AuthServices();