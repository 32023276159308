import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import CourseCard from "./CourseCard";
import { getUserCourses } from "../../Redux/Actions/CourseActions";


export const MyLearning = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("user"));
    const userCourses = useSelector((state) => state.user_courses_reducer.getUserCourses);
    //navigate user to enrolled course
    const handleGoToCourse = (courseId) => {
        navigate(`/app/my-learning/enrolled-course/${courseId}`);
    };
    // dispatch action to get user enrolled courses
    useEffect(() => {
        const body = {
            "user_id": user.user._id
        }
        dispatch(getUserCourses(body));
    }, [])
    //navigate user to courses if no enrolled course 
    const handleExploreCourses = () => {
        navigate("/app/courses")
    }
    return (
        <div className="w-full flex flex-col gap-[24px] lg:gap-[40px] px-[10px]">
            <h3 className="Heading-18 text-font_color-primary lg:Heading-24">My Learning</h3>
            {userCourses && userCourses === "isLoading" && (
                <div className='flex items-center justify-center h-full'>
                    <TailSpin color="#34D795" height={50} width={50} />
                </div>
            )}
            <div className="flex flex-wrap gap-[16px]">
                {
                    userCourses && userCourses !== "isLoading" && userCourses.length !== 0 && userCourses.result && userCourses.result.length > 0 &&
                    userCourses.result.map((course) => (
                        <CourseCard
                            key={course._id}
                            course={course}
                            onGoToCourse={handleGoToCourse}
                        />
                    ))}
            </div>
            {userCourses && userCourses !== "isLoading" && userCourses.length !== 0 && userCourses.result && userCourses.result.length === 0 && (
                <div className="flex flex-col gap-[40px] items-center justify-center lg:flex-row lg:h-[500px]">
                    <img
                        className="w-[150px] h-[150px] lg:w-[300px] lg:h-[300px]"
                        src="/mylearning_empty.svg"
                        alt="dashboard empty"
                    />
                    <div className="flex flex-col gap-[32px] items-center lg:items-start">
                        <div className="flex flex-col gap-[8px]">
                            <p className="text-font_color-primary text-center Heading-18 lg:Heading-24 lg:text-left">Nothing to show!</p>
                            <p className="text-font_color-body text-center body-2 lg:body-1 lg:text-left lg:w-[515px]">You haven’t enrolled in any courses. Start learning by exploring our course catalog and finding the perfect match for you!</p>
                        </div>
                        <button
                            onClick={handleExploreCourses}
                            className="button2 px-[16px] py-[10px] gap-[10px] bg-primary-1 text-neutral-1 rounded-[6px] hover:bg-primary-4 w-fit lg:button1"
                        >
                            Explore Courses
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}