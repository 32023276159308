import { SIGNIN_FAIL, SIGNIN_PENDING, SIGNIN_SUCCESS, RESET_SIGNIN } from "../Types"
import AuthServices from "../../../Services/Auth/AuthServices"
import { toast } from "react-toastify"


export const signIn = (body) => async (dispatch) => {
    dispatch({
        type: SIGNIN_PENDING,
        payload: { signIn: "isLoading" },
    });

    try {
        const data = await AuthServices.signIn(body);

        if (data.data.status === true) {
            localStorage.setItem('user', JSON.stringify(data.data.result));
            dispatch({
                type: SIGNIN_SUCCESS,
                payload: { signIn: data.data },
            });
        } else {
            dispatch({
                type: SIGNIN_FAIL,
                payload: { signIn: data.data }
            });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: SIGNIN_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};



export const resetSignIn = () => async (dispatch) => {
    dispatch({
        type: RESET_SIGNIN,
    });
}