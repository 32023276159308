import React, { useEffect, useState } from 'react';
import "./donut.css"


const DonutChart = ({ completed, total }) => {
  const radius = 70;
  const strokeWidth = 45;
  const circumference = 2 * Math.PI * radius;

  const completedPercentage = (completed / total) * circumference;
  const inProgressPercentage = ((total - completed) / total) * circumference;

  const [animationCompleted, setAnimationCompleted] = useState(false);
  // function to show animation on chart only once
  useEffect(() => {
    setAnimationCompleted(false);
    const timer = setTimeout(() => {
      setAnimationCompleted(true);
    }, 50);
    return () => clearTimeout(timer);
  }, [completed, total]);

  return (
    <div className="w-full flex flex-col lg:flex-row justify-center items-center gap-[32px]">
      <div className="relative flex justify-center items-center">
        <svg
          className="lg:w-[220px] lg:h-[220px] w-[180px] h-[180px] cursor-pointer"
          viewBox="0 0 200 200"
        >
          <circle
            cx="100"
            cy="100"
            r={radius}
            stroke="#9BFDCB"
            strokeWidth={strokeWidth}
            strokeDasharray={animationCompleted ? `${completedPercentage} ${circumference}` : `0 ${circumference}`}
            strokeDashoffset="0"
            strokeLinecap="none"
            fill="none"
            style={{
              transition: 'stroke-dasharray 1s ease'
            }}
            transform="rotate(-180 100 100)"
          />
          <circle
            cx="100"
            cy="100"
            r={radius}
            stroke="#34D795"
            strokeWidth={strokeWidth}
            strokeDasharray={animationCompleted ? `${inProgressPercentage} ${circumference}` : `0 ${circumference}`}
            strokeDashoffset={-completedPercentage}
            strokeLinecap="none"
            fill="none"
            style={{
              transition: 'stroke-dasharray 1s ease'
            }}
            transform="rotate(-180 100 100)"
          />
        </svg>
        {/* Tooltip Div */}
        <div className="tooltip-box">{total - completed}</div>
      </div>
      <div className="flex flex-wrap gap-[18px] lg:gap-[0px] items-center justify-center lg:justify-start lg:flex-col">
        <div className="flex items-center gap-[16px]">
          <p className="body-2 text-font_color-body">Total Sessions</p>
          <p className="text-font_color-primary font-bold text-open_sans text-[16px] lg:text-[24px]">{total}</p>
        </div>
        <div className="flex items-center gap-[16px]">
          <div className="flex items-center gap-[8px]">
            <div className="w-[10px] h-[10px] rounded-full bg-primary-1"></div>
            <p className="body-2 text-font_color-body">In Progress</p>
          </div>
          <p className="text-font_color-primary font-bold text-open_sans text-[16px] lg:text-[24px]">{total - completed}</p>
        </div>
        <div className="flex items-center gap-[16px]">
          <div className="flex items-center gap-[8px]">
            <div className="w-[10px] h-[10px] rounded-full bg-primary-2"></div>
            <p className="body-2 text-font_color-body">Completed</p>
          </div>
          <p className="text-font_color-primary font-bold text-open_sans text-[16px] lg:text-[24px]">{completed}</p>
        </div>
      </div>
    </div>
  );
};

export default DonutChart;

