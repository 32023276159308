import AuthServices from './Auth/AuthServices';

let isRefreshing = false;
let failedQueue = [];

// Process the queue for failed requests
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (token) {
            prom.resolve(token);
        } else {
            prom.reject(error);
        }
    });
    failedQueue = [];
};

// Function to handle user logout
const handleLogout = () => {
    AuthServices.logout();
};

export const setupInterceptors = (axiosInstance) => {
    // Response Interceptor
    axiosInstance.interceptors.response.use(
        response => response,
        async error => {
            const originalRequest = error.config;

            if (error.response && error.response.status === 401) {
                if (isRefreshing) {
                    return new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject });
                    })
                    .then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axiosInstance(originalRequest);
                    })
                    .catch(err => Promise.reject(err));
                }

                isRefreshing = true;

                const user = JSON.parse(localStorage.getItem('user'));
                if (!user) {
                    handleLogout();
                    return Promise.reject(error);
                }

                const device_id = localStorage.getItem('deviceId');
                const body = {
                    refresh_token: user.tokens.RefreshToken.token,
                    device_id,
                };

                return AuthServices.refreshToken(body)
                    .then(response => {
                        if (response.data) {
                            const newAccessToken = response.data.result.AccessToken;
                            const updatedUser = {
                                ...user,
                                tokens: {
                                    ...user.tokens,
                                    AccessToken: newAccessToken,
                                },
                            };
                            localStorage.setItem('user', JSON.stringify(updatedUser));
                            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                            processQueue(null, newAccessToken);
                            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                            return axiosInstance(originalRequest);
                        }
                    })
                    .catch(err => {
                        processQueue(err, null);
                        handleLogout();
                        return Promise.reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            }

            return Promise.reject(error);
        }
    );

    // Request Interceptor
    axiosInstance.interceptors.request.use(
        config => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user && user.tokens && user.tokens.AccessToken) {
                config.headers['Authorization'] = `Bearer ${user.tokens.AccessToken}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );
};
