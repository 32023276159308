import React, { useState, useEffect } from "react";
import AvatarScreen from "./AvatarScreen";
import { useDispatch, useSelector } from "react-redux";
import { getLessonsContent } from "../../Redux/Actions/LessonActions";
import { useParams } from "react-router-dom";
import CourseOverviewAccordion from "./CourseOverviewAccordian";
import { TailSpin } from "react-loader-spinner";

export const MyLesson = () => {
  const dispatch = useDispatch();
  const { session_id, teacher_id, lesson_id } = useParams();
  const lessonContentData = useSelector(
    (state) => state.lesson_content_reducer.getLessonsContent
  );
  const [showAvatarScreen, setShowAvatarScreen] = useState(false);

  useEffect(() => {
    if (session_id && teacher_id && lesson_id) {
      const body = {
        session_id,
        teacher_id,
        lesson_id,
      };
      dispatch(getLessonsContent(body));
    }
  }, [dispatch, session_id, teacher_id, lesson_id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAvatarScreen(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (!lessonContentData || lessonContentData === "isLoading") {
    return;
  }

  const lessonContent = lessonContentData.result || {};
  const lesson = lessonContent.lesson || {};
  const allLessons = lessonContent.all_lessons || [];
  const teacher = lessonContent.teacher || {};
  const lessionSessionId = lessonContent.session_lesson_id || {};

  return (
    <div className="w-full flex flex-col gap-6 p-6">
      {lesson && allLessons.length > 0 && (
        <>
          {showAvatarScreen && (
            <AvatarScreen
              lessonData={lesson}
              teacherData={teacher}
              session_lesson_id={lessionSessionId}
              lessonContent={lessonContent}
              chatHistory={lessonContentData.result.chat_history}
            />
          )}
          {!showAvatarScreen && (
            <div
              className="w-full bg-slate-200 rounded-2xl items-center justify-center flex min-h-[100vh]"
              style={{ position: "relative" }}
            >
              <TailSpin></TailSpin>
            </div>
          )}
          <div className="w-full flex flex-col gap-4">
            <div className="flex flex-col gap-2 mb-4">
              <h2 className="text-2xl font-montserrat font-bold leading-tight text-left">
                {lesson.name}
              </h2>
              <p className="text-sm lg:text-lg text-gray-600">
                Interactive English Lessons with AI, Chat, and Learn in Realtime
              </p>
            </div>
            <div className="w-full flex flex-col lg:flex-row gap-[16px]">
              <div className="w-full gap-[16px] bg-neutral-1 rounded-xl shadow-[0_4px_6px_rgba(0,0,0,0.1),_0_1px_3px_rgba(0,0,0,0.08) lg:max-w-[35%]">
                <h2 className="Heading-20 lg:Heading-24 text-font_color-primary">
                  Lesson Details
                </h2>
                <ul className="space-y-[12px] list-disc gap-[16px] p-[16px]">
                  <li>
                    <strong>Goal:</strong>{" "}
                    {lesson.lesson_details?.goal || "N/A"}
                  </li>
                  <li>
                    <strong>Materials:</strong>{" "}
                    {lesson.lesson_details?.materials?.join(", ") || "N/A"}
                  </li>
                  <li>
                    <strong>Activities:</strong>
                    <ul className="list-disc pl-5 mt-1">
                      {lesson.lesson_details?.activities?.map(
                        (activity, index) => <li key={index}>{activity}</li>
                      ) || "N/A"}
                    </ul>
                  </li>
                  <li>
                    <strong>Assessment:</strong>{" "}
                    {lesson.lesson_details?.assessment || "N/A"}
                  </li>
                </ul>
              </div>

              <div className="w-full gap-[16px] bg-neutral-1 lg:max-w-[65%]">
                <h2 className="Heading-20 text-font_color-primary lg:Heading-24">
                  Course Outline
                </h2>
                <div className="flex flex-col gap-[4px]">
                  {allLessons
                    .sort((a, b) => a.order - b.order)
                    .map((lessonItem, index) => (
                      <div key={index}>
                        <CourseOverviewAccordion
                          key={lessonItem.index}
                          number={index + 1}
                          outlineData={lessonItem}
                          sessionId={session_id}
                          teacherId={teacher_id}
                          lessonId={lesson_id}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
