import { USER_COURSES_PENDING, USER_COURSES_SUCCESS, USER_COURSES_FAIL } from "../Actions/Types";

const initialState = {
    getUserCourses: [],
};
const user_courses_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_COURSES_SUCCESS:
            return {
                ...state,
                getUserCourses: payload.getUserCourses,
            };
        case USER_COURSES_PENDING:
            return {
                ...state,
                getUserCourses: payload.getUserCourses,
            };
        case USER_COURSES_FAIL:
            return {
                ...state,
                getUserCourses: [],
            };

        default:
            return state;
    }
};
export default user_courses_reducer;