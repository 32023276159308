import { SIGNIN_FAIL, SIGNIN_PENDING, SIGNIN_SUCCESS, RESET_SIGNIN } from "../../Actions/Types";
const initialState = {
    signIn: [],
    isLoggedIn: false,
};
const signin_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SIGNIN_SUCCESS:
            return {
                ...state,
                signIn: payload.signIn,
                isLoggedIn: true,
            };
        case SIGNIN_PENDING:
            return {
                ...state,
                signIn: payload.signIn,
                isLoggedIn: false,
            };
        case SIGNIN_FAIL:
            return {
                ...state,
                signIn: payload.signIn,
                isLoggedIn: false,

            };
        case RESET_SIGNIN:
            return {
                ...state,
                signIn: [],
                isLoggedIn: false,
            };

        default:
            return state;
    }
};
export default signin_reducer;