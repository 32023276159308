import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, resetChangePassword } from "../../Redux/Actions/Auth/ChangePasswordAction";
import PasswordInputField from "../../UIComponents/PasswordInputField";
import LoadingButton from "../../UIComponents/LoadingButton";


const ChangePasswordModal = ({ isVisible, onClose }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState({
        "old_password": "",
        "new_password": "",
        "confirm_password": ""
    });
    const [errors, setErrors] = useState({});
    const changePasswordStatus = useSelector((state) => state.change_password_reducer.changePassword);
    // function to validate form values
    const validateForm = () => {
        const newErrors = {};
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#+])[A-Za-z\d@$!%*?&#+]{8,}$/;
        if (!formValues.old_password.trim()) newErrors.old_password = "Password is required.";
        if (!formValues.new_password.trim()) newErrors.new_password = "Password is required.";
        else if (!passwordRegex.test(formValues.new_password)) newErrors.new_password = "Password must include: length greater than 8 characters, at least 1 uppercase, 1 numeric value and 1 special character";
        if (!formValues.confirm_password.trim()) newErrors.confirm_password = "Confirm password is required";
        else if (formValues.new_password !== formValues.confirm_password) newErrors.confirm_password = "Password do not match!"
        return newErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
        setErrors({});
    };
    // function dispatch change password action after validation
    const handleUpdatePassword = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            dispatch(changePassword(formValues));
        } else {
            setErrors(formErrors);
        }
    };
    // reset form values and close change password modal after password changed successfully
    useEffect(() => {
        if (changePasswordStatus?.status === true) {
            setFormValues({
                "old_password": "",
                "new_password": "",
                "confirm_password": ""
            });
            dispatch(resetChangePassword());
            onClose();
        }

    }, [changePasswordStatus])
    return (
        <div
            className={`fixed inset-0 z-50 px-[16px] flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300
                 ${isVisible ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
                 `}
        >
            <div className="relative flex flex-col p-[16px] gap-[24px] rounded-[16px] bg-neutral-1 max-w-[350px] shadow-shadow-6 shadow-shadow-7 lg:gap-[40px] lg:p-[32px] lg:max-w-[568px]">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 flex items-center justify-center w-[24px] h-[24px] hover:bg-neutral-4 rounded-[48px]">
                    <span className="w-[12px] h-[12px]">
                        <svg width="100%" height="100%" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.2 12.5001L22.35 3.3501C22.6875 3.0126 22.6875 2.4876 22.35 2.1501C22.0125 1.8126 21.4875 1.8126 21.15 2.1501L12 11.3001L2.84998 2.1501C2.51248 1.8126 1.98748 1.8126 1.64998 2.1501C1.31248 2.4876 1.31248 3.0126 1.64998 3.3501L10.8 12.5001L1.64998 21.6501C1.31248 21.9876 1.31248 22.5126 1.64998 22.8501C1.79998 23.0001 2.02498 23.1126 2.24998 23.1126C2.47498 23.1126 2.69998 23.0376 2.84998 22.8501L12 13.7001L21.15 22.8501C21.3 23.0001 21.525 23.1126 21.75 23.1126C21.975 23.1126 22.2 23.0376 22.35 22.8501C22.6875 22.5126 22.6875 21.9876 22.35 21.6501L13.2 12.5001Z" fill="#8C8C8C" />
                        </svg>
                    </span>
                </button>
                <div className="flex flex-col gap-[8px] lg:gap-[16px]">
                    <p className="text-font_color-primary Heading-20 text-center lg:Heading-40">Update Your Password</p>
                    <p className="text-font_color-body body-3 text-center lg:body-2">Keep your account secure by regularly updating your password.</p>
                </div>
                <div className="flex flex-col gap-[24px] text-font_color-primary">
                    <PasswordInputField
                        label="Current Password"
                        name="old_password"
                        value={formValues.old_password}
                        onChange={handleChange}
                        placeholder="Enter your password"
                        errorMessage={errors.old_password}
                    />
                    <PasswordInputField
                        label="New Password"
                        name="new_password"
                        value={formValues.new_password}
                        onChange={handleChange}
                        placeholder="Enter your password"
                        errorMessage={errors.new_password}
                    />
                    <PasswordInputField
                        label="Confirm Password"
                        name="confirm_password"
                        value={formValues.confirm_password}
                        onChange={handleChange}
                        placeholder="Enter your password"
                        onEnterPress={handleUpdatePassword}
                        errorMessage={errors.confirm_password}
                    />
                    <LoadingButton
                        onClick={handleUpdatePassword}
                        isLoading={changePasswordStatus === 'isLoading'}
                        text="Update Password"
                        className={"bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button2 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center lg:py-[13px] lg:button1"}
                    />

                </div>
            </div>

        </div>
    )
}

export default ChangePasswordModal;