import React, { useEffect, useState } from "react";
import CourseProgressCard from "../CourseProgressCard";
import SessionScoreCard from "../SessionScoreCard";
import NumbersCards from "../NumbersCards";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "../../../Redux/Actions/StudentDashboardAction";
import { TailSpin } from 'react-loader-spinner';
import OngoingSessions from "./OngoingSessions";
import { Select, MenuItem } from "@mui/material";


export const StudentDashboard = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // read dashboard data from reducer 
    const dashboardData = useSelector((state) => state.dashboard_data_reducer.getDashboardData);

    const [selectedCourse, setSelectedCourse] = useState(dashboardData?.result?.courses ? dashboardData.result.courses[0] : null);
    // dispatch action to get dashboard data
    useEffect(() => {
        const body = {
            "user_id": user.user._id
        }
        dispatch(getDashboardData(body));
    }, [])
    // navigate user to courses
    const handleExploreCourses = () => {
        navigate("/app/courses")
    }

    // function to set selected course 
    const handleCourseSelect = (event) => {
        const selectedCourseId = event.target.value;
        const course = dashboardData.result.courses.find((course) => course._id === selectedCourseId);
        setSelectedCourse(course);
    };
    const [selectedSession, setSelectedSession] = useState(selectedCourse ? selectedCourse.sessions[0] : null);
    // function to set selected session
    const handleSessionSelect = (event) => {
        const selectedSessionId = event.target.value;
        const session = selectedCourse.sessions.find((session) => session.session_id === selectedSessionId);
        setSelectedSession(session);
    };

    return (
        <>
            {dashboardData && dashboardData === "isLoading" && (
                <div className='flex items-center justify-center h-full'>
                    <TailSpin color="#34D795" height={50} width={50} />
                </div>
            )}
            {dashboardData && dashboardData !== "isLoading" && dashboardData.length !== 0 && (
                <div className="flex flex-col gap-[24px] text-font_color-primary lg:gap-[40px] mx-[15px]">
                    <div className="flex flex-col gap-[8px]">
                        <h3 className=" text-left Heading-24 lg:Heading-32">
                            {`Welcome back, ${user.user.first_name}!`}
                        </h3>
                        <p className="text-font_color-body text-left body-2 lg:body-00">Ready to conquer your next lesson?</p>
                    </div>
                    {dashboardData.length !== 0 && dashboardData.result && dashboardData.result.length !== 0 && (
                        <>
                            <div className="flex flex-col gap-[16px]">
                                <div className="gap-[24px] hidden lg:flex">
                                    <Select
                                        labelId="my-select-label"
                                        value={selectedCourse ? selectedCourse._id : setSelectedCourse(dashboardData.result.courses[0])}
                                        onChange={handleCourseSelect}
                                        className="w-[457px] rounded-[6px] h-[40px]"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 100,
                                                },
                                            },
                                        }}
                                    >
                                        {dashboardData.result.courses.map((course) => (
                                            <MenuItem
                                                className="body-2"
                                                key={course._id}
                                                value={course._id}
                                            >
                                                <span
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        width: '100%',
                                                    }}>{course.title}</span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Select
                                        labelId="my-select-label"
                                        value={selectedSession ? selectedSession.session_id : setSelectedSession(selectedCourse?.sessions[0])}
                                        onChange={handleSessionSelect}
                                        className="max-w-[457px] min-w-[300px] rounded-[6px] h-[40px]"
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 100,
                                                },
                                            },
                                        }}
                                    >
                                        {selectedCourse?.sessions.map((session) => (
                                            <MenuItem
                                                className="body-2"
                                                key={session.session_id}
                                                value={session.session_id}
                                            >
                                                <span
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        width: '100%',
                                                    }}>{"Session with " + session.name}</span>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>


                                <div className="flex flex-col w-[350px] gap-[16px] lg:gap-[24px] lg:flex-row lg:w-full">
                                    <div className="relative select-wrapper flex-col lg:hidden lg:order-1 order-2 lg:block">
                                        <Select
                                            labelId="my-select-label"
                                            value={selectedCourse ? selectedCourse._id : setSelectedCourse(dashboardData.result.courses[0])}
                                            onChange={handleCourseSelect}
                                            className="w-full rounded-[6px] h-[40px]"
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 100,
                                                    },
                                                },
                                            }}
                                        >
                                            {dashboardData.result.courses.map((course) => (
                                                <MenuItem
                                                    className="body-2"
                                                    key={course._id}
                                                    value={course._id}
                                                >
                                                    <span
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            width: '100%',
                                                        }}>{course.title}</span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="flex-col lg:flex lg:order-1 order-2">
                                        <CourseProgressCard
                                            CourseData={selectedCourse}
                                        />
                                    </div>
                                    <div className="relative select-wrapper flex-col lg:hidden lg:order-1 order-2 lg:block">
                                        <Select
                                            labelId="my-select-label"
                                            value={selectedSession ? selectedSession.session_id : setSelectedSession(selectedCourse?.sessions[0])}
                                            onChange={handleSessionSelect}
                                            className="w-full rounded-[6px] h-[40px]"
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 100,
                                                    },
                                                },
                                            }}
                                        >
                                            {selectedCourse?.sessions.map((session) => (
                                                <MenuItem
                                                    className="body-2"
                                                    key={session.session_id}
                                                    value={session.session_id}
                                                >
                                                    <span
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            width: '100%',
                                                        }}>{"Session with " + session.name}</span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="flex-col lg:order-2 order-3">
                                        <SessionScoreCard sessions={selectedSession} />
                                    </div>
                                    <div className="flex-col lg:order-3 order-1">
                                        <NumbersCards numberData={dashboardData.result} />
                                    </div>
                                </div>
                            </div>

                            <OngoingSessions
                                sessionsData={dashboardData.result.all_ongoing_sessions}
                            />
                        </>
                    )}
                    {dashboardData && dashboardData.result && dashboardData.result.length === 0 && (
                        <div className="flex flex-col gap-[40px] items-center justify-center lg:flex-row lg:h-[500px]">
                            <img
                                className="w-[150px] h-[150px] lg:w-[300px] lg:h-[300px]"
                                src="/dashboard_empty.svg"
                                alt="dashboard empty"
                            />
                            <div className="flex flex-col gap-[32px] items-center lg:items-start">
                                <div className="flex flex-col gap-[8px]">
                                    <p className="text-font_color-primary text-center Heading-18 lg:Heading-24 lg:text-left">Nothing to show!</p>
                                    <p className="text-font_color-body text-center body-2 lg:body-1 lg:text-left lg:w-[515px]">You haven’t enrolled in any courses. Start learning by exploring our course catalog and finding the perfect match for you!</p>
                                </div>
                                <button
                                    onClick={handleExploreCourses}
                                    className="button2 px-[16px] py-[10px] gap-[10px] bg-primary-1 text-neutral-1 rounded-[6px] hover:bg-primary-4 w-fit lg:button1"
                                >
                                    Explore Courses
                                </button>
                            </div>
                        </div>

                    )}
                </div >
            )}
        </>
    )
}