import React from "react";


const SdkErrorModal = ({ title, subTitle, sdkErrorModalAction }) => {
    
    
    return (
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-40 rounded-3xl">
        <div className="text-center bg-white rounded-3xl px-4 py-8 w-[80%] sm:w-[40%] flex flex-col justify-center items-center">
          <p className="text-[#333] text-2xl mb-4 font-bold">
            {title}
          </p>
          <br />
          <p className="text-[#727674] text-sm mb-4 font-bold">
          {subTitle}
          </p>
          <div className="flex flex-col w-full space-y-4 md:flex-row md:space-y-0 md:space-x-4">
            <button
              className="bg-[#34D795] w-full h-[60px] rounded-[6px] text-white text-sm px-4 font-semi-bold"
              onClick={sdkErrorModalAction}
            >
              Refresh Page
            </button>
          </div>
        </div>
      </div>
    );
}

export default SdkErrorModal;