import React, { useState } from "react";
import { TypeAnimation } from "react-type-animation";

const MESSAGE_TYPE_USER = 1;
const MESSAGE_TYPE_AVATAR = 2;
const MESSAGE_TYPE_HISTORY_USER = 3;
const MESSAGE_TYPE_HISTORY_AVATAR = 4;
const MESSAGE_TYPE_LECTURE_STATUS = 5;
const MESSAGE_TYPE_ERROR = 6;
const MESSAGE_TYPE_FLASHCARD = 7;

const MessageBubble = ({
  message,
  index,
  handleWordTyped,
  onRetry,
  onResume,
}) => {
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleRetry = () => {
    setButtonClicked(true);
    onRetry();
  };

  const handleResume = () => {
    setButtonClicked(true);
    onResume();
  };


  const userMessageClass = "bg-[#34D795] text-white ml-4";
  const errorMessageClass = "bg-[#F56060] text-white mr-4";
  const avatarMessageClass = "bg-white text-[#333] text-[10px] mr-4";
  const stateMessageClass = "bg-transparent"


  const messageClass = 
    message.type === MESSAGE_TYPE_USER || message.type === MESSAGE_TYPE_HISTORY_USER
      ? userMessageClass
      : message.type === MESSAGE_TYPE_ERROR
      ? errorMessageClass
      : message.type === MESSAGE_TYPE_AVATAR || message.type === MESSAGE_TYPE_HISTORY_AVATAR 
      ? avatarMessageClass
      : stateMessageClass;

  return (
    <div
      key={index}
      className={`flex justify-start p-4 rounded-[10px] transition duration-500 ease-in-out self-end mt-4 ${messageClass}`}
      style={{
        boxShadow:
          message.type === MESSAGE_TYPE_LECTURE_STATUS || message.type === MESSAGE_TYPE_FLASHCARD
            ? "none"
            : "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)",
        minHeight: "auto",
      }}
    >
      {message.type === MESSAGE_TYPE_LECTURE_STATUS ? (
        <div className="flex justify-center w-full">
          <div className="bg-gray-400 text-white rounded-sm px-4 py-2 text-[14px]">
            {message.text}
          </div>
        </div>
      ) : message.type === MESSAGE_TYPE_FLASHCARD ? (
        <div className="flex justify-center w-full">
          <img className="w-[40%]" src={message.text} alt="Flashcard" />
        </div>
      ) : (
        <div className="flex items-start">
          <p className="w-full text-[14px] font-open_sans">
            {message.type === MESSAGE_TYPE_AVATAR ? (
              <TypeAnimation
                splitter={(str) => str.split(/(?= )/)}
                sequence={[
                  message.text,
                  () => {
                    handleWordTyped();
                  },
                ]}
                speed={{ type: "keyStrokeDelayInMs", value: 310 }}
                omitDeletionAnimation={true}
                cursor={false}
                style={{
                  fontSize: "1em",
                  display: "block",
                  overflow: "hidden",
                }}
              />
            ) : (
              message.text
            )}
          </p>
          {message.type === MESSAGE_TYPE_ERROR && !buttonClicked && (
            <div className="ml-2 flex flex-row items-center bg-white rounded-sm p-2">
              <button className="" onClick={() => handleRetry()}>
                <img className="w-[30px]" src="/retry-button.svg" alt="Retry" />
              </button>
              <button className="ml-4" onClick={() => handleResume()}>
                <img
                  className="w-[30px]"
                  src="/resume-button.svg"
                  alt="Resume"
                />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageBubble;