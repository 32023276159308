import { GENDERS_FAIL, GENDERS_PENDING, GENDERS_SUCCESS, SET_MESSAGE, ROLES_FAIL, ROLES_PENDING, ROLES_SUCCESS, TEACHERS_FAIL, TEACHERS_PENDING, TEACHERS_SUCCESS } from "./Types";
import { toast } from "react-toastify";
import CommonServices from "../../Services/CommonServices";

export const getGenders = () => async (dispatch) => {
    dispatch({
        type: GENDERS_PENDING,
        payload: { getGenders: "isLoading" },
    });

    try {
        const data = await CommonServices.getGenders();

        if (data.data.status === true) {
            dispatch({
                type: GENDERS_SUCCESS,
                payload: { getGenders: data.data },
            });
        } else {
            dispatch({ type: GENDERS_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: GENDERS_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};

export const getRoles = () => async (dispatch) => {
    dispatch({
        type: ROLES_PENDING,
        payload: { getRoles: "isLoading" },
    });

    try {
        const data = await CommonServices.getRoles();

        if (data.data.status === true) {
            dispatch({
                type: ROLES_SUCCESS,
                payload: { getRoles: data.data },
            });
        } else {
            dispatch({ type: ROLES_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: ROLES_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};

export const getTeachers = () => async (dispatch) => {
    dispatch({
        type: TEACHERS_PENDING,
        payload: { getTeachers: "isLoading" },
    });

    try {
        const data = await CommonServices.getTeachers();

        if (data.data.status === true) {
            dispatch({
                type: TEACHERS_SUCCESS,
                payload: { getTeachers: data.data },
            });
        } else {
            dispatch({ type: TEACHERS_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: TEACHERS_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};

