import { SEND_VERIFICATION_EMAIL_FAIL, SEND_VERIFICATION_EMAIL_PENDING, SEND_VERIFICATION_EMAIL_SUCCESS } from "../Types"
import { toast } from "react-toastify"
import AuthServices from "../../../Services/Auth/AuthServices";

export const sendVerificationEmail = (body) => async (dispatch) => {
    dispatch({
        type: SEND_VERIFICATION_EMAIL_PENDING,
        payload: { sendVerificationEmail: "isLoading" },
    });

    try {
        const data = await AuthServices.sendVerificationEmail(body);

        if (data.data.status === true) {
            dispatch({
                type: SEND_VERIFICATION_EMAIL_SUCCESS,
                payload: { sendVerificationEmail: data.data },
            });
            toast.success(data.data.message);
        } else {
            dispatch({ type: SEND_VERIFICATION_EMAIL_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: SEND_VERIFICATION_EMAIL_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};