import React, { useState, useEffect } from "react";
import { SigninSignupImage } from "../../UIComponents/SignupSigninImage";
import { useDispatch, useSelector } from "react-redux";
import { resetSignupState, signUp } from "../../Redux/Actions/Auth/SignUpAction";
import { getGenders } from "../../Redux/Actions/CommonActions";
import { getRoles } from "../../Redux/Actions/CommonActions";
import VerificationResend from "../../UIComponents/VerificationResend";
import Header from "../../UIComponents/Header";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import { signInWithGoogle } from "../../Redux/Actions/Auth/SignInWithGoogleAction";
import { toast } from "react-toastify";
import { sendVerificationEmail } from "../../Redux/Actions/Auth/SendVerificationEmailAction";
import InputField from "../../UIComponents/InputField";
import PasswordInputField from "../../UIComponents/PasswordInputField";
import LoadingButton from "../../UIComponents/LoadingButton";



export const SignUp = () => {
    const [showResend, setShowRensend] = useState(false);
    const [resendEmail, setResendEmail] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const roles = useSelector((state) => state.role_reducer.getRoles);
    const signUpStatus = useSelector((state) => state.signup_reducer.signUp);
    const user = JSON.parse(localStorage.getItem("user"));
    const signInStatus = useSelector((state) => state.signin_reducer.signIn);
    const googleSignInStatus = useSelector((state) => state.google_signin_reducer.signIn);

    const studentRole = roles && roles !== "isLoading" && roles.length !== 0 && roles.result.length > 0 && roles.result.find(role => role.name === "Student");
    const studentRoleId = studentRole ? studentRole.role_id : null;
    const [formValues, setFormValues] = useState({
        "email": "",
        "first_name": "",
        "last_name": "",
        "role": "",
        "password": ""
    });
    const [errors, setErrors] = useState({});
    useEffect(() => {
        dispatch(getGenders());
        dispatch(getRoles());
    }, [dispatch]);
    //setting role to student
    useEffect(() => {
        setFormValues(prevValues => ({
            ...prevValues,
            role: studentRoleId
        }));
    }, [studentRoleId]);
    //reset form values after signup
    useEffect(() => {
        if (signUpStatus && signUpStatus !== "isLoading" && signUpStatus.status === true) {
            setShowRensend(true);
            setResendEmail(formValues.email.trim());
            setFormValues({
                "email": "",
                "first_name": "",
                "last_name": "",
                "role": studentRoleId,
                "password": ""
            });
        } else {
            setShowRensend(false);
        }
    }, [signUpStatus]);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
        setErrors({});
    };
    //function to validate form values
    const validateForm = () => {
        const newErrors = {};
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#+])[A-Za-z\d@$!%*?&#+]{8,}$/;
        const nameRegex = /^[a-zA-Z\s]+$/;
        if (!formValues.first_name.trim()) {
            newErrors.first_name = "First Name is required.";
        } else if (!nameRegex.test(formValues.first_name)) {
            newErrors.first_name = "First Name cannot contain special characters.";
        } else if (formValues.first_name.length > 15) {
            newErrors.first_name = "First Name cannot exceed 15 characters.";
        }
        if (!formValues.last_name.trim()) {
            newErrors.last_name = "Last Name is required.";
        } else if (!nameRegex.test(formValues.last_name)) {
            newErrors.last_name = "Last Name cannot contain special characters.";
        } else if (formValues.last_name.length > 15) {
            newErrors.last_name = "Last Name cannot exceed 15 characters.";
        }
        if (!formValues.email.trim()) newErrors.email = "Email is required.";
        else if (!/\S+@\S+\.\S+/.test(formValues.email)) newErrors.email = "Email is invalid.";
        if (!formValues.password.trim()) newErrors.password = "Password is required.";
        else if (!passwordRegex.test(formValues.password)) newErrors.password = "Password must include: length greater than 8 characters, at least 1 uppercase, 1 numeric value and 1 special character";

        return newErrors;
    };
    //function for signup 
    const handleCreateAccount = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const body = {
                ...formValues,
                first_name: formValues.first_name.trim(),
                last_name: formValues.last_name.trim(),
                email: formValues.email.trim().toLowerCase(),
                password: formValues.password,
            };
            dispatch(signUp(body));
        } else {

            setErrors(formErrors);
        }
    };
    const handleClose = () => {
        dispatch(resetSignupState());
    };
    //function to login with google
    const loginWithGoogle = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                console.log(response, "response of use google login");
                if (response) {
                    const device_id = localStorage.getItem("deviceId");
                    const body = {
                        "access_token": response.access_token,
                        device_id
                    }
                    dispatch(signInWithGoogle(body));
                }
            } catch (err) {
                console.error('Error fetching user info:', err);
                toast.error(err.message || 'Error occurred while fetching user info');
            }
        },
        onError: (error) => {
            console.error('Login failed:', error);
            toast.error(error.message);
        },
    });
    //navigating user after google login depending on their role
    useEffect(() => {
        if (user?.user) {
            if (user.user.role == 2) {
                navigate("/app/dashboard");
            } else if (user.user.role == 3) {
                navigate("/admin/dashboard");
            }
        }
    }, [user, signInStatus, googleSignInStatus]);
    //resend email function
    const handleSendVerificationEmail = () => {
        const body = {
            "email": resendEmail
        }
        dispatch(sendVerificationEmail(body));
    }
    return (

        <div className="p-0 m-0 w-full h-screen lg:w-screen lg:h-screen lg:flex lg:p-5">

            <div className="hidden lg:flex lg:flex-1 items-center justify-end">
                <SigninSignupImage />
            </div>
            <div className="lg:hidden">
                <Header background={"bg-primary-1"} />
            </div>

            <div className="w-full py-4 px-4 text-font_color-primary lg:flex-1 flex flex-col items-center justify-center lg:pl-24 lg:pr-[14%] lg:gap-4 lg:max-w-[50vw]">
                <div className="w-full flex flex-col gap-4">
                    <div className="w-full flex flex-col gap-[8px] items-center lg:items-start lg:justify-between lg:gap-4">
                        <h2 className="Heading-24 lg:Heading-48 text-left">
                            Create an account
                        </h2>
                        <div className="flex flex-row lg:flex-row gap-2 lg:gap-2 items-center">
                            <p className="body-2 lg:body-1 text-font_color-body">
                                Already have an account?
                            </p>
                            <a
                                href="/signin"
                                className="subtitle2 text-primary-1 cursor-pointer underline hover:text-primary-5 lg:subtitle1"
                            >
                                Log In
                            </a>
                        </div>
                    </div>
                    {showResend === true && (
                        <VerificationResend
                            text="A verification link has been sent to your email, please verify."
                            resendEmail={handleSendVerificationEmail}
                            onClose={handleClose} />
                    )}

                    <div className="w-full flex flex-col h-[70vh] gap-6 justify-between lg:h-fit">
                        <div className="flex flex-col gap-[16px] lg:gap-[24px]">
                            <InputField
                                label="First Name"
                                name="first_name"
                                value={formValues.first_name}
                                onChange={handleChange}
                                placeholder="Enter your first name"
                                errorMessage={errors.first_name}
                            />
                            <InputField
                                label="Last Name"
                                name="last_name"
                                value={formValues.last_name}
                                onChange={handleChange}
                                placeholder="Enter your last name"
                                errorMessage={errors.last_name}
                            />

                            <InputField
                                label="Email"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                                errorMessage={errors.email}
                            />
                            <PasswordInputField
                                label="Password"
                                name="password"
                                value={formValues.password}
                                onChange={handleChange}
                                placeholder="Enter your password"
                                onEnterPress={handleCreateAccount}
                                errorMessage={errors.password}
                            />
                        </div>
                        <div className="flex flex-col gap-[12px] lg:gap-[24px]">
                            <LoadingButton
                                onClick={handleCreateAccount}
                                isLoading={signUpStatus === 'isLoading'}
                                text="Create account"
                                className={"bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button2 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center lg:py-[13px] lg:button1"}
                            />
                            <div className="w-full flex items-center justify-center gap-[10px] lg:gap-[18px]">
                                <hr className="border-t border-[#8C8C8C] w-[90px] lg:w-[100px]" />
                                <p className="text-center text-[#111928] font-open_sans leading-[21.79px] text-[12px] lg:text-[16px]">or continue with</p>
                                <hr className=" border-t border-[#8C8C8C] w-[90px] lg:w-[100px]" />
                            </div>
                            <div className="w-full flex items-center justify-center gap-[24px]">
                                <button
                                    onClick={loginWithGoogle}
                                    className="w-[240px] h-[48px] px-[var(--sds-size-space-300)] py-[var(--sds-size-space-300)] gap-[var(--sds-size-space-200)] rounded-[6px] border border-[#8C8C8C] flex items-center justify-center">
                                    <img src="/google_icon.svg" alt="Google Icon" className="w-6 h-6 mr-2" />
                                    Google
                                </button>

                                {/* <button className="w-[240px] h-[48px] px-[var(--sds-size-space-300)] py-[var(--sds-size-space-300)] gap-[var(--sds-size-space-200)] rounded-[6px] border border-[#8C8C8C] flex items-center justify-center">
                                    <img src="/facebook_icon.svg" alt="Facebook Icon" className="w-6 h-6 mr-2" />
                                    Facebook
                                </button> */}

                            </div>
                            <footer className="body-3 text-font_color-body text-center lg:hidden">
                                <p>© 2024 Knnect. All Rights Reserved.</p>
                            </footer>
                        </div>
                    </div>
                </div>
                <footer className="hidden lg:block text-font_color-body body-1 text-center relative bottom-0">
                    <p>© 2024 Knnect. All Rights Reserved.</p>
                </footer>
            </div>
        </div>
    );
}