import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SigninSignupImage } from "../../UIComponents/SignupSigninImage";
import { useDispatch, useSelector } from "react-redux";
import { resetSignIn, signIn } from "../../Redux/Actions/Auth/SignInAction";
import { v4 as uuidv4 } from 'uuid';
import Header from "../../UIComponents/Header";
import { useGoogleLogin } from '@react-oauth/google';
import { signInWithGoogle } from "../../Redux/Actions/Auth/SignInWithGoogleAction";
import { toast } from "react-toastify";
import InputField from "../../UIComponents/InputField";
import PasswordInputField from "../../UIComponents/PasswordInputField";
import LoadingButton from "../../UIComponents/LoadingButton";


export const SignIn = () => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("user"));
    const [formValues, setFormValues] = useState({
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const signInStatus = useSelector((state) => state.signin_reducer.signIn);
    const googleSignInStatus = useSelector((state) => state.google_signin_reducer.signIn);

    // generate device id and save it in local storage if not available
    useEffect(() => {
        const existingDeviceId = localStorage.getItem('deviceId');
        if (!existingDeviceId) {
            const id = uuidv4();
            localStorage.setItem('deviceId', id);
        }
    }, []);

    useEffect(() => {
        if (signInStatus && signInStatus.result && signInStatus.result.is_blocked === true) {
            localStorage.setItem("remainingTime", signInStatus.result.remaining_time);
            dispatch(resetSignIn());
            navigate("/account-locked");
        }
    }, [signInStatus]);

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        // Email validation
        if (!formValues.email.trim()) {
            newErrors.email = 'Email is required';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            newErrors.email = 'Invalid email format';
            valid = false;
        }

        // Password validation
        if (!formValues.password) {
            newErrors.password = 'Password is required';
            valid = false;
        } else if (formValues.password.length < 8) {
            newErrors.password = 'Password must be at least 8 characters long';
            valid = false;
        }
        setErrors(newErrors);
        return valid;
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: type === 'checkbox' ? checked : value,
        });
    };
    //signin handle function
    const handleSignIn = () => {
        const isFormValid = validateForm();

        if (isFormValid) {
            const device_id = localStorage.getItem("deviceId");
            const body = {
                ...formValues,
                email: formValues.email.trim().toLowerCase(),
                password: formValues.password,
                device_id
            };
            dispatch(signIn(body));
        } else {

            console.log("Form has errors, not dispatching sign in");
        }
    };

    //function for google login
    const loginWithGoogle = useGoogleLogin({
        onSuccess: async (response) => {
            try {
                if (response) {
                    const device_id = localStorage.getItem("deviceId");
                    const body = {
                        "access_token": response.access_token,
                        device_id
                    }
                    dispatch(signInWithGoogle(body));
                }
            } catch (err) {
                toast.error(err.message || 'Error occurred while fetching user info');
            }
        },
        onError: (error) => {
            toast.error(error.message);
        },
    });
    // check if user logged in and navigate it to dashboard depending on their role
    useEffect(() => {
        if (user?.user) {
            if (user.user.role == 2) {
                navigate("/app/dashboard");
            } else if (user.user.role == 3) {
                navigate("/admin/dashboard");
            }
            localStorage.removeItem("remainingTime");
        }
    }, [user, signInStatus, googleSignInStatus]);

    return (
        <div className="p-0 m-0 w-full h-screen lg:w-screen lg:h-screen lg:flex lg:p-5">
            <div className="hidden lg:flex lg:flex-1 items-center justify-end">
                <SigninSignupImage />
            </div>
            <div className="lg:hidden w-full flex">
                <Header background={"bg-primary-1"} />
            </div>
            <div className="w-full py-4 px-4 text-font_color-primary lg:pl-[92px] lg:pr-20 lg:flex-1 flex flex-col items-center justify-center lg:pl-24 lg:pr-[14%] lg:gap-10 lg:max-w-[50vw]">
                <div className="w-full flex flex-col gap-6">
                    <div className="w-full flex flex-col gap-[8px] items-center lg:items-start lg:justify-between lg:gap-4">
                        <h2 className="Heading-24 lg:Heading-48 text-left">
                            Continue learning
                        </h2>
                        <div className="flex flex-row lg:flex-row gap-2 lg:gap-2 items-center">
                            <p className="body-2 lg:body-1 text-font_color-body">
                                Don’t have an account?
                            </p>
                            <a
                                href="/signup"
                                className="subtitle2 text-primary-1 cursor-pointer underline hover:text-primary-5 lg:subtitle1"
                            >
                                Sign up
                            </a>
                        </div>
                    </div>


                    <div className="w-full flex flex-col h-[70vh] gap-6 justify-between lg:h-fit">
                        <div className="flex flex-col gap-[16px] lg:gap-[24px]">
                            <InputField
                                label="Email"
                                name="email"
                                value={formValues.email}
                                onChange={handleInputChange}
                                placeholder="Enter your email"
                                errorMessage={errors.email}
                            />
                            <PasswordInputField
                                label="Password"
                                name="password"
                                value={formValues.password}
                                onChange={handleInputChange}
                                placeholder="Enter your password"
                                onEnterPress={handleSignIn}
                                errorMessage={errors.password}
                            />
                            <a
                                href="/forgot-password"
                                className="text-primary-1 subtitle2 underline text-left cursor-pointer hover:text-primary-5 cursor-pointer lg:subtitle1"
                            >
                                Forgot Password?
                            </a>
                        </div>

                        <div className="flex flex-col gap-[12px] lg:gap-[24px]">
                            <LoadingButton
                                onClick={handleSignIn}
                                isLoading={signInStatus === 'isLoading'}
                                text="Sign in"
                                className={"bg-primary-1 w-full rounded-[6px] hover:bg-primary-5 button2 px-[20px] py-[10px] text-neutral-1 flex items-center justify-center lg:py-[13px] lg:button1"}
                            />

                            <div className="w-full flex items-center justify-center gap-[10px] lg:gap-[18px]">
                                <hr className="border-t border-[#8C8C8C] w-[90px] lg:w-[95px]" />
                                <p className="text-center text-[#111928] font-open_sans leading-[21.79px] text-[12px] lg:text-[16px]">or continue with</p>
                                <hr className=" border-t border-[#8C8C8C] w-[90px] lg:w-[95px]" />
                            </div>
                            <div className="w-full flex flex-col lg:flex-row items-center justify-center gap-6 lg:gap-10">
                                <div className="w-full flex items-center justify-center gap-6">
                                    <button
                                        onClick={loginWithGoogle}
                                        className="w-60 h-12 px-4 py-2 rounded-[6px] border border-[#8C8C8C] flex items-center justify-center">
                                        <img src="/google_icon.svg" alt="Google Icon" className="w-6 h-6 mr-2" />
                                        Google
                                    </button>
                                    {/* <button className="w-60 h-12 px-4 py-2 rounded-[6px] border border-[#8C8C8C] flex items-center justify-center">
                                        <img src="/facebook_icon.svg" alt="Facebook Icon" className="w-6 h-6 mr-2" />
                                        Facebook
                                    </button> */}
                                </div>

                            </div>
                            <footer className="body-3 text-font_color-body text-center lg:hidden">
                                <p>© 2024 Knnect. All Rights Reserved.</p>
                            </footer>
                        </div>
                    </div>

                </div>
                <footer className="hidden lg:block text-font_color-body body-1 text-center relative bottom-0">
                    <p>© 2024 Knnect. All Rights Reserved.</p>
                </footer>
            </div>

        </div>

    );
}