import React from 'react';

const MeetAITeachers = ({ setRefs, onClick }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-center w-full bg-primary-2 p-[20px] gap-[24px] lg:p-[80px] lg:gap-[80px]">
      <div
        ref={setRefs(0)}
        data-animation-type="left"
        className="w-full h-auto flex flex-row justify-end gap-[16px] opacity-0"
      >
        <div className="absolute top-5 left-[-60px] z-[-10] lg:top-40">
          <img
            src="/decorative-icons.png"
            alt="Decorative Icon"
            className="w-[176px] h-[176px]"
          />
        </div>
        <img
          className="w-auto h-[252px] object-cover pt-[80px] lg:h-[464px]"
          src="/teacher1.png"
          alt="Description 1"
        />
        <img
          className="w-auto h-[252px] object-cover pb-[80px] lg:h-[464px]"
          src="/teacher2.png"
          alt="Description 2"
        />
        <img
          className="w-auto h-[252px] object-cover py-[40px] lg:h-[464px]"
          src="/teacher3.png"
          alt="Description 3"
        />
      </div>

      <div
        ref={setRefs(1)}
        data-animation-type="right"
        className="flex flex-col w-full justify-center opacity-0 gap-[24px]"
      >
        <h2 className="w-full text-left Heading-24 text-font_color-primary lg:Heading-40">
          Meet Your AI Teachers
        </h2>
        <p className="text-left text-font_color-body subtitle3 lg:subtitle0">
          Our AI teachers are designed to provide you with personalized support 24/7. They adapt to your learning pace, offering tailored explanations, hints, and feedback to help you understand even the most complex concepts.
        </p>
        <button
          onClick={onClick}
          className="bg-neutral-1 border-2 button3 border-primary-1 w-fit px-[16px] py-[10px] text-primary-1 rounded-[6px] transition-colors duration-300 hover:text-primary-5 hover:border-primary-5 lg:button1 lg:px-[28px] lg:py-[13px]">
          Learn with Knnect
        </button>
      </div>
    </div>
  );
};

export default MeetAITeachers;