import { BASE_URL } from '../Constants';
import axiosInstance from './AxiosInstance';

// Base URL for your API
const API_URL = BASE_URL;
const GENDERS = "genders/";
const ROLES = "roles/";
const Teachers = "teachers/";


class CommonServices {
    async getGenders() {
        return axiosInstance.get(API_URL + GENDERS);
    }

    async getRoles() {
        return axiosInstance.get(API_URL + ROLES);
    }

    async getTeachers() {
        return axiosInstance.get(API_URL + Teachers);
    }
}

export default new CommonServices();