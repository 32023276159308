import { UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAIL, UPDATE_PASSWORD_PENDING, RESET_UPDATE_PASSWORD } from "../Types"
import AuthServices from "../../../Services/Auth/AuthServices"
import { toast } from "react-toastify"


export const updatePassword = (body) => async (dispatch) => {
    dispatch({
        type: UPDATE_PASSWORD_PENDING,
        payload: { updatePassword: "isLoading" },
    });

    try {
        const data = await AuthServices.updatePassword(body);

        if (data.data.status === true) {
            dispatch({
                type: UPDATE_PASSWORD_SUCCESS,
                payload: { updatePassword: data.data },
            });
        } else {
            dispatch({ type: UPDATE_PASSWORD_FAIL });
            toast.error(data.data.message);
        }
    } catch (error) {
        console.error("Error:", error);
        dispatch({ type: UPDATE_PASSWORD_FAIL });
        toast.error(error?.response?.data?.message || "An unexpected error occurred");
    }
};

export const resetUpdatePassword = () => ({
    type: RESET_UPDATE_PASSWORD,
});