import React, { useEffect, useState } from 'react';
import { AVATAR_SDK_KEY } from '../../Constants';
import { AVATAR_SDK_SCRIPT } from '../../Constants';

const ChatComponent = () => {
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = AVATAR_SDK_SCRIPT;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      const sdkOption = { sdk_key: AVATAR_SDK_KEY };
      const avatarOption = {
        avatar_id: "a56767cb-0090-11ef-8ee1-0abbf354c5cc",
        voice_code: "en_us", subtitle_code: "en_us", voice_tts_speech_speed: 0.8,
        radius: 24,
      };
      const chatOption = {
        type: 'inside',
      };
      if (window.KlleonChat) {
        window.KlleonChat.init(sdkOption);
        window.KlleonChat.showStreaming(avatarOption);
        window.KlleonChat.showChatUi(chatOption);
      }
    };

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const handleSendClick = () => {
    if (window.KlleonChat && inputText.trim() !== '') {
      window.KlleonChat.echo(inputText);
      setInputText(''); // Clear the input field after sending
    }
  };

  return (
    <div
      style={{ display: 'flex', height: '100vh', width: '100vw' }}>
      <div id="klleon_chat" style={{ width: '70%', height: '100vh' }}></div>
      <div style={{ width: '30%', padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'center' }}>
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Type your message here"
          style={{ width: '100%', height: '180px', marginBottom: '12px', padding: '8px' }}
          className='border border-neutral-4'
        />
        <button
          onClick={handleSendClick}
          style={{ padding: '10px 20px', cursor: 'pointer', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px' }}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatComponent;
