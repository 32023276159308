import { UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL, UPDATE_PROFILE_PENDING } from "../../Actions/Types";

const initialState = {
    updateProfile: [],
};
const update_profile_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                updateProfile: payload.updateProfile,
            };
        case UPDATE_PROFILE_PENDING:
            return {
                ...state,
                updateProfile: payload.updateProfile,
            };
        case UPDATE_PROFILE_FAIL:
            return {
                ...state,
                updateProfile: [],

            };
        default:
            return state;
    }
};
export default update_profile_reducer;